import React from "react";
import LearnFaqController, { Props } from "./LearnFaqController.web"
// Customizable Area Start
import {
  Grid, 
  Button, 
  Box, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
} from "@material-ui/core";
import HeaderWeb from "../../landingpage/src/Header.web";
import FooterWeb from "../../landingpage/src/Footer.web";
import '../assets/css/InteractiveFaqs.web.css';
// Customizable Area End

 
export default class LearnFaqs extends LearnFaqController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="interactive-faqs-page">
        <HeaderWeb/>
          <Grid container className="faq-main">
            <div className="faq-page-title">Frequently Asked Questions (FAQs)</div>

            <Box className="faq-buttons-section">
              <div className="button-wrapper">
                <Button 
                  className={`tab-btn ${!this.state.is_startupshow && 'active'}`} 
                  id="investersBtn"
                  onClick={() => this.setState({is_startupshow:false,toggleStatus:0})}
                >
                  Investors
                </Button>
                <Button 
                  className={`tab-btn ${this.state.is_startupshow && 'active'}`} 
                  id="startupBtn"
                  onClick={() => this.setState({is_startupshow:true,toggleStatus:0})}
                >
                  startups
                </Button>
              </div>
            </Box>

            <Box className="Faq-data-section">
              <div className="invester-faqs" hidden={this.state.is_startupshow && true}>
                {this.state.investersFaqs?.map((item:any,index:number) => 
                  <Accordion 
                    className="faq-accordion" 
                    key={index} 
                    TransitionProps={{unmountOnExit:true}} 
                    expanded={this.state.toggleStatus === index}
                    onChange={() => this.handleAccordion(index)}
                  >
                    <AccordionSummary className="faq-question">{item.que}</AccordionSummary>
                    <AccordionDetails className="faq-answer">{item.ans}</AccordionDetails>
                  </Accordion>
                )}
              </div>
              
              <div className="startup-faqs" hidden={!this.state.is_startupshow && true}>
                {this.state.startupsFaqs?.map((item:any,index:number) => 
                  <Accordion 
                    className="faq-accordion" 
                    key={index} 
                    TransitionProps={{unmountOnExit:true}} 
                    expanded={this.state.toggleStatus === index}
                    onChange={() => this.handleAccordion(index)}
                  >
                    <AccordionSummary className="faq-question">{item.que}</AccordionSummary>
                    <AccordionDetails className="faq-answer">{item.ans}</AccordionDetails>
                  </Accordion>
                )}
              </div>
            </Box>
          </Grid>
        <FooterWeb/>
      </div>
    );
  }
}
