export const bannerBgImage = require("../assets/banner-bg.png");
export const logoImg = require("../assets/logo.png");
export const bannerRightImage = require("../assets/banner-img.png");
export const brandImg1 = require("../assets/brand1.png");
export const brandImg2 = require("../assets/brand2.png");
export const brandImg3 = require("../assets/brand3.png");
export const brandImg4 = require("../assets/brand4.png");
export const verizonIcon = require("../assets/verizon-icon.png");
export const bontonIcon = require("../assets/bonton-icon.png");
export const investChartImg = require("../assets/invest-chart.png");
export const campaignRightImg = require("../assets/campaign-img.png");
export const investFundRaise = require("../assets/invest-fund-raise.png");
export const investImg = require("../assets/invest-img.png");
export const clientImg1 = require("../assets/client1.png");
export const clientImg2 = require("../assets/client2.png");
export const clientImg3 = require("../assets/client3.png");
export const linkdinIcon = require("../assets/linkdin-icon.png");
export const registrationBg = require("../assets/registration-bg.png");
export const fbIcons = require("../assets/fb_icon.png");
export const instagramIcons = require("../assets/instagram_icon.png");
export const twitterIcons = require("../assets/twitter_icon.png");
export const linkdinIcons = require("../assets/linkdin_icon.png");   
export const emailIcon = require("../assets/email_icon.png");   
export const phoneIcon = require("../assets/phone-icon.png");   
export const locationIcon = require("../assets/location_icon.png");   
export const growthIcon = require("../assets/growth-icon.png");   
export const startupIcon = require("../assets/startup_icon.png");   
export const fundIcon = require("../assets/fund_icon.png");   
export const campDetailContactImg = require("../assets/contact-img.png");   
export const campDetailContactImg2 = require("../assets/contact-img2.png");   
export const campDetailFounderProfile = require("../assets/founder-profile.png");
export const loginImage = require("../assets/login_image.png");
export const founderProfile = require("../assets/founder-profile.png");
export const charter1 = require("../assets/charter_img_1.png");
export const charter2 = require("../assets/charter_img_2.png");
export const charter3 = require("../assets/charter_img_3.png");
export const charter4 = require("../assets/charter_img_4.png");
export const charter5 = require("../assets/charter_img_5.png");
export const warningIcon = require("../assets/warningImage.png");
export const closeIcon = require("../assets/close.png");
