import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  isRegistration: boolean;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  loading: boolean;
  countryCode: string;
  isOtpSent: boolean;
  allCountryCodes: Array<string>;
  mobileNumber: string;
  otpDigit1?: string;
  otpDigit2?: string;
  otpDigit3?: string;
  otpDigit4?: string;
  otpDigit5?: string;
  otpDigit6?: string;
  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  validMobileNumber?: boolean;
  mobileAccountNotFoundError?: boolean;
  validOtp?: boolean;
  wrongOtp?: boolean;
  pin?:any;
  errorTxt?:any;
  resendotp?:any;
  flag: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  labelTitle: string;
  validateOtpApiCallIdForLogin: any;
  validateOtpApiCallIdForSignUp: any;
  sendOtpToMobileApiCallIdForLogIn: any;
  sendOtpToMobileApiCallIdForSignUp: any;
  private readonly errorTitle = "Error";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      countryCodeSelected: "",
      isRegistration: false,
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,
      loading: false,
      countryCode: "+91",
      isOtpSent: false,
      allCountryCodes: configJSON.allCountryCodes,
      mobileNumber: "",
      otpDigit1: "",
      otpDigit2: "",
      otpDigit3: "",
      otpDigit4: "",
      otpDigit5: "",
      otpDigit6: "",
      flag:false,
      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
      validMobileNumber: false,
      mobileAccountNotFoundError: false,
      validOtp: false,
      wrongOtp: false,
      pin:"",
      errorTxt:"",
      resendotp:false
    
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    if (user.token) {
      //@ts-ignore
      this.props.history.push("/");
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (countryCode && userName && password) {
        this.setState({
          mobileNo: userName,
          placeHolderCountryCode: "+" + countryCode,
          countryCodeSelected: countryCode,
          password: password,
          checkedRememberMe: true,
        });
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (this.sendOtpToMobileApiCallIdForLogIn === apiRequestCallId) {
        this.sendOtpToMobileApiCallIdForLogIn = "";

        if (responseJson && !responseJson.error && !responseJson.errors) {
          this.setState({
            isOtpSent: true,
            mobileAccountNotFoundError: false,
            validMobileNumber: false,
          });
        } else if (responseJson.errors) {
          //Check Error Response
          this.setState({
            mobileAccountNotFoundError: true,
            flag:false,
            validMobileNumber: false,
          });
          this.sendLoginFailMessage();
          this.parseApiErrorResponse(responseJson.errors);
        }
      }

      if(this.sendOtpToMobileApiCallIdForSignUp === apiRequestCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({ isOtpSent: true ,flag:false});
        } else {
          //Check Error Response
          this.setState({ mobileAccountNotFoundError: true ,flag:false})
        }
      }

      if(this.validateOtpApiCallIdForLogin === apiRequestCallId) {
        if (
          responseJson &&
          responseJson.data &&
          responseJson.token &&
          !responseJson.error &&
          !responseJson.errors
        ) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          //Need To send Login token message to save for future call
          localStorage.setItem(
            "token",
            JSON.stringify({ token: responseJson.token })
          );
        
          this.props.history.push('/CampaignWebPage');
        } else {
          //Check Error Response
          if (responseJson.error) {
            this.setState({
              validOtp: false,
              wrongOtp: true,
            });
          }
          this.sendLoginFailMessage();
          this.parseApiErrorResponse(responseJson);
        }
      }

      if(this.validateOtpApiCallIdForSignUp === apiRequestCallId) {
        if (
          responseJson &&
          responseJson.is_otp_correct &&

          // responseJson.token &&
          !responseJson.error &&
          !responseJson.errors
        ) {

          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          //Need To send Login token message to save for future call

          //@ts-ignore
          this.props.history.push({
            pathname: "ConfirmSignupDetails",
            state: {
              userData: {
                isSignupUsingMobileNumber: true,
                mobileNumber: this.state.mobileNumber,
                countryCode: this.state.countryCode,
                email: "",
                name: "",
                referallCode: "",
                pin: `${this.state.otpDigit1}${this.state.otpDigit2}${this.state.otpDigit3}${this.state.otpDigit4}${this.state.otpDigit5}${this.state.otpDigit6}`

              },
              responseJson: responseJson,
            },
          });
          this.saveLoggedInUserData(responseJson);
        } else {
          this.setState({ errorTxt: responseJson.message })

          //Check Error Response
          if (responseJson.error) {
            this.setState({
              validOtp: false,
              wrongOtp: true,
            });
          }

          this.sendLoginFailMessage();
          this.parseApiErrorResponse(responseJson);
        }
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }


  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn(): boolean {
    if (
      this.state.countryCodeSelected === null ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorCountryCodeNotSelected
      );
      return false;
    }

    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorMobileNoNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      full_phone_number: this.state.countryCodeSelected + this.state.mobileNo,
      password: this.state.password,
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  sendOtpUsingMobileNumberForLogIn(): boolean {
    if (
      this.state.countryCode === null ||
      this.state.countryCode.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      this.state.mobileNumber === null ||
      this.state.mobileNumber.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
    }

    if (!configJSON.mobileValidationRegex.test(this.state.mobileNumber)) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }
    this.setState({flag:true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      full_phone_number: this.state.countryCode + "-" + this.state.mobileNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpToMobileApiCallIdForLogIn = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendLoginOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  sendOtpUsingMobileNumberForSignUp(): boolean {
    if (
      this.state.countryCode === null ||
      this.state.countryCode.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      this.state.mobileNumber === null ||
      this.state.mobileNumber.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
    }

    if (!configJSON.mobileValidationRegex.test(this.state.mobileNumber)) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }
    this.setState({flag:true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      full_phone_number: this.state.countryCode + "-" + this.state.mobileNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpToMobileApiCallIdForSignUp = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendSignUpOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  validateOtpForLogIn(): boolean {
   
    const {
      otpDigit1,
      otpDigit2,
      otpDigit3,
      otpDigit4,
      otpDigit5,
      otpDigit6,
    } = this.state;
    if (
      this.state.countryCode === null ||
      this.state.countryCode.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      this.state.mobileNumber === null ||
      this.state.mobileNumber.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
    }

    if (!configJSON.mobileValidationRegex.test(this.state.mobileNumber)) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      !otpDigit1 ||
      !otpDigit2 ||
      !otpDigit3 ||
      !otpDigit4 ||
      !otpDigit5 ||
      !otpDigit6
    ) {
      this.setState({
        validOtp: true,
        wrongOtp: false,
      });
      return false;
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      full_phone_number: this.state.countryCode + "-" + this.state.mobileNumber,
      pin:
        otpDigit1 + otpDigit2 + otpDigit3 + otpDigit4 + otpDigit5 + otpDigit6,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateOtpApiCallIdForLogin = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpValidationEndPointForLogin
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  validateOtpForSignUp(): boolean {
    const {
      otpDigit1,
      otpDigit2,
      otpDigit3,
      otpDigit4,
      otpDigit5,
      otpDigit6,
    } = this.state;
    if (
      this.state.countryCode === null ||
      this.state.countryCode.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      this.state.mobileNumber === null ||
      this.state.mobileNumber.length === 0
    ) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
    }

    if (!configJSON.mobileValidationRegex.test(this.state.mobileNumber)) {
      this.setState({
        validMobileNumber: true,
        mobileAccountNotFoundError: false,
      });
      return false;
    }

    if (
      !otpDigit1 ||
      !otpDigit2 ||
      !otpDigit3 ||
      !otpDigit4 ||
      !otpDigit5 ||
      !otpDigit6
    ) {
      this.setState({
        validOtp: true,
        wrongOtp: false,
      });
      return false;
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      full_phone_number: this.state.countryCode + "-" + this.state.mobileNumber,
      pin:
        otpDigit1 + otpDigit2 + otpDigit3 + otpDigit4 + otpDigit5 + otpDigit6,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateOtpApiCallIdForSignUp = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpValidationEndPointForSignUp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  sendOtpButtonClick(): void {
    this.setState({resendotp:false, otpDigit1:"",otpDigit2:"",otpDigit3:"",otpDigit4:"",otpDigit5:"",otpDigit6:""});
   document.getElementById("1st")?.focus();
    if (this.state.isRegistration) {
      this.sendOtpUsingMobileNumberForSignUp();
    } else {
      this.sendOtpUsingMobileNumberForLogIn();
    }
    setTimeout(() => {
      this.setState({resendotp:true})
     }, 60000);
  }

  confirmOtpButtonClick(): void {
    if (this.state.isRegistration) {
      this.validateOtpForSignUp();
    } else {
      this.validateOtpForLogIn();
    }
  }

  btnRegisterNowProps = {
    onRegisterClick: () => {
      this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
    },
  };

  btnLoginOnHeader = {
    onLoginPress: () => {
      this.props.navigation.navigate("SocialMediaAccountLoginScreen");
    },
  };

  handleCountryCodeChange = (code: string) => {
    this.setState({ countryCode: code });
  };

   
  handleMobileNumberChange = (number: string) => {
    this.setState({ mobileNumber: number });
  };

  handleOtpSentStatusChange = (status: boolean) => {
    this.setState({flag:false, isOtpSent: status,otpDigit1:"",otpDigit2:"",otpDigit3:"",otpDigit4:"",otpDigit5:"",otpDigit6:"", validOtp: false, wrongOtp: false, errorTxt: "" });
  };
  otpInputFocusChange = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentId: string,
    nextId: string | null,
    prevId: string | null
  ) => {
    if (e.key === "Backspace" && prevId) {
      const prevElement = document.getElementById(prevId);
      if (prevElement) {
        prevElement.focus();
      }
    } else if (/^\d$/.test(e.key) && nextId) {
      const nextElement = document.getElementById(nextId);
      if (nextElement) {
        nextElement.focus();
      }
    }
  };
  

  handleOtpChange = (otpKey: string, value: string) => {
    const newState = { otpKey: "" };
    // @ts-ignore
    newState[otpKey] = value;
    
    if (/^\d*$/.test(value)) {
      // @ts-ignore
    this.setState(newState);
    }
    this.setState({ validOtp: false, wrongOtp: false});
  };
  opacityValue=(flag:boolean)=>{
    if(flag){
      return 0.5
    }else{
      return 1
    }
  }
  // Customizable Area End
}
