Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";

exports.faqApiApiContentType = "application/json";
exports.faqApiEndPoint = "interactive_faqs/interactive_faqs";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorTitle = "Please enter a title";
exports.configErrorContent = "Please enter some content";
exports.investers_que = [
  {
    que: "Is there any monthly/ annual membership for the subscriber?",
    ans: "No. subscribers do not have to pay any fee to register at the platform, and remain as an active member, for lifetime."
  },
  {
    que: "Is Signup necessary to subscribe?",
    ans: "Yes. Sign up is necessary to subscribe via the platform. To signup visit our website. Click on the signup now button. Enter your mobile number. It will be verified by OTP. It is necessary to avoid fraudulent activities at the platform, and to comply with the User identification."
  },
  {
    que: "Why do I need to verify my PAN before making any investment? ",
    ans: "PAN is required to know your tax identity and KYC (know your customer) compliance. "
  },
  {
    que: "What is the minimum amount an subscriber can subscribe via the platform in any Start-up? ",
    ans: "INR 5000. However, some fund raising campaigns may have higher minimum investment amount."
  },
  {
    que: "Are there any charges for the subscribers required to be paid at the time of investment? ",
    ans: "Yes. We charge only 2% of the amount invested by you as legal cost and document handling cost. The cost of third party due diligence is also included in it."
  },
  {
    que: "How does the platform ensure the quality of the deal? Is there any independent due diligence process followed by the platform before listing any Start-up for fund raising?",
    ans: "Yes. We do third party independent due diligence before listing any Start-up at the platform. Due diligence is based on the Incoporation Certificate, PAN and GSTIN (GST identification number) of the Start-up. Based on the GSTIN verification law firm can easily verify their claim of revenue in the given year."
  },
  {
    que: "Is there any guarantee of return on my investment? ",
    ans: "subscribers are issued with legal documentary as proof for their investment. The documents issued to the subscribers have legal validity, and are prepared by third party law firms. It may take a couple of weeks after the end of the fund raising campaign at the platform."
  },
  {
    que: "What happens when I subscribe in any Start-up?",
    ans: "subscribers are issued with legal documentary as proof for their investment. The documents issued to the subscribers have legal validity, and are prepared by third party law firms. It may take a couple of weeks after the end of the fund raising campaign at the platform."
  },
  {
    que: "What happens if a Start-up does not reach its target goal of raising the fund? ",
    ans: "If a start-up fails to reach at least 80% of its target goal of raising the funds, every subscriber will be refunded of their amount invested to safeguard the interest of the subscribers. Please note, we do not pay any interest on the amount."
  },
];
exports.startups_que = [
  {
    que: "Is it free to raise funds at the platform?",
    ans: "Yes. It is free to raise funds at the platform. However, Start-up needs to pay a campaign activation charge of INR 25000 to activate the campaign at the platform. Activation charge is refundable, and is refunded at the end of the issue of the legal documents to all the subscribers."
  },
  {
    que: "What is the minimum amount that can be raised at the platform?",
    ans: "The minimum amount that can be raised at the platform is INR 250000."
  },
  {
    que: "What is the maximum amount that can be raised at the platform?",
    ans: "Presently the maximum amount that can be raised at the platform is INR 5000000. However, we are working to increase the limit. "
  },
  {
    que: "Do I get my fund as soon as the fund raising campaign ends?",
    ans: "The funds raised at the platform will remains in the escrow account until the time legal documents are issued to all the subscribers. It may take a couple of weeks to transfer the fund to the Start-up, after the end of the fund raising campaign."
  },
  {
    que: "Do the Start-ups need to prepare the required legal investment documents manually after successful completion of the fund raising activity?",
    ans: "No. Start-ups raising fund at the platform do not need to bother about the drafting of legal investment documents. We will be drafting and handling all the processes on your behalf, and as per agreed terms."
  },
  {
    que: "What happens if a Start-up does not reach its target goal of raising the fund?",
    ans: "If a start-up does not reach up to 80% of its fund raising goal, the campaign will be considered as ‘fail’, and the entire amount collected from the subscribers will be returned to the subscribers. Hence, start-ups will not be able to receive the proceeds of the fund raising campaign in the case of a ‘failed fund raising campaign’."
  },
  {
    que: "Does the platform execute any third party/ independent due diligence of the claims made by the Start-ups?",
    ans: "Yes. The platform has tie-ups with third party law firms for the due diligence, who do rigorous due diligence process based on the basic information (Incorporation Certificate, PAN, GSTIN, and others) provided by the Start-ups."
  },
  {
    que: "Who decides the Start-up Valuation?",
    ans: "Start-ups are free to decide their valuation. The platform do not intervene in any way in this regards, however, to avoid ‘failure of the campaign’ in reaching the minimum target, the valuation should be reasonable, as our subscribers are Smart"
  },
];
// Customizable Area End