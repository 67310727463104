import React from "react";
// Customizable Area Start
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import '../assets/css/PaymentsWeb.web.css';
import { Box, Grid, Button ,CircularProgress} from "@material-ui/core";
import PaymentsummaryWebController from "./PaymentsummaryWebController";
// Customizable Area End
class PaymentSummaryWeb extends PaymentsummaryWebController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start	
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
            {this.state.isLoading&& <div className="loader"><CircularProgress color="primary" size={50}/></div>}
            <Box className="paymentpage" id="Main" style={{opacity:this.state.isLoading ? 0.5 : 1}}>
                <HeaderWeb />
                <Grid container className="payment-main-box">
                    <div className="payment-summary-container">
                        <Box className="page-title-wrapper">
                            <span className="main-title">Investment Summary</span>
                        </Box>
                        <Grid className="payment-summary-table">
                            <div className="tbl-row">
                                <span className="key-span">Company Name</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>{this.state.company_name}</span>
                            </div>
                            <div className="tbl-row">
                                <span className="key-span">Investment Date</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>{this.state.investment_date}</span>
                            </div>

                            <div className="tbl-row">
                                <span className="key-span">Investment Amount</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>₹{this.state.investment_amount}</span>
                            </div>
                            <div className="tbl-row">
                            <span className="key-span" style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column" }}>Convenience Fee <p style={{ fontSize: "14px", textAlign: "left" }}>
                                    Processing fee ({this.state.pfee}%) +<br></br>Legal fee({this.state.lfee}%)
                                </p>
                                </span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>₹{this.state.convenience_fee}</span>
                            </div>
                            <div className="tbl-row">
                                <span className="key-span">GST ({this.state.gst_rate}%)</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>₹{this.state.gst_amount}</span>
                            </div>
                            <div className="tbl-row">
                                <span className="key-span">Wallet Amount</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right" }}>₹{this.state.walletAmount}</span>
                            </div>
                            <div className="tbl-row" style={{ background: "#f1fff2" }}>
                                <span className="key-span" style={{ color: "#36bc58" }}>Total Paid Amount</span>
                                <span className="value-span" style={{ display: "flex", justifyContent: "right", color: "#36bc58" }}>₹{this.state.paid_amount}</span>
                            </div>
                        </Grid>
                        <Button className="payment-Btn" id="btnnon" onClick={()=>this.NavigateToCampainListing()}>Continue Investing</Button>
                    </div>
                </Grid>
                <FooterWeb />
            </Box>
            </>
            // Customizable Area End	
        );
    }
}
// Customizable Area Start 
const PaymentsWebStyle = {}
//@ts-ignore
export default withStyles(PaymentsWebStyle)(withRouter(PaymentSummaryWeb))
// Customizable Area End