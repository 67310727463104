import PropTypes from "prop-types";
import React, { Component } from "react";
// Customizable Area Start
import { mobileImage } from "./assets";
// Customizable Area End

type Props = {
  testID: string;
  loginMobileNumberButtonText: string;
  onPress: () => void;
  // Customizable Area Start
  className?: any;
  // Customizable Area End
};

export default class CustomMobileNumberLogInButton extends Component<Props> {
  static propTypes = {
    testID: PropTypes.string,
    loginMobileNumberButtonText: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    // Customizable Area Start
    className: PropTypes.any,
    // Customizable Area End
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <div
      id="mobileNo"
        onClick={() => this.props.onPress()}
        className={this.props.className}
      >
        <img src={mobileImage} alt="Mobile No" />
        <span>
          {this.props.loginMobileNumberButtonText}
        </span>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area End