import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { loginImage } from "../../landingpage/src/assets";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  match: any;
  location: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileDetails: any;
  accounttype: any;
  user_name: string;
  user_image: any;
  user_email: any;
  user_phone: any;
  walletAmount:number|undefined;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBlockControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProfileDetailsCallId: any
  profileupdateCallId: any
  getWalletApicallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start 
    this.getProfileDetails = this.getProfileDetails.bind(this);
    this.getimageUpdate = this.getimageUpdate.bind(this);
    this.getProfileUpdate = this.getProfileUpdate.bind(this);
    this.custURL = this.custURL.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      profileDetails: "",
      accounttype: "",
      user_name: "",
      user_image: loginImage, 
      user_email: "",
      user_phone: "",
      walletAmount:0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  // Customizable Area Start
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  async receive(from: string, message: Message) {

    console.log("@@@ MESSAGE EMAIL ACCOUNT REGISTRATION ===", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (this.getProfileDetailsCallId === apiRequestCallId) {
        console.log(responseJson, "Blogs Api Response")
        if (responseJson && responseJson.data) {
          this.setState({
            profileDetails: responseJson.data.attributes,
            accounttype: responseJson.data.type,
            user_name: responseJson.data.attributes.full_name,
            user_email: responseJson.data.attributes.email,
            user_phone: responseJson.data.attributes.full_phone_number
          })
        }
      }
      if (apiRequestCallId === this.profileupdateCallId) {

        if (window.location.pathname === '/UserProfile') {
          window.location.reload();
        } else {
          this.props.history.push('UserProfile');
        }

        // this.setState({ passportdetails: responseJson.data,is_passportDataShow: true})

      }
      if(apiRequestCallId ===this.getWalletApicallID){
        this.handleWalletApiResponse(responseJson,errorReponse);
      }

    }

  }
  // Customizable Area End
  // Customizable Area Start
  setUserEmail = (event: any) => {
    if (event.target) {
      this.setState({
        user_email: event.target.value
      })
    }
  }
  setUserPhone = (event: any) => {
    if (event.target) {
      this.setState({
        user_phone: event.target.value
      })
    }
  }
  getProfileDetails = async () => {
    const user = this.getToken()

    var header = new Headers();
    header.append("token", user.token);
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailsCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_account`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getProfileUpdate = async (d1: any, d2: any, d3: any) => {
    const user = this.getToken()
    let header = new Headers();
    header.append("token", user.token);
    header.append("Content-Type", "application/json")
    let formdata = JSON.stringify({
      "full_name": d1,
      "full_phone_number": d2,
      "email": d3
    });

    const httpBody = formdata

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileupdateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_profile`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  custURL = (event: any) => {
    if (event.target) {
      const data = event.target.files[0]
      if (data) {
        const reader: any = new window.FileReader();
        reader.addEventListener("load", () => {
          this.setUserImage(reader.result)
        });
        reader?.readAsDataURL(data);
        this.getimageUpdate(data);
      }
    }
  };
  setUserImage = (result: any) => {
    this.setState({ user_image: result });
  }
  getToken = () => {
    return JSON.parse(localStorage.getItem("token") || "{}");
  }
  setUserName = (event: any) => {
    if (event.target) {

      this.setState({ user_name: event.target.value })
    }
  }
  handleKycBtn = () => {
    const { profileDetails } = this.state
    let redirect: string = "/EkycVerification";
    if( profileDetails?.adhaar || profileDetails?.passport){
      redirect = "/EkycVerificationStep3"
    } else if (profileDetails?.pan){
      redirect = "/EkycVerificationStep2"
    }
    this.props.history.push(redirect);
  }
  
  getimageUpdate = async (data: any) => {

    const user = this.getToken()
    let header = new Headers();
    header.append("token", user.token);
    var formdata = new FormData();
    formdata.append("profile_image", data, data.name);
    // let formdata = JSON.stringify({ 
    //     "profile_image": d1
    // });

    const httpBody = formdata

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileupdateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_profile`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getWalletsApiFunc = () => {
    const user = this.getToken()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWalletsApiEndPoint
      
      );
      this.getWalletApicallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  handleWalletApiResponse=(responseJson: any,errorReponse: any)=>{
    if (errorReponse) {
      console.log(errorReponse);
    }
    else if (responseJson) {
      this.setState({
        walletAmount:responseJson.wallet_amount
      })

    }
  }
  // Customizable Area End

}
