import PropTypes from "prop-types";
import React, { Component } from "react";
// Customizable Area Start
import { googleImage } from "./assets";
// Customizable Area End

type Props = {
  testID: string;
  loginGoogleButtonText: string;
  onPress: () => void;
  // Customizable Area Start
  className?: any;
  // Customizable Area End
};

export default class CustomGoogleLogInButton extends Component<Props> {
  static propTypes = {
    testID: PropTypes.string,
    loginGoogleButtonText: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    // Customizable Area Start
    className: PropTypes.any,
    // Customizable Area End
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <button
        id="googleLoginBtn"
        onClick={() => this.props.onPress()}
        className={this.props.className}
      >
          <img src={googleImage} alt="goggle-login"/>
          <span>
            {this.props.loginGoogleButtonText}
          </span>
      </button>
      // Customizable Area End
    );
  }
}