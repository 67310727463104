import React from "react";
import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON,
} from "./SocialMediaAccountWebController";
import CustomGoogleLogInButton from "./CustomGoogleLogInButton";
// Customizable Area Start
import {
  Container,
} from "@material-ui/core";
import CustomMobileNumberLogInButton from "./CustomMobileNumberLogInButton";
import LoginCommonHeaderWeb from "./LoginCommonHeader.web";
import LoginCommonFooterWeb from "./LoginCommonFooter.web";
import { withRouter } from "react-router-dom";
// Customizable Area End

export class SocialMediaAccountRegistrationScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountRegistrationScreen: SocialMediaAccountRegistrationScreen;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      loading: false,
      lookingForValue: "founder",
    };
    // Customizable Area End
  }

  render() {
    const { loading } = this.state;
    return (
      // Customizable Area Start
      <div className="container" id="accountreg">
        <Loader loading={loading} />
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

    <Container className="login-box-wrapper">
      <span className="labelTitle">
        {configJSON.signUpTitleText}
      </span>

      <CustomGoogleLogInButton
        testID="btnGoogleLogIn"
        //@ts-ignorets
        className="login-btn google"
        loginGoogleButtonText={configJSON.signUpGoogleButtonText}
        {...this.btnGoogleLogInProps}
      />

      <CustomMobileNumberLogInButton
        testID="btnMobileNumberLogIn"
        //@ts-ignorets
        className="login-btn mobile"
        loginMobileNumberButtonText={configJSON.signUpMobileNumberButtonText}
        {...this.btnMobileNumberSignUpProps}
      />

      <span className="didNotRegisterText" hidden={this.state.is_referredUser}>
        Already have an account ? &nbsp;
        <span
          className="registerNowText"
          id="registerNowT"
          onClick={() => this.btnLoginOnHeader.onLoginPress()}
        >Login</span>
      </span>
    </Container>

    <LoginCommonFooterWeb />
  </div>
);
// Customizable Area End
  }
}
//@ts-ignore
export default withRouter(SocialMediaAccountRegistrationScreen);