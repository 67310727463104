import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCallCommon } from "../../../components/src/ApiCallCommon.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    match: any;
    location: any
    // Customizable Area End
}

interface S {
    loading: boolean;
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    userValidationData: any;
    panNumber: string;
    panName: string;
    validPan: boolean;
    bankDetails: any;
    isShowBankDetails: boolean;
    accountNumber: string;
    ifscCode: string;
    validAccount: boolean;
    passportNo: string;
    dobno: any;
    passportdetails: any;
    is_passportSelect: boolean;
    is_adharContinue: boolean;
    is_passportDataShow: boolean;
    aadhaarNo: string;
    clientid: string;
    otpDigit1: string;
    otpDigit2: string;
    otpDigit3: string;
    otpDigit4: string;
    otpDigit5: string;
    otpDigit6: string;
    aadharvalid: boolean;
    passportvalid: boolean;
    validotp: boolean;
    resendotp:boolean;
    panDuplicationError: string;
    adharDuplicationError: string;
    bankAccDuplicationError: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PlanControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    panVerificationCallId: any;
    bankVerificationCallId: any;
    passportVerificationCallId: any;
    otpGetCallId: any
    otpCallId: any;
    profileupdateCallId: any;
    triggerEmailApiCallId: any;
    verifyPanDuplicateCallId: any;
    verifyAadharDuplicateCallId: any;
    verifyBankDuplicateCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.otpverifyEvent=this.otpverifyEvent.bind(this);
        this.verifyAadhaarDuplication = this.verifyAadhaarDuplication.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            loading: false,
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            userValidationData: {
                isSignupUsingMobileNumber: false,
                isSignupUsingGoogleAccount: false,
                mobileNumber: "",
                countryCode: "",
                email: "",
                name: "",
                referallCode: "",
            },
            panNumber: "",
            panName: "",
            validPan: true,
            bankDetails: "",
            isShowBankDetails: false,
            accountNumber: "",
            ifscCode: "",
            validAccount: true,
            passportNo: '',
            dobno: '',
            passportdetails: "",
            is_passportSelect: false,
            is_adharContinue: false,
            is_passportDataShow: false,
            aadhaarNo: "",
            clientid: "",
            otpDigit1: "",
            otpDigit2: "",
            otpDigit3: "",
            otpDigit4: "",
            otpDigit5: "",
            otpDigit6: "",
            aadharvalid: true,
            passportvalid: false,
            validotp: true,
            resendotp:false,
            panDuplicationError: "",
            adharDuplicationError: "",
            bankAccDuplicationError: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    token = localStorage.getItem("token")

    async componentDidMount() {
        // Customizable Area Start
        
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.panVerificationCallId) {

                this.panefunc(responseJson); 
            }

            if (apiRequestCallId === this.bankVerificationCallId) {
                console.log(responseJson, "bank")
                this.setState({ bankDetails: responseJson.data, validAccount: responseJson.success, isShowBankDetails: responseJson.success })
                this.getProfileUpdate(this.state.bankDetails)
            }
            if (apiRequestCallId === this.passportVerificationCallId) {
                console.log(responseJson, "passport")
                this.setState({ passportdetails: responseJson.data, is_passportDataShow: responseJson.success, passportvalid: true })
                this.getProfileUpdate(this.state.passportdetails)
            }
            if (apiRequestCallId === this.otpGetCallId) {
                console.log(responseJson, "passport")
                const {client_id}=responseJson.data
                this.setState({ clientid: client_id, is_adharContinue:responseJson.success, aadharvalid:responseJson.success })

            }
            if (apiRequestCallId === this.otpCallId) {
                console.log(responseJson, "otp")
             this.otpfunc(responseJson);
            }
            if (apiRequestCallId === this.profileupdateCallId) {
                console.log(responseJson, "profileupdate")
            }
            if(apiRequestCallId === this.triggerEmailApiCallId) {
                this.props.history.push('/CampaignWebPage');
            }

            this.ekycDuplicationApiResponse(responseJson, apiRequestCallId);
        }
        // Customizable Area End 
    }

    // Customizable Area Start
    ekycDuplicationApiResponse = (responseJson: any, apiRequestCallId:any) => {

        if(apiRequestCallId === this.verifyPanDuplicateCallId) {
            if(responseJson?.duplicated) {
                this.setState({ panDuplicationError: responseJson?.message?.message });
            }else {
                this.panverifyEvent(this.state.panNumber);
            }
        }
        if(apiRequestCallId === this.verifyAadharDuplicateCallId) {
            if(responseJson?.duplicated) {
                this.setState({ adharDuplicationError: responseJson?.message?.message });
            }else {
                this.otpverifyEvent(this.state.aadhaarNo);
            }
        }
        if(apiRequestCallId === this.verifyBankDuplicateCallId) {
            if(responseJson?.duplicated) {
                this.setState({ bankAccDuplicationError: responseJson?.message?.message });
            }else {
                this.bankverifyEvent(this.state.accountNumber,this.state.ifscCode);
            }
        }
    }

    handleSkipBtnClick = async () => {
        const user = JSON.parse(localStorage.getItem("token") || "{}");

        const headers = {
            token: user.token,
            "Content-Type": "application/json"
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.triggerEmailApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/update_ekyc_pending_email`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PATCH"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    panefunc=(responseJson:any)=>{
        if(responseJson && responseJson.data){
            const {full_name}=responseJson.data;
        this.setState({ panName: full_name, validPan: responseJson.success })
       if(responseJson.success){
            const {pan_number}=responseJson.data;
           this.getProfileUpdate(pan_number)
       }
    }
    }
    otpfunc=(responseJson:any)=>{
        if(responseJson && responseJson.data){
            const {aadhaar_number}=responseJson.data
            this.getProfileUpdate(aadhaar_number)
             this.setState({ validotp: responseJson.success})
            if(responseJson.success) {
                this.props.history.push('/EkycVerificationStep3')
            }
        }
    }

    setAdharNo=(e:any)=>{
        if(e.target){
            this.setState({ 
                aadhaarNo: e.target.value, 
                aadharvalid: true,
                adharDuplicationError: "",
            })
        }
    }
    setAdharContinue=()=>{
        this.setState({ is_adharContinue: false, is_passportSelect: false })
    }
    setOtpValue=(otpDigit:number,e:any)=>{
        if(e.target){
        switch (otpDigit) {
            case 1:  this.setState({ otpDigit1: e.target.value, validotp: true });
            break;
            case 2:  this.setState({ otpDigit2: e.target.value, validotp: true });
            break;
            case 3:  this.setState({ otpDigit3: e.target.value, validotp: true });
            break;
            case 4:  this.setState({ otpDigit4: e.target.value, validotp: true });
            break;
            case 5:  this.setState({ otpDigit5: e.target.value, validotp: true });
            break;
            case 6:  this.setState({ otpDigit6: e.target.value, validotp: true });
            break;
            default : break;
        }
    }
       
    }
    setPassportNo=(e:any)=>{
        if(e.target){

            this.setState({ passportNo: e.target.value })
        }
    }
    setDobno=(e:any)=>{
        if(e.target){

            this.setState({ dobno: e.target.value })
        }
    }
    setpassportvalid=()=>{
        this.setState({ passportvalid: true })
    }
    setIs_passportDataShow=()=>{
        this.setState({ is_passportDataShow: false })
    }
    getProfileUpdate = async (d1: any) => {
   
        const user = JSON.parse(localStorage.getItem("token") || "{}");
        let header = new Headers();
        header.append("token", user.token);
        header.append("Content-Type", "application/json");
        if (this.state.panName) {
            let formdata = JSON.stringify({
                "pan": d1,
            });
            const httpBody = formdata
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileupdateCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `account_block/accounts/update_profile`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                httpBody
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }
        if (this.state.aadhaarNo) {
            let formdata = JSON.stringify({

                "adhaar": d1,
            });
            const httpBody = formdata
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileupdateCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `account_block/accounts/update_profile`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                httpBody
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }
        if (this.state.passportdetails) {
            let formdata = JSON.stringify({

                "passport": d1.passport_number,

            });
            const httpBody = formdata
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileupdateCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `account_block/accounts/update_profile`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                httpBody
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }
        if (this.state.bankDetails && this.state.validAccount) {
            const {ifsc_details,full_name}=d1;
            const {bank_name,ifsc}=ifsc_details;
            let data = {
                "bank_name": bank_name,
                //"bank_account_type": d1,
                "bank_account_holder_name": full_name,
                "bank_account_number": this.state.accountNumber,
                "bank_account_ifsc_code": ifsc,
                "is_ekyc_verified": true

            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileupdateCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `account_block/accounts/update_profile`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }

    }

    verifyPanDuplication = async (panNumber: any) => {
        const userToken = JSON.parse(localStorage.getItem("token") || "{}");

        const headers = {
            "token" : userToken.token,
            "Content-Type": "application/json",
        }

        this.verifyPanDuplicateCallId = await ApiCallCommon({
            header: JSON.stringify(headers),
            method: "POST",
            endPoint: configJSON.validateEkycDetails,
            body: JSON.stringify({ "pan": panNumber }),
        });
    }
    verifyAadhaarDuplication = async (adhaarNumber: any) => {
        const tokenUser = JSON.parse(localStorage.getItem("token") || "{}");

        const requestHeader = {
            "token" : tokenUser.token,
            "Content-Type": "application/json",
        }

        this.verifyAadharDuplicateCallId = await ApiCallCommon({
            method: "POST",
            header: JSON.stringify(requestHeader),
            endPoint: configJSON.validateEkycDetails,
            body: JSON.stringify({ "adhaar": adhaarNumber }),
        });
    }
    verifyBankDuplication = async (bankAccNumber: any) => {
        const requestToken = JSON.parse(localStorage.getItem("token") || "{}");
        const headers = {
            "Content-Type": "application/json",
            "token" : requestToken.token,
        }
        this.verifyBankDuplicateCallId = await ApiCallCommon({
            endPoint: configJSON.validateEkycDetails,
            header: JSON.stringify(headers),
            method: "POST",
            body: JSON.stringify({ "bank_account_number": bankAccNumber }),
        });
    }

    panverifyEvent = async (pano: any) => {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzA3MzkwNiwianRpIjoiN2VhYzVhZTQtZjI4My00Y2JhLTg0MTktMTdiMWY3ZWI1N2YwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnNvZnRudXRAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjMwNzM5MDYsImV4cCI6MTk3ODQzMzkwNiwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.aEHVMESfNpf_rRbcYutjGsFo6WbFL6Z7_HdCsdNAW6I");

        let formdata = JSON.stringify({
            "id_number": pano
        });

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.panVerificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `https://kyc-api.aadhaarkyc.io/api/v1/pan/pan`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            myHeaders
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log(requestMessage);

        return true;
    }
    bankverifyEvent = async (d1: any, d2: any) => {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzA3MzkwNiwianRpIjoiN2VhYzVhZTQtZjI4My00Y2JhLTg0MTktMTdiMWY3ZWI1N2YwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnNvZnRudXRAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjMwNzM5MDYsImV4cCI6MTk3ODQzMzkwNiwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.aEHVMESfNpf_rRbcYutjGsFo6WbFL6Z7_HdCsdNAW6I");

        let formdata = JSON.stringify({
            "id_number": d1,
            "ifsc": d2,
            "ifsc_details": true
        });

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.bankVerificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `https://kyc-api.aadhaarkyc.io/api/v1/bank-verification/`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            myHeaders
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log(requestMessage);

        return true;
    }
    passportverifyEvent = async (d1: any, d2: any) => {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzA3MzkwNiwianRpIjoiN2VhYzVhZTQtZjI4My00Y2JhLTg0MTktMTdiMWY3ZWI1N2YwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnNvZnRudXRAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjMwNzM5MDYsImV4cCI6MTk3ODQzMzkwNiwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.aEHVMESfNpf_rRbcYutjGsFo6WbFL6Z7_HdCsdNAW6I");

        let formdata = JSON.stringify({
            "id_number": d1,
            "dob": d2
        });

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.passportVerificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `https://kyc-api.aadhaarkyc.io/api/v1/passport/passport/passport-details`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            myHeaders
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log(requestMessage);

        return true;
    }

    otpverifyEvent = async (d1: any) => {
        this.setState({resendotp:false, otpDigit1:"",otpDigit2:"",otpDigit3:"",otpDigit4:"",otpDigit5:"",otpDigit6:""});
       
        const temp=document.getElementById("ip1");
        if(temp!=null){
        temp.focus();}
        setTimeout(() => {
            this.setState({resendotp:true})
           }, 60000);
        if(this.state.aadhaarNo === ""){
            this.setState({ aadharvalid: false });
            return 0;
        }
        else{
            if(!this.state.is_passportSelect && !this.state.aadharvalid) {
                this.setState({ is_adharContinue: true });
            }
        }
        
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzA3MzkwNiwianRpIjoiN2VhYzVhZTQtZjI4My00Y2JhLTg0MTktMTdiMWY3ZWI1N2YwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnNvZnRudXRAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjMwNzM5MDYsImV4cCI6MTk3ODQzMzkwNiwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.aEHVMESfNpf_rRbcYutjGsFo6WbFL6Z7_HdCsdNAW6I");

        let formdata = JSON.stringify({
            "id_number": d1
        });

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otpGetCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-v2/generate-otp`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            myHeaders
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log(requestMessage);

        return true;
    }
    otpsendverifyEvent = async () => {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MzA3MzkwNiwianRpIjoiN2VhYzVhZTQtZjI4My00Y2JhLTg0MTktMTdiMWY3ZWI1N2YwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnNvZnRudXRAc3VyZXBhc3MuaW8iLCJuYmYiOjE2NjMwNzM5MDYsImV4cCI6MTk3ODQzMzkwNiwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.aEHVMESfNpf_rRbcYutjGsFo6WbFL6Z7_HdCsdNAW6I");

        let formdata = JSON.stringify({
            "client_id": this.state.clientid,
            "otp": `${this.state.otpDigit1}${this.state.otpDigit2}${this.state.otpDigit3}${this.state.otpDigit4}${this.state.otpDigit5}${this.state.otpDigit6}`
        });

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otpCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-v2/submit-otp`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            myHeaders
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log(requestMessage);

        return true;
    }


    btnRegisterNowProps = {
        onRegisterClick: () => {
            this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
        },
    };
    inputfocus = (elmnt: any) => {
        console.log(elmnt, '$$');

        const optInputs: any = document.querySelectorAll('.otpInput');
        if (!elmnt) {
            return;
          }
          
          const { key, target } = elmnt;
          const { tabIndex } = target;
          
          const next = key === 'Delete' || key === 'Backspace' ? tabIndex - 2 : tabIndex;
          
          if (next > -1 && optInputs[next] || (next < 6 && optInputs[next]) ) {
            optInputs[next].focus();
          } 
          

    }
    btnLoginOnHeader = {
        onLoginPress: () => {
            this.props.navigation.navigate("SocialMediaAccountLoginScreen");
        },
    };
    setPanNumber=(event:any) => {
        if(event.target){
            this.setState({
                panNumber:event.target.value, 
                panDuplicationError: "",
                validPan: true,
            });
        }
    }
    setBankDetails=(event:any) => {
        const { id, value } = event.target;
        this.setState({ ...this.state, [id]: value }, () => {
            this.setState({validAccount: true, bankAccDuplicationError: ""});
        });
    }
    handleUserDataChange = (event:any) => {
        const newState = { ...this.state.userValidationData };
        // @ts-ignore
        if(event.target){
            const { name ,value} = event.target;
            newState[name] = value;
            this.setState({ userValidationData: newState , aadharvalid: true});
        }
        // @ts-ignore
    };
    handlePassportToggle = (e: {target:{checked:boolean}}) => {
        const {checked}=e.target
        if (checked) {
          this.setState({ is_passportSelect: true });
        } else {
          this.setState({ is_passportSelect: false });
        }
      }
    // Customizable Area End
}
