import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const CONFIGJSON = require("./config")
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  value: any,
  termCheckboxFlag: boolean,
  open: boolean,
  openFail: boolean,
  count: number,
  redirectionHtml: any,
  feecal: any
  walletamount: any,
  totalPayaleAmount: any,
  investedAmount: string | null,
  investmentId: string | null,
  campaignName: string | null,
  amounts: number 
  pfee: number,
  lfee: number,
  gst: number,
  campaignId:string
  buildDeskUrl:string,
  feeDeductedFromWallet:number,
  isLoading: boolean,
  walletSecleted: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLogosCallId: string = "";
  getFeeDetailsCallId: string = "";
  walletCallId: string = "";
  buildDeskCallID:string=""
  walletBalance: number = 0;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getFeeDetails = this.getFeeDetails.bind(this);

    // Customizable Area Start
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleTermCheckboxChange = this.handleTermCheckboxChange.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.initializePayment = this.initializePayment.bind(this);
    this.handleFailClose = this.handleFailClose.bind(this);
    this.getFeeDetails = this.getFeeDetails.bind(this);
    this.getWalletAmount = this.getWalletAmount.bind(this);
    this.initializePayment = this.initializePayment.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      campaignId:"",
      value: 0,
      termCheckboxFlag: false,
      open: false,
      openFail: false,
      count: 0,
      redirectionHtml: "",
      feecal: '',
      walletamount: '',
      totalPayaleAmount: '',
      investedAmount: "",
      investmentId: "",
      campaignName: "",
      amounts: 0,
      pfee: 0,
      lfee: 0,
      gst: 0,
      buildDeskUrl:"",
      feeDeductedFromWallet: 0,
      isLoading:false,
      walletSecleted:false,


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.firstHalfConditions(responseJson, apiRequestCallId);
      if (this.getFeeDetailsCallId === apiRequestCallId) {
        this.setState({ feecal: responseJson,isLoading:false },()=>{
          this.getWalletAmount();
        })
      }
      if (this.walletCallId === apiRequestCallId) {
        this.walletBalance=responseJson.wallet_amount;  
        this.setState({ walletamount: responseJson.wallet_amount ,isLoading:false},()=>{
          this.getWalletSelect()
        })
      }
      if (this.buildDeskCallID === apiRequestCallId) {
        this.setState({ buildDeskUrl: responseJson.base_url })
      }
     

    }
    // Customizable Area End
  }
  // Customizable Area Start
  getWalletSelect=()=>{
    const isWallet=localStorage.getItem("isWalletSelected")
    this.setState({ walletSecleted: isWallet=="true"?true:false})
    if(isWallet=="true"){
      this.ifWalletEnabled()
    }
  }
  handlePaymetentSuccess = () => {

    const searchParams = new URLSearchParams(this.props.history.location.search);
    const status = searchParams.get('status');
    if(status===null){
      this.getUserDetails()
      this.getWalletAmount()
      return
    }
    if (status !== null && status === "true") {

      const amount = searchParams.get('amount');
      const campaign_name = searchParams.get('campaign_name');
      const investment_id = searchParams.get('investment_id');
      localStorage.setItem('isWalletSelected', "false");
      this.setState({
        investedAmount: amount,
        investmentId: investment_id,
        campaignName: campaign_name,
        open: true
      })
      setTimeout(() => {
        this.handleClose()
      },3000)
    } else {
      const amount = searchParams.get('amount');
      const campaign_name = searchParams.get('campaign_name');
     const isWallet= localStorage.getItem('isWalletSelected')
      this.setState({
        investedAmount: amount,
        campaignName: campaign_name, openFail: true,
        walletSecleted:Boolean(isWallet)
      })
      setTimeout(() => {
        this.handleFailClose()
      },3000)
    }
  }
  handleTabChange = (event: any, newValue: any) => {
    this.setState({
      value: newValue,
    })
  };
  handleCheckboxChange(event: any) {
    this.setState({walletSecleted:event.target.checked})
    localStorage.setItem('isWalletSelected', event.target.checked)
    if(!event.target.checked){
      this.setState({ walletamount:this.walletBalance,feeDeductedFromWallet:0,totalPayaleAmount:0})
    }else{

    this.ifWalletEnabled()
      
    }

}
ifWalletEnabled=()=>{
  const { proccessing_fee, legal_fee, amount, gst } = this.state.feecal;
      const { walletamount } = this.state;
      const feeTotal = proccessing_fee + legal_fee;
      const tempWalletAmount = Math.max(walletamount - feeTotal, 0);
      const tamount = feeTotal - walletamount;
      
      if (tamount > 0) {
        const ttamount = amount + gst + tamount;
        this.setState({ totalPayaleAmount: ttamount, walletamount: 0 ,feeDeductedFromWallet:this.walletBalance});
      } else {
        const ttamount = amount + gst;
        this.setState({ totalPayaleAmount: ttamount, walletamount: tempWalletAmount ,feeDeductedFromWallet:this.walletBalance-tempWalletAmount});
      }
}
  handleTermCheckboxChange(event: any) {
    this.setState({
      termCheckboxFlag: event.target.checked,
    })
    localStorage.setItem("termCheckboxFlag", event.target.checked)
  }
  handleContinueClick() {
    this.setState({
      count: this.state.count + 1,
    })
    if (this.state.count === 1) {
      this.setState({
        value: 1,
      })
    }
  }
  handleClose() {
    this.setState({
      open: false,
    })
    localStorage.removeItem('UserData');
    localStorage.removeItem('termCheckboxFlag');
    this.props.history.push({
      pathname: "/PaymentSummaryWeb",
      state: {
        investmentId: this.state.investmentId,
        pfee: this.state.pfee,
        legalFee: this.state.lfee,
        gst: this.state.gst,
      }
    });
  }
  handleFailClose() {
    this.setState({
      openFail: false,
    })
  }

  termsAndConditionsScreen = () => {
    this.props.navigation.navigate('TermsAndConditionsScreen')
  }
  privacyPolicyScreen = () => {
    this.props.navigation.navigate('PrivacyPolicyScreen')
  }

  firstHalfConditions(responseJson: any, apiRequestCallId: any) {
    if (this.getLogosCallId === apiRequestCallId) {
      console.log(responseJson);
    }
  }
  initializePayment = async () => {
    const token = JSON.parse(localStorage.getItem("token") || "{}").token
    if(!this.state.campaignId) {
      localStorage.removeItem('UserData')
      localStorage.removeItem('termCheckboxFlag');
      this.props.history.push('/CampaignWebPage');
      return;
    }

    const rawResponse = await fetch(`${this.state.buildDeskUrl}/billdesk_create`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "token": token
      },
      body: JSON.stringify({ "wallet_amount": this.state?.feeDeductedFromWallet, "investment_amount": this.state.feecal.amount, "convenience_fee": this.state.feecal.proccessing_fee + this.state.feecal.legal_fee, gst: this.state.feecal.gst, "total_payable_amount": this.state.totalPayaleAmount ? this.state.totalPayaleAmount :this.state.feecal.proccessing_fee + this.state.feecal.legal_fee + this.state.feecal.amount + this.state.feecal.gst, "campaign_id": this.state.campaignId })
    });
    const text = await rawResponse.text();
    
    this.setState({
      redirectionHtml: text,
    })
   
   
  }
  getCheckboxFlag = () => {
    const checkBox = localStorage.getItem("termCheckboxFlag");
    if(checkBox=="true"){
      this.setState({termCheckboxFlag:true})
    }else{
      this.setState({termCheckboxFlag:false})

    }

  }
  getUserDetails = () => {
    this.setState({isLoading: true});
    const userData = JSON.parse(localStorage.getItem("UserData") ?? "{}");
    if (Object.keys(userData).length > 0) {
      this.setState({
        amounts:userData.amounts,
        pfee:userData.pfee,
        lfee:userData.lfee,
        gst:userData.gst,
        campaignName:userData.campingTitle,
        campaignId: userData.campaignId
      },()=>{
        this.setState({isLoading:false})
        this.getFeeDetails()
      })
    }else {
      this.props.history.push('/CampaignWebPage');
    }
  }
  getFeeDetails = async () => {
    this.setState({isLoading: true});
    const amount = this.state.amounts;
    const user = JSON.parse(localStorage.getItem("token") || "{}");

    const header = {
      "Content-Type": CONFIGJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeeDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${CONFIGJSON.callculateFeeApiEndpoint}?amount=${amount}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      CONFIGJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getWalletAmount = async () => {
    this.setState({isLoading: true});
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    const header = {
      "Content-Type": CONFIGJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.walletCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${CONFIGJSON.getWalletApiEndpoint}?token=${user.token}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      CONFIGJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getBuildeskUrl = async () => {

    const user = JSON.parse(localStorage.getItem("token") || "{}");
    const header = {
      "Content-Type": CONFIGJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.buildDeskCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      CONFIGJSON.getBuildDeskUrlApiEndpoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      CONFIGJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  // Customizable Area End
}