import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?:any;
  openState?: any;
  onCloseFunc?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  askEkycDialog: boolean;
  askSignupDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EkycDialogControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      askEkycDialog: true,
      askSignupDialog: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  handleAskEkycDialog = () => {
    this.setState({ askEkycDialog: false });
  }
  handleAskSignupDialog = () => {
    this.setState({ askSignupDialog: false });
  }

  handleKyc = () => {
     const store = JSON.parse(localStorage.getItem('myuser') || "");
     let redirectTo: string = "/EkycVerification";
     if( store?.attributes?.adhaar || store?.attributes?.passport){
       redirectTo = "/EkycVerificationStep3"
     } else if (store?.attributes?.pan){
       redirectTo = "/EkycVerificationStep2"
     }
     this.props.history.push(redirectTo);
  }
  // Customizable Area End
}
