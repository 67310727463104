Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.createAccountURL = "account_block/accounts";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";
exports.clientID =
  "27403751587-r2ah7gjv31okjammgnm5g09fei2793f9.apps.googleusercontent.com";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";
exports.confirmUserDetialsURL = "account_block/accounts/validate_user_details";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";
exports.putHttpRequest = "PUT";
exports.smsaccountEndPointForSignUp="account_block/accounts/sms_account_creation";

exports.signUpGoogleButtonText = "Sign Up with Google";
exports.signUpMobileNumberButtonText = "Register with Mobile Number";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";
exports.signUpTitleText = "Signup";
exports.signUpBodyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s";

exports.thanksMessageAfterSignup = "Please confirm your details";
exports.thankYouRegistrationMessage = "Thank you for registration";
exports.questionPageDescription =
  "Here are some questions and we want you to give answers so you can proceed ahead";
exports.questions = [
  "Do you know that startup investment may result into high gain or total loss of the capital ?",
  "Do you know that startup investment is highly illiquid?",
  "Do yo know the startup investment is risky?",
  "Startups do not pay dividends every quarter to their subscribers. Are you aware with this fact?",
];

exports.emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
exports.mobileValidationRegex = /^([+]\d{2})?\d{10}$/;
exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";
// Customizable Area End
