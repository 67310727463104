// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen.web';
import CreditdebitCardPayments from '../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Couponcodegenerator from '../../blocks/couponcodegenerator/src/Couponcodegenerator';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import EkycVerification from '../../blocks/EkycVerification/src/EkycVerification';
import EkycVerificationStep2 from '../../blocks/EkycVerification/src/EkycVerificationStep2.web';
import EkycVerificationStep3 from '../../blocks/EkycVerification/src/EkycVerificationStep3.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
import Interactivefaqs from '../../blocks/interactivefaqs/src/LearnFaqs.web';
import AddInteractivefaqs from '../../blocks/interactivefaqs/src/AddInteractivefaqs';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import DashboardGuests from '../../blocks/DashboardGuests/src/DashboardGuests.web';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBlock.web';
import UserProfileEditBasicBlock from '../../blocks/user-profile-basic/src/UserProfileEdit.web';
import BhimUpiIntegration2 from '../../blocks/BhimUpiIntegration2/src/BhimUpiIntegration2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import DocusignIntegration from '../../blocks/DocusignIntegration/src/DocusignIntegration';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen.web';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
import BlogPostDetails from '../../blocks/BlogPostsManagement/src/BlogPostDetails.web';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import Payments from '../../blocks/Payments/src/Payments';
import Referrals from '../../blocks/Referrals/src/Referrals';
import ReferAndEarn from '../../blocks/Referrals/src/ReferAndEarn.web';
import EkycVerification2 from '../../blocks/EkycVerification2/src/EkycVerification2';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CfWhatsappIntegration34 from '../../blocks/CfWhatsappIntegration34/src/CfWhatsappIntegration34';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import LandingWebPage from '../../blocks/landingpage/src/LandingWebPage.web';
import CampaignWebPage from '../../blocks/landingpage/src/CampaignWebPage.web';
import RaiseForm from '../../blocks/ContentManagement/src/RaiseForm.web';
import CampaignDetailWebPage from '../../blocks/landingpage/src/CampaignDetailWebPage.web';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web';
import MobileAccountRegistrationBlock from '../../blocks/mobile-account-registration/src/MobileAccountRegistrationBlock.web';
import QuestionAfterSignUp from '../../blocks/mobile-account-registration/src/QuestionAfterSignUp.web';
import DashboardWebPage from '../../blocks/dashboard/src/Dashboard.web';
import Charter from '../../blocks/landingpage/src/CharterScreen.web';
import EkycVerifications from '../../blocks/EkycVerification/src/EkycVerifications.web';
import PaymentsWeb from "../../blocks/Payments/src/PaymentsWeb.web";
import PaymentSummaryWeb from "../../blocks/Payments/src/PaymentSummaryWeb.web";
import Checkout from "../../blocks/AutomaticCheckoutCalculation2/src/Checkout.web";
import WalletScreen from '../../blocks/CfWallet5/src/WalletScreen.web';
import TermsAndConditionsScreen from '../../blocks/TermsAndConditions/src/TermsAndConditionsScreen.web';
import PrivacyPolicyScreen from '../../blocks/PrivacyPolicy/src/PrivacyPolicyScreen.web';
import PortfolioWeb from '../../blocks/DashboardGuests/src/Portfolio.web';
import ConfirmSignupDetails from '../../blocks/social-media-account/src/ConfirmSignupDetails.web';


const routeMap = {
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: '/AutomaticCheckoutCalculation2'
  },
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: '/BlogPostsManagement'
  },
  BlogPostDetails: {
    component: BlogPostDetails,
    path: '/blogpostdetails'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Charter: {
    component: Charter,
    path: '/charter'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  ReferAndEarn: {
    component: ReferAndEarn,
    path: '/ReferAndEarn'
  },
  EkycVerification2: {
    component: EkycVerification2,
    path: '/EkycVerification2'
  },
  EkycVerifications: {
    component: EkycVerifications,
    path: '/EkycVerifications'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },

  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CfWhatsappIntegration34: {
    component: CfWhatsappIntegration34,
    path: '/CfWhatsappIntegration34'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  CreditdebitCardPayments: {
    component: CreditdebitCardPayments,
    path: '/CreditdebitCardPayments'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  MobileAccountRegistrationBlock: {
    component: MobileAccountRegistrationBlock,
    path: '/MobileAccountRegistrationBlock'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  EkycVerification: {
    component: EkycVerification,
    path: '/EkycVerification'
  },
  EkycVerificationStep2: {
    component: EkycVerificationStep2,
    path: '/EkycVerificationStep2'
  },
  EkycVerificationStep3: {
    component: EkycVerificationStep3,
    path: '/EkycVerificationStep3'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Invitefriends: {
    component: Invitefriends,
    path: '/Invitefriends'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  DashboardGuests: {
    component: DashboardGuests,
    path: '/DashboardGuests'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfile'
  },
  UserProfileEditBasicBlock: {
    component: UserProfileEditBasicBlock,
    path: '/UserProfileEdit'
  },
  BhimUpiIntegration2: {
    component: BhimUpiIntegration2,
    path: '/BhimUpiIntegration2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  DocusignIntegration: {
    component: DocusignIntegration,
    path: '/DocusignIntegration'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker'
  },
  LandingPage: {
    component: LandingWebPage,
    path: '/LandingPage'
  },
  WalletScreen: {
    component: WalletScreen,
    path: '/wallet'
  },
  Charter: {
    component: Charter,
    path: '/charter'
  },
  TermsAndConditionsScreen:{
    component: TermsAndConditionsScreen,
    path: '/TermsConditions'
  },
  PrivacyPolicyScreen: {
    component: PrivacyPolicyScreen,
    path: '/PrivacyPolicies'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  ConfirmSignupDetails: {
    component: ConfirmSignupDetails,
    path: '/ConfirmSignupDetails'
  },
  QuestionAfterSignUp: {
    component: QuestionAfterSignUp,
    path: '/QuestionAfterSignUp'
  },

  Home: {
    component: LandingWebPage,
    path: '/',
    exact: true
  },

  CampaignWebPage: {
    component: CampaignWebPage,
    path: '/CampaignWebPage',
    exact: true
  },
  RaiseWebPage: {
    component: RaiseForm,
    path: '/Raise',
    exact: true
  },
  DashboardWebPage: {
    component: DashboardWebPage,
    path: '/DashboardWebPage',
    exact: true
  },
  CampaignDetailWebPage: {
    component: CampaignDetailWebPage,
    path: '/CampaignDetailWebPage',
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  PaymentsWeb: {
    component: PaymentsWeb,
    path: '/paymentsWeb',
    exact: true
  },
  PaymentSummaryWeb: {
    component: PaymentSummaryWeb,
    path: '/PaymentSummaryWeb',
    exact: true
  },
  Checkout: {
    component: Checkout,
    path: '/checkout',
    exact: true
  },
  PortfolioWeb: {
    component: PortfolioWeb,
    path: '/PortfolioWeb/:id'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyCgItnHz3IJDWAuQzti3uI2of5wvicaU8k",
  authDomain: "anupam-e1345.firebaseapp.com",
  projectId: "anupam-e1345",
  storageBucket: "anupam-e1345.appspot.com",
  messagingSenderId: "27403751587",
  appId: "1:27403751587:web:dfd825c111afcbab9f6f16"
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
