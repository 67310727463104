import React from "react";
// Customizable Area Start
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import {
    Box,
    Grid,
    Button,
    Dialog,
    Backdrop,
    Tab,
    Tabs,
    Checkbox,CircularProgress
} from "@material-ui/core";
import { closeIcon, successIcon, failIcon } from "../src/assets";
import PaymentsWebController from "./PaymentsWebController.web"
import "../assets/css/PaymentsWeb.web.css"
// Customizable Area End

class PaymentsWeb extends PaymentsWebController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount(): Promise<void> {
        this.getUserDetails()
        this.handlePaymetentSuccess();
        this.getBuildeskUrl();  
        this.getCheckboxFlag()  
    }
    
    // Customizable Area Start	    

    renderFunction=()=>{
        if(this.state.redirectionHtml){
            return <div id="main2" dangerouslySetInnerHTML={{ __html: this.state.redirectionHtml }} />
        }
        else{
            return (
                <>
            {this.state.isLoading&& <div className="loader"><CircularProgress color="primary" size={50}/></div>}
             <Box className="paymentpage" id="Main" style={{opacity: this.state.isLoading? 0.5 : 1}}>
            <HeaderWeb />
            <Grid container className="payment-main-box">
                <Box className="page-title-wrapper">
                    <span className="main-title">Invest In {this.state.campaignName}</span>
                </Box>

                <Grid container className="payment-details-block">
                    <Box className="stapper">
                        <Tabs value={1} TabIndicatorProps={{ style: { background: 'black', border: "1px solid black" } }}>
                            <Tab label="Step1" disabled className="stapper-tab" />
                            <Tab label="Step2" disabled className="stapper-tab" />
                        </Tabs>
                    </Box>

                    <div className="make-payment">Make payment</div>

                    <div className="wallet-balance-box">
                        <div className="wallet-select">
                            <Checkbox
                                color="primary"
                                checked={this.state.walletSecleted}
                                id="wallet-select"
                                onChange={this.handleCheckboxChange}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <label htmlFor="wallet-select">Wallet balance </label>
                        </div>
                        <div className="wallet-balance">₹{this.state.walletamount}</div>
                    </div>

                    <Grid className="payment-info-tbl">
                        <div className="tbl-row">
                            <span className="key-span">Investment Amount</span>
                            <span className="value-span text-right">₹{this.state.feecal.amount}</span>
                        </div>
                        <div className="tbl-row">
                            <span className="key-span" style={{ flexWrap: "wrap" }}>
                                Convenience Fee <p>Processing fee ({this.state.pfee}%) +<br></br>Legal fee({this.state.lfee}%)</p>
                            </span>
                            <span className="value-span text-right">₹{this.state.feecal.proccessing_fee + this.state.feecal.legal_fee}</span>
                        </div>
                        <div className="tbl-row">
                            <span className="key-span">GST ({this.state.gst}%)</span>
                            <span className="value-span text-right">₹{this.state.feecal.gst}</span>
                        </div>
                        { this.state.walletSecleted ?
                            <>
                                <div className="tbl-row" id="main1">
                                    <span className="key-span">Wallet Amount</span>
                                    <span className="value-span text-right wallam">₹{this.state.feeDeductedFromWallet}</span>
                                </div>
                                <div className="tbl-row">
                                    <span className="key-span">Total Payable Amount</span>
                                    <span className="value-span text-right">₹{this.state.totalPayaleAmount}</span>
                                </div> </>
                            : <div className="tbl-row">
                                <span className="key-span">Total Payable Amount</span>
                                <span className="value-span text-right" id="lastc">₹{this.state.feecal.proccessing_fee + this.state.feecal.legal_fee + this.state.feecal.amount + this.state.feecal.gst}</span>
                            </div>
                        }

                    </Grid>

                    <Grid className="accept-terms-block">
                        <Checkbox
                            onChange={this.handleTermCheckboxChange}
                            color="primary"
                            id="terms-select"
                            className="term-checkbox"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            checked={this.state.termCheckboxFlag}
                        />
                        <label htmlFor="terms-select" className="term-text">
                            I agree to the <span onClick={this.termsAndConditionsScreen} style={{cursor:"pointer"}}>TERMS OF USE</span> and have read and understand <span onClick={this.privacyPolicyScreen} style={{cursor:"pointer"}}> PRIVACY POLICY</span>
                        </label>
                    </Grid>

                    <Button id="btnnon" className="payment-Btn" disabled={!this.state.termCheckboxFlag} onClick={this.initializePayment} >
                        Make Payment
                    </Button>
                </Grid>
            </Grid>

            {/* payment successfull modal  */}


            <Dialog
                className="Dialog Ask-Ekyc"
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <div className="dialog-inner">
                    <Button id="btnn" className="dialog-close-btn" onClick={this.handleClose}>
                        <img src={closeIcon} alt="close" width={"15px"} />
                    </Button>
                    <img src={successIcon} alt="" width={"80px"} />
                    <span className="dialog-title">Payment Successfull</span>
                    <span className="dialog-dis" style={{ fontFamily: "FuturaBT-Medium", color: "black", fontSize: "16px" }}>You have successfully invested ₹{this.state.investedAmount} in {this.state.campaignName}</span>
                </div>
            </Dialog>

            {/* payment failed modal  */}
            <Dialog
                className="Dialog Ask-Ekyc"
                open={this.state.openFail}
                onClose={this.handleFailClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <div className="dialog-inner">
                    <Button className="dialog-close-btn" onClick={this.handleFailClose}>
                        <img src={closeIcon} alt="close" id="btnedit" width={"15px"} />
                    </Button>
                    <img src={failIcon} alt="" width={"80px"} />
                    <span className="dialog-title">Payment Failed</span>
                    <span className="dialog-dis" style={{ fontFamily: "FuturaBT-Medium", color: "black", fontSize: "16px" }}>Your payment for investment of ₹{this.state.investedAmount} in {this.state.campaignName} has been failed. Please try again.</span>
                </div>
            </Dialog>
            <FooterWeb />
        </Box>
        </>)
        }
    }
    async componentDidUpdate(prevProps: any, prevState: any) {
        const { redirectionHtml } = this.state;
      
        if (prevState.redirectionHtml !== redirectionHtml) {
            const form = document.querySelector('form[name="f1"]');
            this.setState({redirectionHtml:""})
            if (form) {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.textContent = 'document.querySelector(\'form[name="f1"]\').submit()';
              document.body.appendChild(script);
            }
          }
      }
    // Customizable Area End


    render() {

        return (
            // Customizable Area Start
           this.renderFunction()
            // Customizable Area End	
        );
    }
}
// Customizable Area Start 
const PaymentsWebStyle = {}
//@ts-ignore
export default withStyles(PaymentsWebStyle)(withRouter(PaymentsWeb))
// Customizable Area End