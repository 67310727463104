import React from "react";
// Customizable Area Start
import Loader from "../../../components/src/Loader";
import MobileAccountLoginWebController, {
  Props,
  configJSON,
} from "./MobileAccountLoginWebController";
import {
  Box,
  Container,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { editIcon } from "./assets";
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";
import { withRouter } from "react-router-dom";
import '../assets/css/mobileAccountLogin.web.css';
// Customizable Area End
export class MobileAccountLoginBlock extends MobileAccountLoginWebController {
  static MobileAccountLoginBlock: MobileAccountLoginBlock;

  constructor(props: Props) {
    super(props);

  }

  render() {
    // Customizable Area Start
    const { loading, allCountryCodes } = this.state;
    // Customizable Area End
    
    return (
      // Customizable Area Start
      <Grid className="sendOtpContainer">
        <Loader loading={loading} />
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

        <Container className="inner-container">
          <span className="labelTitle">{configJSON.logInTitleText}</span>
          {/* <Text style={styles.titleWhySignUp}>{configJSON.logInBodyText}</Text> */}
          {!this.state.isOtpSent ? (
            <Box className="form-box">
              <span className="mobileNumberTextStyle">
                {configJSON.mobileNumberText}
              </span>

              <Container className="mobileno-fieldset">

                <FormControl className="country-code-fc">
                  <Select
                  data-test-id="country-code"
                    className="country-code-select"
                    value={this.state.countryCode}
                    onChange={(event) =>
                      this.handleCountryCodeChange(event.target.value as string)
                    }
                    disableUnderline={true}
                  >
                    {allCountryCodes.map((country) => {
                      return <MenuItem key={country} value={country}>{country}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  className="mobile-no-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                  data-test-id="phoneNumberInput"
                    id=""
                    type="text"
                    inputMode="numeric"
                    value={this.state.mobileNumber}
                    placeholder={configJSON.placeHolderMobile}
                    error={this.state.validMobileNumber || this.state.mobileAccountNotFoundError ? true : false}
                    onChange={(e) => {
                      this.handleMobileNumberChange(e.target.value);
                      this.setState({mobileAccountNotFoundError:false});
                    }}
                  />
                  {this.state.validMobileNumber && (
                    <span className="validation-msg">
                      Please enter valid mobile number.
                    </span>
                  )}
                  {this.state.mobileAccountNotFoundError && (
                    <span className="validation-msg accountNotFound">
                     Account not found, try logging with Google or check the number.
                    </span>
                  )}
                </FormControl>
              </Container>
              <>
              <button
                  data-test-id="submitPhoneNo"
                  className="send-otp-btn"
                  style={{border:"none",opacity:this.opacityValue(this.state.flag)}}
                  disabled={this.state.flag}
                  onClick={() => this.sendOtpButtonClick()}
                >
                  {configJSON.sendOtpText}
                </button>
              </>
            </Box>
          ) : (
            <Box className="form-box">
              <span className="mobileNumberTextStyle">
                {configJSON.mobileNumberText}
              </span>

              <Container className="mobileno-fieldset">
                <FormControl
                  className="country-code-fc"
                >
                  <Select
                    className="country-code-select"
                    value={this.state.countryCode}
                    onChange={(e) =>
                      this.handleCountryCodeChange(e.target.value as string)
                    }
                    disableUnderline={true}
                    disabled={true}
                  >
                    {allCountryCodes.map((country) => {
                      return <MenuItem key={country} value={country}>{country}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  className="mobile-no-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    id="edit-mobile-input"
                    type="text"
                    inputMode="numeric"
                    value={this.state.mobileNumber}
                    disabled={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                        className="editMobileBTN"
                          data-test-id="edit-mobile-btn"
                          aria-label="edit mobile number"
                          onClick={() => this.handleOtpSentStatusChange(false)}
                          edge="end"
                          style={{ width: 28, height: 19 }}
                        >
                          <img src={editIcon} height={16} width={16} /> &nbsp;
                          <span className="input-addon">
                            Edit
                          </span>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Container>

              <span className="otpTextStyle">{configJSON.otp}</span>

              <Container className="otp-container">
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 1,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp1"
                    id="1st"
                    type="text"
                    className="firstotp"
                    inputMode="numeric"
                    autoFocus
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e, "1st", "2nd", null);
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit1"
                    value={this.state.otpDigit1}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 2,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp2"
                    id="2nd"
                    type="text"
                    inputMode="numeric"
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e, "2nd", "3rd", "1st");
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit2"
                    value={this.state.otpDigit2}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 3,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp3"
                    id="3rd"
                    type="text"
                    inputMode="numeric"
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e,"3rd", "4th", "2nd");
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit3"
                    value={this.state.otpDigit3}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 4,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp4"

                    id="4th"
                    type="text"
                    inputMode="numeric"
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e,  "4th", "5th", "3rd");
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit4"
                    value={this.state.otpDigit4}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 5,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp5"

                    id="5th"
                    type="text"
                    inputMode="numeric"
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e, "5th", "6th", "4th");
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit5"
                    value={this.state.otpDigit5}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  className="opt-field-fc"
                  variant="outlined"
                >
                  <OutlinedInput
                    inputProps={{
                      maxLength: 1,
                      tabIndex: 6,
                      style: { textAlign: "center" },
                      inputMode: "tel"
                    }}
                    data-test-id="otp6"

                    id="6th"
                    type="text"
                    inputMode="numeric"
                    onKeyUp={(e) => {
                      this.otpInputFocusChange(e, "6th", "confirm-otp-button", "5th");
                    }}
                    error={this.state.validOtp || this.state.wrongOtp }
                    name="otpDigit6"
                    value={this.state.otpDigit6}
                    onChange={(e) =>
                      this.handleOtpChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
              </Container>

              <Container className="otp-additional-info-container" >
                {this.state.resendotp && 
                <div style={{ display: "flex", flexDirection: "column",cursor:"pointer" }}>
                  <span className="otpNotRecieved-msg">
                    {configJSON.otpNotRecieved}
                  </span>

                  <span className="resend-otp-link" onClick={() => this.sendOtpButtonClick()}>
                    {configJSON.resendOtp}
                  </span>
                </div> }
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
                  {this.state.validOtp && (
                    <span className="validation-msg txt-right">
                      Please enter valid otp.
                    </span>
                  )}
                  {this.state.wrongOtp && (
                    <span className="validation-msg txt-right">
                      Entered otp is wrong.
                    </span>
                  )}
                </div>
              </Container>

              <>
                <Box
                  className="confirm-otp-btn"
                  onClick={() => this.confirmOtpButtonClick()}
                  id="confirm-otp-button"
                  data-test-id="confirm-otp-button"
                >
                  {configJSON.confirmOtp}
                </Box>
              </>
            </Box>
          )}
        </Container>

        <LoginCommonFooterWeb />

        {/* Customizable Area Start */}
      </Grid>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withRouter(MobileAccountLoginBlock);