import React from "react";
// Customizable Area Start
import Loader from "../../../components/src/Loader";
import {
  Box,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import '../assets/css/QuestionScreen.web.css';
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";
import { withRouter } from "react-router-dom";
import { crossImage } from "./assets";
import QuestionAfterSignupController, { Props, configJSON } from "./QuestionAfterSignupController.web";
// Customizable Are End

export class QuestionAfterSignUp extends QuestionAfterSignupController {
  static QuestionAfterSignUp: QuestionAfterSignUp;

  constructor(props: Props) {
    super(props);

  }

  render() {
    // Customizable Are Start
    const { loading, questions, answersArray } = this.state;
    console.log(this.answerCount);
    // Customizable Are End

    return (
      // Customizable Are Start
      <Grid className="container" id="Main">
        <Loader loading={loading} />
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

        <Container className="container-inner">
          <span className="page-title">
            {configJSON.thankYouRegistrationMessage}
          </span>

          <span className="page-sub-title">
            {configJSON.questionPageDescription}
          </span>

          <Box className="questionMainContainer">
            {questions?.map((question, index) => {
                return (
                  <FormControl key={question}>

                    <span className="questionTextStyle">
                      <span className="question-index">
                        {`${index + 1}`}.
                      </span>{" "}
                      <span className="question-text">{`${question}`}</span>
                    </span>

                    <RadioGroup
                      value={answersArray.length > 0 && answersArray[index]}
                      row={true}
                      className="radio-btn-group"
                      id="radioGroup"
                      onChange={(e) =>
                        this.handleAnswerChange(index, e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        className="radio-fc"
                        control={
                          <Radio
                            style={{
                              color:
                                answersArray && answersArray[index] === "Yes"
                                  ? "#00BC4A"
                                  : "#A5A5A5",
                            }}
                            size="small"
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        className="radio-fc"
                        control={
                          <Radio
                            style={{
                              color:
                                answersArray && answersArray[index] === "No"
                                  ? "#00BC4A"
                                  : "#A5A5A5",
                            }}
                            size="small"
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              })}
            <>
              <Button
                className="confirm-buttton"
                disabled={this.answerCount !== 4 ? true : false}
                id="confirm-user-details-button"
                onClick={this.submitAnswers}
              >
                Confirm
              </Button>
            </>
          </Box>
        </Container>

        <LoginCommonFooterWeb />

        <>
          <Modal
            //@ts-ignore
            open={this.state.openImproveModal}
            onClose={this.closeImproveModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            className="text-model"
          >
            <Box className="validation-popup">
              <img
                src={crossImage}
                width={80}
                height={80}
              />
              <span className="popup-title">
                Please improve your knowledge on Startup investing.
              </span>
              <span className="model-discription">
                You should be aware about the risks involved with the startup
                investing.
              </span>
              <>
                <Box
                  className="pop-up-action-btn"
                  id="confirm-user-details-button"
                  onClick={this.closeImproveModal}
                >
                  Back
                </Box>
              </>
            </Box>
          </Modal>
        </>
      </Grid>
      // Customizable Are End
    );
  }
}

//@ts-ignore
export default withRouter(QuestionAfterSignUp);