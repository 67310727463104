import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const CONFIGJSON = require("./config")
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  investment_amount: number
  paid_amount: number
  company_name: string
  investment_date: string
  convenience_fee: number
  gst_amount: number
  pfee: number
  lfee: number
  gst_rate: number
  walletAmount: number
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentsummaryWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvestmenSummaryApiCallId: string = ""
  getWalletApicallID: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      investment_amount: 0,
      paid_amount: 0,
      company_name: "",
      investment_date: "",
      convenience_fee: 0,
      gst_amount: 0,
      pfee: 0,
      lfee: 0,
      gst_rate: 0,
      walletAmount: 0,
      isLoading:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.getInvestmenSummaryApiCallId === apiRequestCallId) {
        this.handleInvestmentSummary(responseJson)
      }
      if(apiRequestCallId ===this.getWalletApicallID){
        this.handleWalletApiResponse(responseJson,errorReponse);
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const {pfee,investmentId,gst,legalFee}=this.props.history.location.state
    this.setState({pfee,gst_rate:gst,lfee:legalFee})
    this.getInvestmentSummary(investmentId)
    this.getWalletsApiFunc()
  }


  NavigateToCampainListing=()=>{
    this.props.history.push("/CampaignWebPage")
  }
  getInvestmentSummary =  (investmentId: any) => {
    this.setState({isLoading:true})
    const user = JSON.parse(localStorage.getItem("token") || "{}");

    const header = {
      "Content-Type": CONFIGJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvestmenSummaryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${CONFIGJSON.getInvestmentSummaryApiEndpoint}/${investmentId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      CONFIGJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getWalletsApiFunc = () => {
    this.setState({isLoading:true})
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    const header = {
      "Content-Type": CONFIGJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      CONFIGJSON.getWalletsApiEndPoint
      
      );
      this.getWalletApicallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      CONFIGJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  handleInvestmentSummary = (responseJson: any) => {
    this.setState({ investment_amount: responseJson.investment_amount,
      paid_amount: responseJson.paid_amount,
      company_name: responseJson.company_name,
      investment_date: responseJson.investment_date,
      convenience_fee: responseJson.convenience_fee,
      gst_amount: responseJson.gst,
    isLoading:false})
  }
  handleWalletApiResponse=(responseJson: any,errorReponse: any)=>{
    if (errorReponse) {
      console.log(errorReponse);
    }
    else if (responseJson) {
      this.setState({
        walletAmount:responseJson.wallet_amount,
        isLoading:false
      })

    }
  }

  // Customizable Area End
}