import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?:any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  lookingForValue: string
  errorlogintxt?:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SocialMediaAccountWebController extends BlockComponent<Props, S, SS>
implements GoogleWebDelegate {
  // Customizable Area Start
  createAccountAPICallId: any;
  googleUser: any;
  loginWithGoogleAccount: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      loading: false,
      lookingForValue: "founder",
      errorlogintxt: "",
      // Customizable Area End
    };
  }

  // Customizable Area Start
  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    console.log(userInfo,'22');
      this.logInWithSocial(
        userInfo.email,
        userInfo.email,
        userInfo.id,
        this.props
      );
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  }; 

  googleLogIn = () => {
    const self = this;
      //@ts-ignore
      googleController.googleLogIn(this).then(
        function () {
          self.stopLoading();
          runEngine.debugLog("User SIGNED IN.");
        },
        function (error: any) {
          self.stopLoading();
          if (error.error === "popup_closed_by_user") {
            //handle window closed event
          }
        }
      );
    
  };

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    if (user.token) {
      //@ts-ignore
      this.props.history.push("/");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  loginWithGoogle(message:any){
    this.loginWithGoogleAccount = "";
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson &&
      responseJson.token &&
      !responseJson.error &&
      !responseJson.errors
    ) {
      this.saveLoggedInUserData(responseJson);
      localStorage.setItem(
        "token",
        JSON.stringify({ token: responseJson.token })
      );
      this.props.history.push('/CampaignWebPage');
    } else if (responseJson && responseJson.errors) {
      this.setState({errorlogintxt:"Account not found, try logging with your Mobile number."});
      this.parseApiErrorResponse([...responseJson.errors]);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.stopLoading();

    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
      this.openInfoPage();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.loginWithGoogleAccount != null &&
      this.loginWithGoogleAccount ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     this.loginWithGoogle(message)
    } else {
      runEngine.debugLog("GOIT");
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json",
    };

    const attributes = {
      email: incomingEmail,
      unique_auth_id: incomingId,
    };

    const httpBody = {
      data: {
        type: "social_account",
        attributes: attributes,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginWithGoogleAccount = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }
   
  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn();
      this.startLoading();
    },
  };

  btnMobileNumberLogInProps = {
    onPress: () => {
      this.props.navigation.navigate("MobileAccountLoginBlock");
    },
  };

  btnRegisterNowProps = {
    onRegisterClick: () => {
      this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
    },
  };

  btnLoginOnHeader = {
    onLoginPress: () => {
      this.props.navigation.navigate("SocialMediaAccountLoginScreen");
    },
  };
  // Customizable Area End
}
