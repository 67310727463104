import React from "react";
// Customizable Area Start
import { Grid } from '@material-ui/core';
import '../assets/css/CfWallet5.web.css';
import { contactIcon, greenBlock } from "./assets"
import DashBoardHeaderWeb from "../../dashboard/src/DashBoardHeader.web";
import DashboardSidebarWeb from "../../dashboard/src/DashboardSidebar.web";
import WalletScreenController, { Props } from "./WalletScreenController.web";
// Customizable Area End

export default class WalletScreen extends WalletScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid className="profile-page-main">
        <DashBoardHeaderWeb />
        <div className="profile-container-main">
          <DashboardSidebarWeb />
          <div className="profile-bg-main wallet-main">
            <div className="page-title">
              <img src={contactIcon} alt="contact icon"/>
              <span> Wallet</span>
            </div>

            <div className="wallet-balance-box">
              <div className="box-data">
                <span> Your Balance</span>
                <h4>₹{this.state.walletBalance}</h4>
              </div>
              <img src={greenBlock} width='200px' className="contact_icon" height={'auto'} />
            </div>
           {this.state.walletTransaction.length > 0 && <div className="transection-title">Your Transactions</div>}
           { this.state.walletTransaction.length > 0 && <div className="transection-box">
              {this.state.walletTransaction && this.state.walletTransaction.map((item:any)=>(
                 <div className="record-box">
                 <div>{item.is_refer_earned ? "Referral Bounus" : "Investment transaction deduction"} </div>
                 <div className={item.is_refer_earned? "credited" : "debited" } >{item.is_refer_earned ? "+" : "-"}₹{item.amount}</div>
                 </div>
              ))}
          </div>}
        </div>
        </div>
      </Grid>
      // Customizable Area End
    );
  }
}
