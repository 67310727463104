import React from "react";
import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON,
} from "./SocialMediaAccountWebController";
import CustomGoogleLogInButton from "./CustomGoogleLogInButton";
// Customizable Area Start
import {
  Container,
} from "@material-ui/core";
import '../assets/css/SocialMediaLogin.web.css';
import CustomMobileNumberLogInButton from "./CustomMobileNumberLogInButton";
import LoginCommonHeaderWeb from "./LoginCommonHeader.web";
import LoginCommonFooterWeb from "./LoginCommonFooter.web";
import { withRouter } from "react-router-dom";
//Customizable Area End

class SocialMediaAccountLoginScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountLoginScreen: SocialMediaAccountLoginScreen;

  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.state = {
      loading: false,
      lookingForValue: 'founder',
    };
    //Customizable Area End
  }

  render() {
    //Customizable Area Start
    const { loading } = this.state;
    //Customizable Area End
    return (
      //Customizable Area Start
      <div className="container" id="Main">
        <Loader loading={loading} />
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

    <Container className="login-box-wrapper">
      <span className="labelTitle">
        {configJSON.logInTitleText}
      </span>

      <CustomGoogleLogInButton
        testID="btnGoogleLogIn"
        //@ts-ignorets
        className="login-btn google"
        loginGoogleButtonText={configJSON.loginGoogleButtonText}
        {...this.btnGoogleLogInProps}
      />
      <span className="validation-msg">{this.state.errorlogintxt}</span>
      <CustomMobileNumberLogInButton
        testID="btnMobileNumberLogIn"
        //@ts-ignorets
        className="login-btn mobile"
        loginMobileNumberButtonText={configJSON.loginMobileNumberButtonText}
        {...this.btnMobileNumberLogInProps}
      />

      <span className="didNotRegisterText">
        Did't Register yet ? &nbsp;
        <span
        id="registerNowText"
          className="registerNowText"
          onClick={() => this.btnRegisterNowProps.onRegisterClick()}
        >Register now </span>
      </span>
    </Container>

    <LoginCommonFooterWeb />
  </div>
  //Customizable Area End
);
  }
}

//@ts-ignore
export default withRouter(SocialMediaAccountLoginScreen);