Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";
exports.getWalletApiEndpoint="get_wallet";
exports.callculateFeeApiEndpoint="bx_block_invest_raises/calculate_fee"
exports.initializePayementApiEndpoint="billdesk_create"
exports.btnExampleTitle = "CLICK ME";
exports.getWalletsApiEndPoint="get_wallet";
exports.getInvestmentSummaryApiEndpoint="bx_block_invest_raises/investments"
exports.getBuildDeskUrlApiEndpoint="bx_block_invest_raises/backend_url"
// Customizable Area End