export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require('../assets/arrow.png');
export const storeLogo = require('../assets/logo.png');
export const toggleIcon = require('../assets/toggle-icon.png');
export const userProfile = require('../assets/user-profile.png');
export const myprofileIcon = require('../assets/myprofile_icon.png');
export const portfolioIcon = require('../assets/portfolio.png');
export const portfolioActiveIcon = require('../assets/portfolio_active.png');
export const liveCampaignsIcon = require('../assets/live_campaigns_icon.png');
export const refer_earnIcon = require('../assets/refer_earn_icon.png');
export const totalInvestmentIcon = require('../assets/total_investment_icon.png');
export const walletIcon = require('../assets/wallet_icon.png');
export const searchIcon = require('../assets/search_icon.png');
export const clearIcon = require('../assets/clear_icon.png');
export const graphImg = require('../assets/graph.png');
export const dateIcon = require('../assets/date_icon.png');
export const portfolioTitleIcon = require('../assets/portfolio_icon.png');
export const infoIcon = require('../assets/icon_info.png');
export const refer_earnActiveIcon = require('../assets/refer_earn_icon_active.jpeg');