// Customizable Area Start
import React from "react";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";

//MUI COMPONENTS
import { Box, Container, Button, Link, IconButton, Drawer, List, ListItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import '../assets/css/MainHeader.web.css';
// Customizable Area Start
//RESOURCES
// const bannerBgImage = require("../assets/banner-bg.png");
import { logoImg, loginImage } from "../src/assets";
import LandingPageController from "./LandingPageController.web";
class Header extends LandingPageController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleDrawerOpen = () => {
    this.setState({ drawerStatus: true })

  };

  handleDrawerClose = () => {
    this.setState({ drawerStatus: false })
  };

  async componentDidMount(): Promise<void> {
    this.getProfileDetails();
    const urlID = window.location.pathname.split('/')[2];
    this.setState({ urlID: urlID });
  }

  renderLoggedinUserMenu = () => {
    return (
      <>
        <div className="drawer-header">
          <div className="loggedin-user-info">
            <img src={this.state.userInfo?.profile_image || loginImage} alt="" width={"45px"} height={"45px"} />
            <div className="info">
              <span>{this.state.userInfo?.full_name || ""}</span>
              <small>{this.state.userInfo?.email || ""}</small>
            </div>
          </div>
        </div>
        <List className="navlink-list">
          <ListItem button className="nav-link" selected={this.state.currUrl === "/UserProfile"}>
            <Link href="/UserProfile" id="myProfileLink">My Profile</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/DashboardGuests"}>
            <Link href="/DashboardGuests">Portfolio</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/ReferAndEarn"}>
            <Link href="/ReferAndEarn">Refer & Earn</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === '/CampaignWebPage' || this.state.currUrl === `/CampaignDetailWebPage/${this.state.urlID}`}>
            <Link href="/CampaignWebPage">Campaigns</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/raise"}>
            <Link href="/raise">Raise</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/BlogPostsManagement"}>
            <Link href="/BlogPostsManagement">Blogs</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/InteractiveFaqs"}>
            <Link href="/InteractiveFaqs">Learn</Link>
          </ListItem>
          <ListItem button className="nav-link">
            <Link onClick={this.handleLogOut}>Logout</Link>
          </ListItem>
        </List>
      </>
    );
  }

  renderGuestUserMenu = () => {
    return (
      <>
        <div className="drawer-header">
          <img src={logoImg} alt="logoImg" />
        </div>
        <List className="navlink-list">
          <ListItem button className="nav-link" selected={this.state.currUrl === '/CampaignWebPage' || this.state.currUrl === `/CampaignDetailWebPage/${this.state.urlID}`}>
            <Link href="/CampaignWebPage">Campaigns</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/raise"}>
            <Link href="/raise">Raise</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/BlogPostsManagement" || this.state.currUrl === `/blogpostdetails/${this.state.urlID}`}>
            <Link href="/BlogPostsManagement">Blogs</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/InteractiveFaqs"}>
            <Link href="/InteractiveFaqs">Learn</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/SocialMediaAccountLoginScreen"}>
            <Link href="/SocialMediaAccountLoginScreen">Login</Link>
          </ListItem>
          <ListItem button className="nav-link" selected={this.state.currUrl === "/SocialMediaAccountRegistrationScreen"}>
            <Link href="/SocialMediaAccountRegistrationScreen">Register</Link>
          </ListItem>
        </List>
      </>
    );
  }
  // Customizable Area End
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.white,
        fontSize: 24,
        right: 10,
      },
      tooltip: {
        backgroundColor: theme.palette.common.white,
        padding: 0,
        top: 15,
        margin: 0,
      },
    }));
    function BootstrapTooltip(props: any) {
      const classes = useStylesBootstrap();
      return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
      // Customizable Area Start
      <>
        <Container fixed>
          <Box className={classes.mainHeader} id="HeaderMain">
            <Link
              id="logoImage"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.history.location.pathname != "/"
                  && this.props.history.push("/")
              }}
            >
              <img src={logoImg} alt="Logo Img" />
            </Link>

            {/*** Desktop Header ***/}
            <Box className={`${classes.rightNav} desktop-topnav`}>
              <Link href="/CampaignWebPage" id="investLink">Campaigns</Link>
              <Link
                id="raiseLink"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.history.location.pathname != "/raise"
                    && this.props.history.push("/raise")

                }}
              >
                Raise
              </Link>
              <Link href="/BlogPostsManagement" id="blogLink">Blog</Link>
              <Link href="/InteractiveFaqs" id="learnLink">Learn</Link>
              {this.state.userToken ? (
                <ClickAwayListener
                  data-test-id="clickList"
                  onClickAway={() => this.handleTooltipClose()}
                >
                  <div>
                    <BootstrapTooltip
                      id="bootstarpTooltip"
                      data-test-id="bootstarpTooltip"
                      interactive={true}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={this.state.toolTip}
                      onClick={() => this.handleTooltipOpen()}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="bottom-end"
                      title={
                        <div
                          style={{
                            width: "122px",
                            height: "124px",
                            display: "flex",
                            paddingLeft: 16,
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            alignItems: "start",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#353535",
                              cursor: "pointer",
                            }}
                            onClick={() => this.props.history.push('/UserProfile')}
                            id="tooltipProfile"
                          >
                            My Profile
                          </span>
                          <span
                            id="dashboard"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#353535",
                              cursor: "pointer",
                            }}
                            onClick={()=>this.props.history.push('/DashboardGuests')}
                          >
                            Portfolio
                          </span>
                          <span
                            id="logout"
                            data-test-id="logout"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#535353",
                              cursor: "pointer",
                            }}
                            onClick={this.handleLogOut}
                          >
                            Logout
                          </span>
                        </div>
                      }
                    >
                      <img
                        id="tooltips"
                        src={this.state.userInfo?.profile_image  || loginImage}
                        height="48"
                        width="48"
                        style={{ cursor: "pointer", marginLeft: 25, borderRadius: "50%", objectFit: "cover" }}
                      />
                    </BootstrapTooltip>
                  </div>
                </ClickAwayListener>
              ) : (
                <>
                  <Link href="/SocialMediaAccountLoginScreen">Login</Link>
                  <Link href="/SocialMediaAccountRegistrationScreen">
                    <Button className={`${classes.customButton} register-btn-nv`}>Register</Button>
                  </Link>
                </>
              )}
            </Box>

            {/*** mobile Header ***/}
            <Box className={`${classes.rightNav} mobile-topnav`}>
              <IconButton
                color="inherit"
                className="toggle-Button"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start">
                <MenuIcon />
              </IconButton>

              <Drawer
                className='drawer-container'
                variant="temporary"
                anchor="right"
                hideBackdrop={false}
                transitionDuration={500}
                open={this.state.drawerStatus}
                onClose={this.handleDrawerClose}
              >
                {this.state.userToken ? (this.renderLoggedinUserMenu()) : (this.renderGuestUserMenu())}
              </Drawer>
            </Box>
          </Box>
        </Container>
        <Box className={classes.hr} />
      </>
      // Customizable Area End
    );
  }
}
//NAVBAR CSS
const NavbarStyle = {
  mainHeader: {
    padding: "15px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  hr: {
    borderTop: "2px solid #E0E0E0",
  },
  rightNav: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "& .MuiTypography-root": {
      padding: "0 15px",
      color: "#353535",
      opacity: "0.79",
      fontFamily: "Futura Bk BT, sans-serif",
      fontWeight: "400",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  fonts: {
    fontFamily: "Futura Bk BT, sans-serif",
    fontWeight: "400",
  },
  customButton: {
    color: "#00BC4A",
    border: "1px solid #00BC4A",
    fontFamily: "Futura Bk BT, sans-serif",
    padding: "5px 10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#00BC4A",
      color: "#fff",
    },
    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: 600,
    },
  },
};
// Customizable Area End
//@ts-ignore
export default withStyles(NavbarStyle)(withRouter(Header));
// Customizable Area End
