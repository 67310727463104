import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  match?: any;
  location?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  walletBalance: any;
  walletTransaction: any;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class WalletScreenController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getWalletBalanceCallID:any;
  getWalletTransactionCallID:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start 
    this.getWalletBalance=this.getWalletBalance.bind(this);
    this.getWalletTransaction = this.getWalletTransaction.bind(this);
    this.apiCall = this.apiCall.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      walletBalance: '',
      walletTransaction: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
      this.getWalletBalance();
      this.getWalletTransaction();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     
      if (this.getWalletBalanceCallID === apiRequestCallId) {
        this.setState({ walletBalance: responseJson.wallet_amount });
      }
      if(this.getWalletTransactionCallID === apiRequestCallId) {
        this.setState({ walletTransaction: responseJson.wallet_transactions });
      }
    }   
  }

  // Customizable Area Start  

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;

    const header = new Headers();
    header.append("token", token);
    header.append("Content-Type", contentType);
    
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getWalletBalance = async() => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    this.getWalletBalanceCallID = await this.apiCall({
      contentType: 'apication/json',
      method: 'GET',
      token: user.token,
      endPoint: `get_wallet`,
    });
  }

  getWalletTransaction = async() => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    this.getWalletTransactionCallID = await this.apiCall({
      contentType: 'apication/json',
      method: 'GET',
      token: user.token,
      endPoint: `get_wallet_transaction`,
    });
  }
  // Customizable Area End

}
