export const logoImg = require("../assets/logo.png");
export const fbIcon = require("../assets/fb_icon.png");
export const instagramIcon = require("../assets/instagram_icon.png");
export const twitterIcon = require("../assets/twitter_icon.png");
export const linkdinIcon = require("../assets/linkdin_icon.png");
export const emailIcon = require("../assets/email_icon.png");
export const phoneIcon = require("../assets/phone-icon.png");
export const locationIcon = require("../assets/location_icon.png");
export const loginImage = require("../assets/login_image.png");
export const mobileImage = require("../assets/mobile-Icon-Login.png");
export const googleImage = require("../assets/GoogleLogo.png");
