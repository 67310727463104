export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editIcon = require("../assets/editIcon.png");
export const logoImg = require("../../social-media-account-login/assets/logo.png");
export const fbIcon = require("../../social-media-account-login/assets/fb_icon.png");
export const instagramIcon = require("../../social-media-account-login/assets/instagram_icon.png");
export const twitterIcon = require("../../social-media-account-login/assets/twitter_icon.png");
export const linkdinIcon = require("../../social-media-account-login/assets/linkdin_icon.png");
export const emailIcon = require("../../social-media-account-login/assets/email_icon.png");
export const phoneIcon = require("../../social-media-account-login/assets/phone-icon.png");
export const locationIcon = require("../../social-media-account-login/assets/location_icon.png");