import React from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "./Footer.web";
import HeaderWeb from "./Header.web";
import { Box, Grid, Container, Button, Link } from "@material-ui/core";
import {
  bannerBgImage,
  bannerRightImage,
  verizonIcon,
  campaignRightImg,
  investChartImg,
  investFundRaise,
  investImg,
  linkdinIcon,
  registrationBg,
} from "../src/assets";
import LandingPageController from "./LandingPageController.web";

// Customizable Area End
class LandingWebPage extends LandingPageController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getLogos();
    await this.getLiveCampaigns(true);
    await this.getTeam();
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.mainContainer} id="Main" >
        <Box className={classes.bannerContainer} id="BannerImage" >
          <HeaderWeb />
          <Container fixed className={classes.bannerSubContainer}>
            <Grid container spacing={2} className={classes.bannerRow}>
              <Grid item xs={12} sm={8}>
                <Box>
                  <h3 className={classes.firstTitle}>
                    Hello.! We're Yuva Invest
                  </h3>
                  <h1 className={classes.mainHeading}>
                    We make startup investing so easy,
                    <br />
                    <span>anyone can do it.</span>
                  </h1>
                  <p className={classes.FuturaBTBook + " banner-dis " + classes.lead} style={{ fontSize: 18, fontWeight: 400, color: "#414141" }}>
                    It all started with our rock hard determination to make Startup Investing accessible to all. The story of Zomato-Info Edge fascinates us. It ignited a deep desire in our heart and mind to make something robust and appealing, which can give opportunity of Startup Investing to millions of Indians with ease and automation. This resulted into the development and creation of YuvaInvest, the Startup Investment Platform of India.<br></br>
                    By investing in Startups via this platform, a subscriber not only spires to create wealth in the long run, they also conserve the environment for their wealthy children. We follow our <Link onClick={() => this.props.history.push("/charter")} id="durationcancelBtn">Charter</Link>. Our approach of environmental, social and governance (ESG) investing makes us different from our competitors in India.

                  </p>
                  <Button id="routeCampaignWeb" className={classes.customButton} onClick={() => { user.token ? (this.props.history.push("/CampaignWebPage")) : (this.props.history.push("/SocialMediaAccountRegistrationScreen")) }} >get started</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.bannerImgBox} >
                  <img
                    src={bannerRightImage}
                    className={classes.bannerImg}
                    alt="Banner Img"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.bannerBrandBox} >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <h5 className={classes.FuturaBTMedium + " " + classes.h5} id="backedby">
                    Backed By the Best
                    <br />
                    in the Business
                  </h5>
                </Grid>
                <Grid item md={8}>
                  <p className={classes.FuturaBTBook + " backedBySubhead " + classes.leadText} style={{ color: "rgba(57, 57, 57, 1)" }}>
                    Be the part of the growth story of startups who are working on their dreams to transform India into
                    <br />
                    the fastest growing startup ecosystem.
                  </p>
                </Grid>
              </Grid>
              <Box className={classes.brandHr} />
              <Box className={classes.brandImagesBlocks}>
                {Array.isArray(this.state.logos) && this.state.logos.slice(0, 6).map((item: any, index: number) => (
                  <img src={item?.attributes?.logo_image} style={{ width: "100%", height: "150px", objectFit: "cover", borderRadius: "10px" }} key={index} />
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
        {this.state.liveCampaigns?.length !== 0 && <Box className={classes.campaignSection} id="campaignSection">
          <Container fixed className={classes.campaignContainer}>
            <h2 className={classes.FuturaBTBook + " " + classes.h2} style={{ fontWeight: 400 }}>
              Live <span className={classes.FuturaBold} style={{ fontWeight: 400 }}>Campaigns</span>
            </h2>
            <Grid container spacing={2} className={classes.campignCardRow}>
              {this.state.liveCampaigns?.length > 3 && <img
                src={campaignRightImg}
                className={classes.campaignRightImg}
                alt="Logo Img"
              />
              }
              {this.state.liveCampaigns?.slice(0, 6).map((item: any, index: any) =>
              (
                <Grid item xs={12} sm={6} md={4} key={index} style={{ cursor: "pointer" }} id="campaginCard">
                  <Box className={classes.campaignCard}>
                    <div onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)} id="cardDiv">

                      <Box className={classes.campaignHeaderRow}>
                        <img
                          src={
                            item?.attributes?.campaign_logo
                          }
                          className={classes.campIcon}
                          alt={verizonIcon}
                        />
                        <h6 className={classes.h6}>
                          <span className={classes.FuturaMedium} style={{ fontWeight: 400 }}>
                            {item?.attributes?.campaign_name}
                          </span>
                        </h6>
                      </Box>
                      <p className={classes.FuturaBTBook + " card-dis " + classes.leadText} style={{ fontWeight: 400 }}>
                        {item?.attributes?.campaign_description && (item.attributes.campaign_description).substring(0, 180)}
                      </p>
                    </div>
                    <Box className={classes.campBtnrow}>
                      {item?.attributes?.service_type_tag_1 && <Button key={index + 1} className={classes.customButton}>{item?.attributes?.service_type_tag_1}</Button>}
                      {item?.attributes?.service_type_tag_2 && <Button key={index + 2} className={classes.customButton}>{item?.attributes?.service_type_tag_2}</Button>}
                      {item?.attributes?.service_type_tag_3 && <Button key={index + 3} className={classes.customButton}>{item?.attributes?.service_type_tag_3}</Button>}
                    </Box>
                    <div id="routeCampaigDetails" onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)}>
                      <Box className={classes.campaignFooterRow}>
                        <Box className={classes.campaignFooterBlocks}>
                          <span
                            className={classes.FuturaBTBook + " " + classes.span}
                          >
                            Percent Raised
                          </span>
                          <h1
                            className={
                              classes.FuturaBTBook + " " +
                              classes.campTitle
                            }
                          >
                            <span className={classes.FuturaBTMedium} style={{ fontWeight: 600 }}>{` ${item?.attributes?.percent_raised}`}</span>
                          </h1>
                        </Box>
                        <Box className={classes.campaignFooterBlocks}>
                          <span
                            className={classes.FuturaBTBook + " " + classes.span}
                          >
                            Closes In
                          </span>
                          <h1
                            className={
                              classes.fonts + " " + classes.campTitle
                            }
                          >
                            <span className={classes.FuturaBTMedium} style={{ fontWeight: 600 }}>{`${item?.attributes?.closes_in} Days`}</span>
                          </h1>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Grid>
              )
              )}
            </Grid>
          </Container>
        </Box>}
        <Box className={classes.yuvaInvestSection} id="yuvaInvestSection">
          <Container fixed className={classes.yuvaInvestContainer}>
            <h2 className={classes.FuturaBTBook + " " + classes.h2} style={{ fontFamily: "FuturaBT-Book", fontWeight: 400 }}>
              Why Choose <span className={classes.FuturaBold} style={{ fontWeight: 400 }}>Yuva Invest ?</span>
            </h2>
            <p className={classes.lead + " subHeading " + classes.FuturaBTBook}>
              Still not sure if you should trust, and associate with us then look at some of the reasons here. 
              <br />
              They are going to reinforce your conviction to subscribe a campaign or raise fund here.
            </p>
            <Grid container spacing={2} className={classes.campignCardRow}>
              <Grid item xs={12} sm={4}>
                <Box className={classes.investCard}>
                  <h3 className={classes.FuturaMedium + " " + classes.h3} style={{ letterSpacing: 1 }}>
                    The First ESG Friendly Platform
                  </h3>
                  <p className={classes.FuturaBTBook + " " + classes.leadText}>
                    We plant tress for every successful raise. We also plan to donate part of our profit to Social Cause as laid down in our Charter. Investors at our platform create wealth with a prosperous future.
                  </p>
                  <Box className={classes.investFooterBlock}>
                    <img
                      src={investFundRaise}
                      className={classes.investFundRaise}
                      alt="investFundRaise"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.investCard + " " + classes.investCard2}>
                  <h3 className={classes.FuturaMedium + " " + classes.h3} style={{ letterSpacing: 1 }}>
                    The First Platform having Advisors from Academia
                  </h3>
                  <p className={classes.FuturaBTBook + " " + classes.leadText}>
                    We have group of Eminent Advisors from Central Universities of India. They give their insights on most recent developments in the Ecosystem.
                  </p>

                  <Box className={classes.investFooterBlock}>
                    <img
                      src={investImg}
                      className={classes.investFundRaise}
                      alt="investImg"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.investCard} >
                  <h3 className={classes.FuturaMedium + " " + classes.h3} style={{ letterSpacing: 1 }}>
                    We collaborate with most promising Startups
                  </h3>
                  <p className={classes.FuturaBTBook + " " + classes.leadText}>
                    We don’t chase stardom (like Paytm). We collaborate with Startups who have potential to grow fast, and most importantly become profitable faster.
                  </p>
                  <Box className={classes.investFooterBlock}>
                    <img
                      src={investChartImg}
                      className={
                        classes.investFundRaise + " " + classes.chartImg
                      }
                      alt="investChartImg"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.clientsSection}>
          <Container fixed className={classes.campaignContainer}>
            <h2 className={classes.FuturaBTBook + " " + classes.h2} style={{ fontWeight: 400 }}>
              Backed <span className={classes.FuturaBold} style={{ fontWeight: 400 }}>By</span>
            </h2>
            <p className={classes.FuturaBTBook + " " + classes.lead} style={{ fontWeight: 400 }}>
              We are backed by Top Industry Leaders.
            </p>
            <Grid container spacing={2} className={classes.clientsCardRow}>
              {Array.isArray(this.state.team) && this.state.team.map((item: any, index: number) =>
              (
                <Grid item xs={12} sm={4} key={item} id="teamSection">
                  <Box className={classes.clientsCard}>
                    <Box className={classes.clientImgBg}>
                      <img
                        src={item?.attributes?.team_image}
                        className={classes.clientImg}
                        alt="Client Img"
                      />
                    </Box>
                    <Box className={classes.clientContentBlock}>
                      <Box className={classes.clientContentRow}>
                        <span className={classes.backedbyName}>
                          {item?.attributes?.name}
                        </span>
                        {item?.attributes?.linked_in_link?.length > 0 && <a href={`//${item?.attributes?.linked_in_link}`} target="_blank">
                          <img
                            src={linkdinIcon}
                            className={classes.linkdinIcon}
                            alt="Linkdin Img"
                          />
                        </a>}
                      </Box>
                      <p className={classes.FuturaBTBook + " " + classes.lead}>
                        {item?.attributes?.description && (item.attributes.description).substring(0, 100)}
                      </p>
                    </Box>
                  </Box>
                </Grid>
              )
              )}

            </Grid>
          </Container>
        </Box>
        {!user.token &&
          <Box className={classes.registerSection}>
            <h2 className={classes.FuturaBold + " " + classes.h2}>
              Join Yuva Invest Today
            </h2>
            <p className={classes.FuturaBTBook + " registerSubhead " + classes.lead}>
              Want to be part of vetted startups? Here are some of the top
              <br />
              campaigns live right now.
            </p>
            <Button className={classes.customButton} onClick={() => this.props.history.push("/SocialMediaAccountRegistrationScreen")}>Register Now</Button>
          </Box>
        }

        <FooterWeb />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HomeScreenStyle = {
  mainContainer: {
    height: "100%",
    overflowX: "hidden",
    backgroundColor: "#fff",
    "& .MuiContainer-fixed": {
      "@media(max-width: 1024px)": {
        maxWidth: "1000px",
      },
    },
  },
  bannerContainer: {
    background: "url(" + bannerBgImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    paddingBottom: 50,
    position: "relative",

    "&:before": {
      content: "''",
      left: "0",
      top: "0",
      position: "absolute",
      zIndex: "-1",
      width: "100%",
      height: "100%",
      display: "block",
      backgroundColor: "#e9f5f5",
      paddingBottom: "30px",
    },
  },
  bannerSubContainer: {
    padding: "50px 0 0 0",
    "@media(max-width: 1024px)": {
      padding: "50px 15px 0 15px",
    },
  },
  bannerRow: {
    "@media(max-width: 767px)": {
      flexDirection: "column-reverse",
    },
    "& .banner-dis": {
      textAlign: "justify",
      "& a": {
        cursor: "pointer",
      }
    }
  },
  mainHeader: {
    padding: "15px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  hr: {
    borderTop: "2px solid #E0E0E0",
  },
  brandHr: {
    borderTop: "1px solid #E0E0E0",
    margin: "30px 0",
  },
  pt: {
    paddingTop: 50,
  },
  sectionRow: {
  },
  investBtnRow: {
    paddingTop: 30,
  },
  rightNav: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "& .MuiTypography-root": {
      padding: "0 15px",
      color: "#353535",
      opacity: "0.79",
      fontFamily: "Futura-Bold, sans-serif",
      fontWeight: "400",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  fontsBold: {
    fontFamily: "Futura-Bold, sans-serif",
    fontWeight: "400",
  },
  fonts: {
    fontFamily: "FuturaBT-Book",
  },
  FuturaBTBook: {
    fontFamily: "FuturaBT-Book !important",
  },
  FuturaBTMedium: {
    fontFamily: "FuturaBT-Medium !important",
  },
  FuturaMedium: {
    fontFamily: "FuturaBT-Medium !important",
  },
  FuturaBold: {
    fontFamily: "Futura-Bold !important",
  },
  boldTitle: {
    fontWeight: "600",
  },
  mainHeading: {
    color: "#474747",
    fontSize: 40,
    lineHeight: "60px",
    letterSpacing: 1,
    fontFamily: "Futura-Bold !important",
    fontWeight: 400,
    "@media(max-width: 1280px) and (min-width: 1025px)": {
      "& br": {
        display: "none",
      }
    },
    "@media(max-width: 1024px)": {
      fontSize: 25,
      lineHeight: "30px",
    },
    "@media(max-width: 480px)": {
      "& br": {
        display: "none",
      },
      "& span": {
        marginLeft: 10,
      }
    },
  },
  h1: {
    color: "#474747",
    fontSize: 40,
    lineHeight: "60px",
    fontWeight: "600",
    letterSpacing: 1,
    "@media(max-width: 1024px)": {
      fontSize: 25,
      lineHeight: "30px",
    },
  },
  h2: {
    color: "#474747",
    fontSize: 30,
    margin: 0,
    "@media(max-width: 1024px)": {
      fontSize: 18,
    },
  },
  firstTitle: {
    color: "#414141",
    fontSize: 20,
    fontFamily: "FuturaBT-Medium !important",
    fontWeight: 400,
  },
  h3: {
    color: "#414141",
    fontSize: 20,
  },
  backedbyName: {
    color: "#414141",
    fontSize: 20,
    fontFamily: "FuturaBT-Medium !important",
    fontWeight: 400,
  },
  hassleFree: {
    color: "#414141",
    fontSize: 20,
    fontFamily: "FuturaBT-Book !impoertant",
    fontWeight: 600,
  },
  h5: {
    color: "#393939",
    fontSize: 24,
    lineHeight: "30px",
    margin: 0,
  },
  leadText: {
    color: "#393939",
    fontSize: 16,
    lineHeight: "26px",
    margin: 0,
    wordBreak: "break-word",
    "&.card-dis": {
      textAlign: "justify",
      minHeight: "115px",
      "@media (max-width:1279px) and (min-width: 959px)": {
        minHeight: "168px"
      },
      "@media (max-width:600px)": {
        minHeight: "auto",
      }
    }
  },
  lead: {
    color: "#6D6D6D",
    fontSize: 18,
    lineHeight: "28px",
    "@media (max-width: 480px)": {
      "&.registerSubhead": {
        padding: "0 10px",
        "& br": {
          display: "none",
        }
      }
    },
  },
  customButton: {
    color: "#00BC4A",
    border: "1px solid #00BC4A",
    fontFamily: 'FuturaBT-Medium !important',
    padding: "5px 10px",
    textTransform: "capitalize",
    backgroundColor: "#edfff4",
    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: 600,
    },
  },
  bannerImgBox: {
    position: "relative",
    marginTop: 135,
    "@media(max-width: 1280px)": {
      height: "250px",
      width: "250px",
      position: "relative",
      marginTop: 135,
    },
    "@media(max-width: 1024px)": {
      height: "250px",
      width: "250px",
      position: "relative",
      marginTop: 135,
    },
    "@media (max-width: 767px)": {
      marginTop: 0,
    }
  },
  bannerImg: {
    height: "400px",
    width: "400px",
    objectFit: "contain",
    position: "absolute",
    right: "-18px",
    "@media (max-width:1279px)": {
      right: "-80px",
    },
    "@media(max-width: 1024px)": {
      height: "250px",
      width: "250px",
      position: "unset",

    },
  },
  bannerBrandBox:
  {
    backgroundColor: "#FFF",
    borderRadius: 12,
    padding: "30px 50px",
    boxShadow: "0px 26px 40px rgb(0 0 0 / 7%)",
    paddingBottom: 30,
    marginTop: 50,
    "@media(max-width: 1024px)": {
      padding: "30px",
    },
    "@media(max-width: 1279px)": {
      "& .backedBySubhead":{
        textAlign: "justify",
        "& br": {
          display: "none",
        }
      }
    },
    "@media (max-width: 767px)": {},
  },
  brandImagesBlocks: {
    display: "flex",
    justifyContent: "space-between",
    "& img": {
      maxWidth: 150,
      objectFit: "contain",
      margin: "10px",
    },
    "@media(max-width: 768px)": {
      overflowX: "scroll",
      "& img": {
        margin: "10px",
      },
    },
  },
  campaignContainer: {
    padding: "100px 0",
    "@media(max-width: 1024px)": {
      padding: "100px 15px",
    },
  },
  yuvaInvestSection: {
    backgroundColor: "#f3fafa",
    "@media(max-width: 767px)": {
      "& .subHeading ": {
        textAlign: "justify",
        "& br": {
          display: "none",
        }
      }
    }
  },
  yuvaInvestContainer: {
    padding: "100px 0",
    "@media(max-width: 1024px)": {
      padding: "100px 15px",
    },
  },
  campignCardRow: {
    paddingTop: "50px",
    position: "relative",
  },
  campaignRightImg: {
    position: "absolute",
    right: "11%",
    top: "-72px",
    "@media(max-width: 767px)": {
      width: "100px",
      top: "-36px",
    },
  },
  campaignCard: {
    padding: 30,
    borderRadius: 12,
    border: "1px solid #dedede",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    height: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box",
    "@media(max-width: 1024px)": {
      padding: 10,
    },
    "@media(max-width: 599px)": {
      minHeight: "auto",
      padding: "20px",
    }
  },
  campaignHeaderRow: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 15,
  },
  h6: {
    color: "#474747",
    fontSize: 18,
    margin: 0,
    paddingLeft: "10px",
  },
  span: {
    color: "#787878",
    fontSize: 15,
    margin: 0,
  },
  campIcon: {
    height: 54,
    width: 54,
    objectFit: "cover",
    borderRadius: "50%",
  },
  campBtnrow: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "15px 0",
    paddingBottom: "8px",
    marginBottom: "auto",
    "& button": {
      margin: "0 8px 8px 0",
      pointerEvents: "none",
      padding: "8px",
      boxSizing: "border-box",
      wordBreak: "break-all",
      "& .MuiButton-label": {
        lineHeight: "15px",
        letterSpacing: ".5px",
      }
    },
  },
  campaignFooterRow: {
    display: "flex",
  },
  campaignFooterBlocks: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "&:nth-child(2)": {
      paddingLeft: 20,
    },
  },
  campTitle: {
    color: "#474747",
    fontSize: 28,
    marginBottom: 0,
    "@media(max-width: 1024px)": {
      fontSize: 18,
    },
  },
  investCard: {
    padding: 30,
    borderRadius: 12,
    border: "0",
    backgroundColor: "#00bc4a",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: 480,
    "@media(max-width: 1024px)": {
      padding: 10,
      minHeight: 450,
    },
    "& h3": {
      color: "#fff",
      fontWeight: "600",
    },
    "& p": {
      color: "#fff",
      paddingBottom: "20px",
      textAlign: "justify",
    },
  },
  investCard2: {
    backgroundColor: "#fff",
    "& h3": {
      color: "#474747",
    },
    "& p": {
      color: "#474747",
      paddingBottom: "20px",
    },
    "@media(max-width: 1024px)": {
      padding: 10,
    },
  },
  investFooterBlock: {
    display: "flex",
    justifyContent: "center",
  },
  investFundRaise: {
    objectFit: "contain",
    width: "250px",
    height: "250px",
    "@media(max-width: 1024px)": {
      width: "200px",
      height: "200px",
    },
  },
  chartImg: {
    position: "absolute",
    bottom: 0,
  },
  clientsCard: {
    display: "flex",
    flexDirection: "column",
    "& h3": {
      fontSize: 18,
      margin: 0,
      fontWeight: 600,
    },
    "& p": {
      fontSize: 16,
      marginTop: 0,
    },
  },
  clientsCardRow: {
    paddingTop: 30,
  },
  clientContentBlock: {
    paddingTop: "20px",
  },
  clientContentRow: {
    display: "flex",
    alignItems: "center",
    minHeight: "38px",
  },
  clientImgBg: {
    padding: 20,
    borderRadius: 12,
    backgroundColor: "#eff8f8",
    display: "flex",
    flexDirection: "column",
  },
  clientImg: {
    width: "100%",
    height: "350px",
    objectFit: "cover",
    borderRadius: "20px",
  },
  linkdinIcon: {
    marginLeft: 10,
  },
  registerSection: {
    background: "url(" + registrationBg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    padding: "80px 0",
    "& h2": {
      color: "#fff",
    },
    "& p": {
      color: "#fff",
      textAlign: "center",
      margin: "30px 0",
    },
    "& button": {
      color: "#fff",
      border: "1px solid #00BC4A",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#00BC4A",
        color: "#fff",
      },
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 100,
      },
    },
  },

};//@ts-ignore
export default withStyles(HomeScreenStyle)(withRouter(LandingWebPage));
// Customizable Area End