import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  drawerStatus: boolean;
  userName: string;
  profileUrl: string;
  userEmail: string;
  userToken: any;
  toolTip:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    this.state = {
      drawerStatus: false,
      userName: '',
      profileUrl: '',
      userEmail: '',
      userToken: '',
      toolTip:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
    // Customizable Area Start

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson , "responseJsonresponseJsonresponseJson");

      if (this.getProfileDetailsCallId === apiRequestCallId) {
          if (responseJson.errors && responseJson.errors.length > 0) {
              if (responseJson.errors[0].token.toLowerCase().includes("token")) {
                  localStorage.clear()
                  window.location.href = "/";
                  return;
              }
          } else {
        this.setState({
          userName: responseJson.data.attributes.full_name,
          profileUrl: responseJson.data.attributes.profile_image,
          userEmail: responseJson.data.attributes.email,
        })
      }
      }
    }
  }
  handleDrawerOpen = () => {
    this.setState({ drawerStatus: true })

  };
  handleDrawerClose = () => {
    this.setState({ drawerStatus: false })
  };
  handleLogOutHeader() {
    localStorage.clear();
    window.location.href = "/";
  }
  handleCloseTooltip() {
    this.setState({ toolTip: false });
  }
  TooltipOpen() {
    this.setState({ toolTip: !this.state.toolTip });
  }
  handleNAvigation=(path:string)=>{
    if(this.props.history.location.pathname!== path){
      this.props.history.push(path);
    }else{
      this.handleCloseTooltip()
    }
  }

  getProfileDetails =async () => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    const header={
      "Content-Type": "application/json",
      "token": user.token
    }
   
    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailsCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/get_account`
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
}
// Customizable Area End

