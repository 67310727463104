import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  location:any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  userValidationData?: any;
  validName?: boolean;
  validEmail?: boolean;
  validMobileNumber?: boolean;
  errortext?: boolean;
  referalCode?: any;
  referalError?: any;
  // Customizable Area End
}

interface SS {}

export default class ConfirmSignupDetailsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  confirmUserDetailsCallId: any;
  answerCount: number = 0;
  // Customizable Area Start

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      errortext: false,
      loading: false,
      userValidationData: {
        isSignupUsingMobileNumber: false,
        isSignupUsingGoogleAccount: false,
        mobileNumber: "",
        countryCode: "",
        email: "",
        name: "",
        referallCode: "",
      },
      referalCode: "",
      referalError: "",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getStoredData();
  }
  
  getStoredData = () => {
    const user = JSON.parse(localStorage.getItem("token") ?? "{}");
    if (user.token) {
      this.props.history.push("/");
    } else {
      const data = this.props.location?.state?.userData || {
        isSignupUsingMobileNumber: false,
        isSignupUsingGoogleAccount: false,
        mobileNumber: "",
        countryCode: "",
        email: "",
        name: "",
        referallCode: "",
      }
      if(data) {
        this.setState({ userValidationData: data }, () => this.getReferCode());
      }
    }
  }

  getReferCode = () => {
    const refer_code = localStorage.getItem("refer_code") ?? "";
    this.setState((prevState) => ({
      userValidationData: {
        ...prevState.userValidationData,
        referallCode: refer_code
      },
      referalCode: refer_code,
    }));
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  async receive(from: string, message: Message) {
    this.stopLoading();

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (this.confirmUserDetailsCallId === apiRequestCallId) {
        this.confirmDetilsApiProcess(responseJson, message);
      }
    }
  }

  confirmDetilsApiProcess = (responseJson: any, message: any) => {
    if (
      responseJson?.data &&
      !responseJson.error &&
      !responseJson.errors
    ) {
      localStorage.setItem('refer_code', this.state.referalCode);
      this.props.history.push({
        pathname: "QuestionAfterSignUp",
        state: this.props.location.state,
      });
    }
    else if (responseJson?.errors) {
      this.showAlert(configJSON.errorTitle, responseJson.errors);
      this.parseApiErrorResponse([...responseJson.errors]);
    }
    else {
      if (responseJson.message.includes('referal')) {
        this.setState({ referalError: responseJson.message });
      } else {
        this.setState({ errortext: responseJson.message })
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  confirmUserDetails = async (props: Props) => {
    const { userValidationData } = this.state;
    const {mobileNumber,email,name} = userValidationData;

    if (!name || name.length === 0) {
      this.setState({ validName: true });
      return;
    }

    if (!configJSON.mobileValidationRegex.test(mobileNumber)) {
      this.setState({ validMobileNumber: true });
      return;
    }

    if (!configJSON.emailValidationRegex.test(email)) {
      this.setState({ validEmail: true });
      return;
    }

    localStorage.setItem("NameValidate", name);
    localStorage.setItem("emailValidate", email);
    localStorage.setItem("phoneValidate", mobileNumber);

    this.startLoading();

    this.confirmUserInfoApiCall(props);

  }

  confirmUserInfoApiCall = (props: any) => {
    const { userValidationData } = this.state;
    const {
      mobileNumber,
      email,
      name,
      isSignupUsingMobileNumber,
      countryCode,
    } = userValidationData;

    if (this.state.referalCode) {
      const header = {
        "Content-Type": configJSON.urlHeaderTypeJSON,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.confirmUserDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.confirmUserDetialsURL}`
      );
      const httpBody = {
        email: email,
        name: name,
        full_phone_number: isSignupUsingMobileNumber
          ? `${countryCode}-${mobileNumber}`
          : mobileNumber,
        refer_by_code: this.state.referalCode,
        is_phone_account: isSignupUsingMobileNumber ? isSignupUsingMobileNumber : "false"
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else {
      const header = {
        "Content-Type": configJSON.urlHeaderTypeJSON,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.confirmUserDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.confirmUserDetialsURL}`
      );

      const httpBody = {
        email: email,
        name: name,
        full_phone_number: isSignupUsingMobileNumber
          ? `${countryCode}-${mobileNumber}`
          : mobileNumber,
        is_phone_account: isSignupUsingMobileNumber ? isSignupUsingMobileNumber : "false"
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  doConfirmDetails = () => {
    this.confirmUserDetails(this.props);
  };

  btnRegisterNowProps = {
    onRegisterClick: () => {
      this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
    },
  };

  btnLoginOnHeader = {
    onLoginPress: () => {
      this.props.navigation.navigate("SocialMediaAccountLoginScreen");
    },
  };

  handleUserDataChange = (key: string, value: string) => {
    const newState:any = { ...this.state.userValidationData };
    newState[key] = value;
    this.setState({ userValidationData: newState });

    if (key === "referallCode") {
      this.setState({ referalError: "" }, () => {
        this.setState({ referalCode: value });
      });
    }
  };
  // Customizable Area End
}
