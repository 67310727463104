import { RouteComponentProps  } from 'react-router';

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface CompanieDataWithInvestType {
  id: string ,
  type: string,
  attributes: {
    company_id: 1
    date_of_invest: string,
    company_name: string,
    invest_amount: number,
    doc: string | null
  };
}

interface ApiCall {
  contentType: string,
    method: string,
    endPoint: string,
    body?: {},
}

export interface Props{
  navigation: any;
  id: string;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  match: RouteComponentProps<{ id: string }>['match'];
   // Customizable Area Start
  // Customizable Area End
}

interface S {
  investedCompanies: Array<CompanieDataWithInvestType> ;
  loading: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: string | number  | undefined;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardGuestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  portfolioApiId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      investedCompanies: [],
      loading: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.portfolioApiId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message);
    }

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start

  apiCall = async (data: ApiCall) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token") || null,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return { apiRequestCallId, responseJson, errorReponse };
  };

  handleApiSuccessResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } =
      this.getApiCommonResponseDetail(message);
    const error = responseJson?.error;
    if (responseJson && !error && !errorReponse) {
      if (apiRequestCallId == this.portfolioApiId) {
          this.onPortfolioSuccess(responseJson.data);
      }
    }
  };

  handleApiErrorResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } =
      this.getApiCommonResponseDetail(message);
    if (responseJson?.error || errorReponse?.errors) {
      if (apiRequestCallId == this.portfolioApiId) {
          this.onPortfolioFailure();
      }
    }
  };


  async getPortfolios(uniqId: string) {
    this.setState({ loading: true });
    this.portfolioApiId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboardguests/portfolio?uniq_id=${uniqId}`,
    })
  }


  onPortfolioSuccess(data: Array<CompanieDataWithInvestType> | [] ) {
    this.setState({ investedCompanies: data, loading : false });
  }

  onPortfolioFailure() {
    this.setState({ loading: false, investedCompanies: [] });
    this.showAlert(`Error`, "Something went wrong");
  }

  // Customizable Area End
}