import React from "react";

import {
  Container,
  Box,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

import EkycVerificationController, {
  Props,
  configJSON,
} from "./EkycVerificationController.web";
import { withRouter } from "react-router-dom";
import "../assets/css/EkycVerification.web.css";
import { backIcon } from "./assets";
import Loader from "../../../components/src/Loader";
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import { editIcon } from "../../mobile-account-login/src/assets";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";

class EkycVerificationStep3 extends EkycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End

    const { loading } = this.state;

    return (
      <div>
        <Loader loading={loading} />
        {/* Customizable Area Start */}
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

        <Container className="ekyc-container" id="Main">
          <div className="page-title">{configJSON.labelTitleText}</div>

          <div className="stpper-wrapper">
            <div className="step-box">
              <span className="step-num">1</span>
              <span className="step-info">Step 1</span>
            </div>
            <div className="step-box">
              <span className="step-num">2</span>
              <span className="step-info">Step 2</span>
            </div>
            <div className="step-box active">
              <span className="step-num">3</span>
              <span className="step-info">Step 3</span>
            </div>
          </div>

          {
            this.state.isShowBankDetails ? (
              <Box className="ekyc-form-box step-three">
                <div className="filled-back-info">
                  <button className="back-btn" id="backbtn" onClick={() => this.setState({ isShowBankDetails: false })}>
                    <img src={backIcon} alt="" width={"15px"} />&nbsp;Back
                  </button>
                  <div className="filled-data">
                    <div className="info-box">
                      <span>Bank Holder Name</span>
                      <small>{this.state.bankDetails.full_name}</small>
                    </div>
                    <div className="info-box">
                      <span>Bank Name</span>
                      <small>{this.state.bankDetails.ifsc_details.bank_name}</small>
                    </div>
                    <div className="info-box">
                      <span>Branch Name</span>
                      <small>{this.state.bankDetails.ifsc_details.branch}</small>
                    </div>
                    <div className="info-box">
                      <span>City Name</span>
                      <small>{this.state.bankDetails.ifsc_details.city}</small>
                    </div>
                    <div className="info-box">
                      <span>State Name</span>
                      <small>{this.state.bankDetails.ifsc_details.state}</small>
                    </div>
                  </div>
                </div>
                <>
                  <button
                    className="form-button"
                    
                    onClick={() => this.props.history.push("/CampaignWebPage")}
                    id="step2btnconfirm"
                  >
                    Confirm 
                  </button>
                </>
              </Box>
            ) : (
              <Box className="ekyc-form-box step-two">
                <div className="ekyc-field">
                  <label className="input-label">Bank Account Number</label>
                  <FormControl
                    className="ekyc-form-control"
                    id="bankform"
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="accountNumber"
                      type="text"
                      name="accountnumber"
                      onChange={(e: any) => this.setBankDetails(e)}
                      placeholder="Enter Account Number"
                    />
                  </FormControl>
                  {this.state.bankAccDuplicationError && <div className="validation-msg" id="duplicatation_error">{this.state.bankAccDuplicationError}</div>}
                </div>

                <div className="ekyc-field">
                  <label className="input-label">IFSC Code</label>
                  <FormControl
                    className="ekyc-form-control"

                    variant="outlined"
                  >
                    <OutlinedInput
                      id="ifscCode"
                      type="text"
                      name="ifsc_code"
                      onChange={(e: any) => this.setBankDetails(e)}
                      placeholder="Enter IFSC Code"
                    />
                  </FormControl>
                  {!this.state.validAccount && (
                    <div className="validation-msg">Please enter valid Account number or IFSC Code</div>
                  )}
                 
                </div>

                <>
                  <button
                    className="form-button"
                    id="step2btn"
                    onClick={() =>{
                      this.state.accountNumber==="" && this.state.ifscCode==="" ? this.setState({validAccount:false}):
                        this.verifyBankDuplication(this.state.accountNumber);
                      }}
                  >
                    Confirm
                  </button>

                </>
              </Box>
            )
          }
        </Container>
        <LoginCommonFooterWeb/>
      </div>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withRouter(EkycVerificationStep3);
