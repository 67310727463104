import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { ApiCallCommon } from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("../../social-media-account/src/config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    loading: boolean;
    // Customizable Area Start
    validateUserInfo: any;
    questions: Array<string>;
    answersArray: Array<string>;
    openImproveModal: boolean;
    signedUpUserEmail: string;
    signedUpUserFullName: string;
    signedUpUserMobileNumber: string;
    referalCode: any;
    // Customizable Area End
}

interface SS { }

export default class QuestionAfterSignupController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    answerCount: number = 0;
    mobAccountCreateApiCallId: any;
    socialAccountCreateApiCallId: any
    // Customizable Area Start

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            // Customizable Area End
        ]);

        this.state = {
            // Customizable Area Start
            loading: false,
            signedUpUserEmail: "",
            signedUpUserFullName: "",
            signedUpUserMobileNumber: "",
            validateUserInfo: {
                isSignupUsingMobileNumber: false,
                isSignupUsingGoogleAccount: false,
                mobileNumber: "",
                countryCode: "",
                email: "",
                name: "",
                referallCode: "",
            },
            questions: configJSON.questions,
            answersArray: ["", "", "", ""],
            openImproveModal: false,
            referalCode: ""
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.retriveStoredData();
    }

    retriveStoredData = () => {
        const user = JSON.parse(localStorage.getItem("token") ?? "{}");
        if (user.token) {
            this.props.history.push("/");
        } else {
            const values = this.props.location?.state?.userData || {
                isSignupUsingMobileNumber: false,
                isSignupUsingGoogleAccount: false,
                mobileNumber: "",
                countryCode: "",
                email: "",
                name: "",
                referallCode: "",
            }
            if (values) {
                this.setState({ validateUserInfo: values }, () => this.getReferralCode());
            }
        }
    }

    getReferralCode = () => {
        const refer_code = localStorage.getItem("refer_code") ?? "";
        this.setState((prevState) => ({
            validateUserInfo: {
                ...prevState.validateUserInfo,
                referallCode: refer_code
            },
            referalCode: refer_code,
        }));
    }

    loadingStart = () => {
        this.setState({ loading: true });
    };

    loadingStop = () => {
        this.setState({ loading: false });
    };

    async receive(_from: string, message: Message) {
        this.loadingStop();

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (this.mobAccountCreateApiCallId === apiRequestCallId) {
                localStorage.setItem("token", JSON.stringify({ token: responseJson?.token }));
                localStorage.removeItem("refer_code");
                this.props.navigation.navigate("EkycVerifications");
            }

            if (this.socialAccountCreateApiCallId === apiRequestCallId) {
                this.createAccountApiProcess(responseJson, message);
            }
        }
    }

    createAccountApiProcess = (responseJson: any, message?: any) => {
        if (
            responseJson?.data &&
            responseJson?.meta?.token &&
            !responseJson.error &&
            !responseJson.errors
        ) {
            localStorage.setItem("token", JSON.stringify({ token: responseJson.meta.token }));
            localStorage.removeItem("refer_code");
            localStorage.removeItem("userDa");
            this.props.navigation.navigate("EkycVerifications");
        }
        else if (responseJson?.errors) {
            this.parseApiErrorResponse(responseJson);
        }
        else {
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }

    btnRegisterNowProps = {
        onRegisterClick: () => {
            this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
        },
    };

    btnLoginOnHeader = {
        onLoginPress: () => {
            this.props.navigation.navigate("SocialMediaAccountLoginScreen");
        },
    };

    handleAnswerChange = (index: number, value: string) => {
        const newArray: any = this.state.answersArray;
        newArray[index] = value;
        this.setState({ answersArray: newArray });
        if (this.answerCount < 4) {
            this.answerCount = this.answerCount + 1;
        }
    };

    submitAnswers = async () => {
        const { answersArray } = this.state;
        let count = 0;
        let answerCount = 0;

        answersArray?.map((element) => {
            if (element === "Yes" || "No") {
                answerCount = answerCount + 1;
                if (element === "Yes") {
                    count = count + 1;
                }
            }
        });

        if (count >= 3 && answerCount === 4) {

            if (localStorage.getItem("userDa")) {
                let userDe: any = localStorage.getItem("userDa");
                let oldDataParse = JSON.parse(userDe)
                this.createAccountFromSocial(oldDataParse.email, oldDataParse.id, this.props);
                this.setState({
                    signedUpUserEmail: oldDataParse.email || "",
                    signedUpUserFullName: oldDataParse.name || "",
                    signedUpUserMobileNumber: oldDataParse.mobileNumber || "",
                });
            }
            else {
                this.createAccountFromMobile();
            }
        } else {
            this.setState({ openImproveModal: true });
        }
    };

    createAccountFromSocial = async (incomingEmail: string, incomingId: string, props: Props) => {
        if (
            !incomingEmail ||
            incomingEmail.length === 0 ||
            !incomingId ||
            incomingId.length === 0
        ) {
            runEngine.debugLog("createAccountFromSocial empty info");
            return;
        }

        let vname = localStorage.getItem('NameValidate');
        let vphone = localStorage.getItem('phoneValidate');
        this.loadingStart();

        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
        };

        if(this.state.referalCode) {
            const httpBody = {
                data: {
                    type: "social_account",
                    attributes: {
                        email: incomingEmail,
                        activated: true,
                        unique_auth_id: incomingId,
                        full_name: vname,
                        full_phone_number: vphone,
                        refer_by_code: this.state.referalCode
                    },
                }
            };
            this.socialAccountCreateApiCallId = await ApiCallCommon({
                header: JSON.stringify(header),
                method: "POST",
                endPoint: configJSON.createAccountURL,
                body: JSON.stringify(httpBody)
            })
        }else {
            const httpBody = {
                data: {
                    type: "social_account",
                    attributes: {
                        email: incomingEmail,
                        activated: true,
                        unique_auth_id: incomingId,
                        full_name: vname,
                        full_phone_number: vphone
                    },
                }
            };
            this.socialAccountCreateApiCallId = await ApiCallCommon({
                method: "POST",
                header: JSON.stringify(header),
                body: JSON.stringify(httpBody),
                endPoint: configJSON.createAccountURL,
            })
        }
    }

    createAccountFromMobile = async () => {
        let vname = localStorage.getItem('NameValidate');
        let vemail = localStorage.getItem('emailValidate');

        const header = new Headers();
        header.append("Content-Type", "application/json");

        if (this.state.referalCode) {
            const httpBody = JSON.stringify({
                "full_phone_number": `91${this.state.validateUserInfo.mobileNumber}`,
                "pin": this.state.validateUserInfo.pin,
                "full_name": vname,
                "email": vemail,
                "refer_by_code": this.state.referalCode
            });

            this.mobAccountCreateApiCallId = await ApiCallCommon({
                header: header,
                method: "POST",
                endPoint: configJSON.smsaccountEndPointForSignUp,
                body: httpBody
            });
        } else {
            const httpBody1 = JSON.stringify({
                "full_phone_number": `91${this.state.validateUserInfo.mobileNumber}`,
                "pin": this.state.validateUserInfo.pin,
                "full_name": vname,
                "email": vemail,
            });

            this.mobAccountCreateApiCallId = await ApiCallCommon({
                header: header,
                method: "POST",
                endPoint: configJSON.smsaccountEndPointForSignUp,
                body: httpBody1
            });
        }
    }

    closeImproveModal = () => {
        this.setState({ openImproveModal: false });
    };
    // Customizable Area End
}
