import React from "react";
import { withRouter } from "react-router";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  Grid,
  Card,
  CardActions,
  CardMedia,
  CardContent
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import "../assets/Blogs.css"
// Customizable Area End

import BlogsPostsController from "./BlogsPostsController.web";
import Spinner from "../../landingpage/src/Spinner.web";

class BlogPostsManagement extends BlogsPostsController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getBlogs();
  }
  // Customizable Area End

  render() {
    console.log(this.props, '###');

    return (
      // Customizable Area Start
 
      <>
        <HeaderWeb />
        <Container fixed className="bloglist" id="Main">
     
  
 
          <h2>Our Blogs</h2>
          <h3>Recent blog posts</h3>
          <Grid container spacing={2}>
            {this.state.bloglist.length === 0 ? 
              (<div className="loader-wrapper"><Spinner /></div>) : 
              (<>
              {this.state?.bloglist?.map((itemblog: any, index: any) => {
                if (index < 1) {
                  return (
  
                    <Grid item xs={12} sm={12} md={6}>
                      <Card >
  
                        <CardMedia
                          component="img"
                          height="280"
                          image={itemblog.attributes.blog_image}
                          alt="green iguana"
                        />
                        <CardActions>
                          <h5>{itemblog.attributes.first_name} {itemblog.attributes.last_name}</h5>
                          <h5>{itemblog.attributes.created_at}</h5>
                        </CardActions>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {itemblog.attributes.title}
                          </Typography>
                          <Typography variant="body2" className="blog-discription">
                            {itemblog.attributes.description}
                          </Typography>
                        </CardContent>
  
                        <CardActions>
                          <Button onClick={() => { this.props.history.push(`/blogpostdetails/${itemblog?.id}`) }} size="small" color="primary">
                            Read Article
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                }
              })}
            </>)}

            <Grid item xs={12} sm={12} md={6} className="blogsidelist">
              {this.state?.bloglist?.map((itemblog: any, index: any) => {
                if (index > 0 && index < 3) {
                  return (
                    <Card className="blogsidelistcard" >
                      <CardMedia
                        component="img"
                        width="120"
                        height="200"
                        image={itemblog.attributes.blog_image}
                        alt="green iguana"
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardActions>
                          <h5>{itemblog.attributes.first_name} {itemblog.attributes.last_name}</h5>
                          <h5>{itemblog.attributes.created_at}</h5>
                        </CardActions>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {itemblog.attributes.title}
                          </Typography>
                          <Typography variant="body2" className="blog-side-list-title">
                            {itemblog.attributes.title}
                          </Typography>
                        </CardContent>

                        <CardActions>
                          <Button onClick={() => { this.props.history.push(`/blogpostdetails/${itemblog?.id}`) }} size="small" color="primary">
                            Read Article
                          </Button>
                        </CardActions>

                      </Box>

                    </Card>
                  )
                }
              })}
            </Grid>

          </Grid>
          <h3>All blog posts</h3>
          <Grid container spacing={3}>
            {this.state?.bloglist?.length === 0 ? 
              (<div className="loader-wrapper"><Spinner/></div>) : 
              (<>
                {this.state?.bloglist?.map((item: any, index: any) => {
                  if (index < 6) {
                    return (
                      <Grid item xs={12} sm={12} md={4}>
                        <Card >

                          <CardMedia
                            component="img"
                            height="220"
                            image={item.attributes.blog_image}
                            alt="green iguana"
                          />
                          <CardActions>
                            <h5>{item.attributes.first_name} {item.attributes.last_name}</h5>
                            <h5>{item.attributes.created_at}</h5>
                          </CardActions>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              {item.attributes.title}
                            </Typography>
                            <Typography variant="body2" className="blog-discription">
                              {item.attributes.description}
                            </Typography>
                          </CardContent>

                          <CardActions>
                            <Button onClick={() => { this.props.history.push(`/blogpostdetails/${item?.id}`) }} size="small" color="primary">
                              Read Article
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }
                })}
              </>)}
          </Grid>
        </Container>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
//@ts-ignore
export default (withRouter(BlogPostsManagement));
// Customizable Area End
