import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?:any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  lookingForValue: string;
  signedUpUserEmail?: string;
  signedUpUserFullName?: string;
  signedUpUserMobileNumber?: string;
  errorlogintxt?:string;
  is_referredUser?:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SocialMediaAccountWebController extends BlockComponent<Props, S, SS>
implements GoogleWebDelegate {
  // Customizable Area Start
  createAccountAPICallId: any;
  googleUser: any;
  loginWithGoogleAccount: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      loading: false,
      lookingForValue: "founder",
      signedUpUserEmail: "",
      signedUpUserFullName: "",
      signedUpUserMobileNumber: "",
      errorlogintxt: "",
      is_referredUser: false,
      // Customizable Area End
    };
  }

  // Customizable Area Start
  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
      let userdet=JSON.stringify(userInfo)
      localStorage.setItem("userDa",userdet)
      //@ts-ignore
      this.props.history.push({
        pathname: "ConfirmSignupDetails",
        state: {
          userData: {
            isSignupUsingGoogleAccount: true,
            email: userInfo.email || "",
            name: userInfo.name || "",
            signedUpUserMobileNumber: userInfo.mobileNumber || "",
            
          },
         
        },
      });
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function () {
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function (error: any) {
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
        }
      }
    );
  };

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    if (user.token) {
      //@ts-ignore
      this.props.history.push("/");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const refer_code = window.location.href.split('?')[1];
    if(refer_code) {
      localStorage.setItem("refer_code", refer_code);
      this.setState({ is_referredUser: true });
    }else {
      localStorage.removeItem("refer_code");
    }
  }

  firstElseIf(message:any){
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson &&
      responseJson.data &&
      responseJson.meta &&
      responseJson.meta.token &&
      !responseJson.error &&
      !responseJson.errors
    ) {
      // this.saveLoggedInUserData(responseJson);
      //@ts-ignore
      this.props.history.push({
        pathname: "ConfirmSignupDetails",
        state: {
          userData: {
            isSignupUsingGoogleAccount: true,
            email: this.state.signedUpUserEmail,
            name: this.state.signedUpUserFullName,
            mobileNumber: this.state.signedUpUserMobileNumber,
            countryCode: "",
            referallCode: "",
          },
          responseJson: responseJson,
        },
      });
    } else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.stopLoading();

    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
      this.openInfoPage();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId != null &&
      this.createAccountAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     this.firstElseIf(message);
    }  else {
      runEngine.debugLog("GOIT");
    }
    // Customizable Area End
  }

  // Customizable Area Start

  navigate() {
    runEngine.debugLog("this.isRegistration");
      runEngine.debugLog("Registration");
      runEngine.debugLog(true);
      this.navigateToSignup();    
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
    MessageEnum.AccoutResgistrationSuccess    
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn(true);
      this.startLoading();
    },
  };

  btnMobileNumberLogInProps = {
    onPress: () => {
      this.props.navigation.navigate("MobileAccountLoginBlock");
    },
  };

  btnMobileNumberSignUpProps = {
    onPress: () => {
      //@ts-ignore
      this.props.history.push({
        pathname: "MobileAccountRegistrationBlock",
        state: {
          isSignupUsingMobileNumber: true,
        },
      });
      localStorage.removeItem("userDa");
    },
  };

  btnRegisterNowProps = {
    onRegisterClick: () => {
      this.props.navigation.navigate("SocialMediaAccountRegistrationScreen");
    },
  };

  btnLoginOnHeader = {
    onLoginPress: () => {
      this.props.navigation.navigate("SocialMediaAccountLoginScreen");
    },
  };

  btnNavigateProps = {
    onPress: () => this.navigate(),
  };
         
  // Customizable Area End
}
