import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    history?: any;
    match?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    bloglist: any,
    blogdetails: any,
    blogdetailstetx: any,


    // Customizable Area End
}

interface SS {
    id: any;
}

export default class BlogsPostsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBlogsCallId: string = "";
    getBlogsDetailsCallId: any;


    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.getBlogsDetails = this.getBlogsDetails.bind(this);
        // Customizable Area Start
        // this.getBlogs = this.getBlogs.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            bloglist: [],
            blogdetails: '',
            blogdetailstetx: '',


        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    getBlogs = async () => {
        this.getBlogsCallId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_landingpage3/blogs`,
        });
    }

    getBlogsDetails = async () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        let url = window.location.pathname;
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.getBlogsDetailsCallId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_landingpage3/blogs/${id}`,
        });
    }

    apiCall = async (data: any) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            console.log(responseJson, "responseJsonresponseJsonresponseJson");
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
           
                this.getBlogsCallIdReponsefun(responseJson, apiRequestCallId);
                this.getBlogsDetailsCallIdResponsefun(responseJson, apiRequestCallId)
        
        }
    }

    getBlogsCallIdReponsefun=(responseJson:any,apiRequestCallId:any)=>{
        if (this.getBlogsCallId === apiRequestCallId) {
                    
            console.log(responseJson, "Blogs Api Response")
                        this.setState({
                            bloglist: responseJson.data,
                        })
        }
    }
    getBlogsDetailsCallIdResponsefun=(responseJson:any,apiRequestCallId:any)=>{
        if (this.getBlogsDetailsCallId === apiRequestCallId) {

               
            console.log(responseJson, "Blogs Api Response")
            let cont = responseJson.data.attributes.content;
            let result = cont.replaceAll("^\"|\"$", "");
            this.setState({
                blogdetails: responseJson.data.attributes,
                blogdetailstetx: result
            })
        }
    }
    createMarkup = () => {
        return { __html: this.state.blogdetailstetx };
    }
    // Customizable Area End
}
