// Customizable Area Start
import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {  
  Grid, 

} from "@material-ui/core";
import DashboardController from "./DashboardController.web";
import DashBoardHeaderWeb from "./DashBoardHeader.web";
import DashboardSidebarWeb from "./DashboardSidebar.web";
import '../assets/css/dashboard.web.css';

// Customizable Area End
class DashboardWebPage extends DashboardController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
//   async componentDidMount() {
//   }

  // Customizable Area End
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      // Customizable Area Start
     <>
      <Grid className="dashboard-page">       
        <DashBoardHeaderWeb />
        <div className="profile-container-main">
          <DashboardSidebarWeb />
        
        </div>
      </Grid>
     </>
      // Customizable Area End
    );
  }
}
//HOME SCREEN CSS
// Customizable Area Start
const DashboardScreenStyle = {
};
// Customizable Area End
//@ts-ignore
export default withStyles(DashboardScreenStyle)(withRouter(DashboardWebPage));
// Customizable Area End
