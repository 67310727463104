import React from "react";
// Customizable Area Start
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import "../assets/css/TermAndCondition.web.css";
// Customizable Area End

import TermsAndConditionsController, { Props } from "./TermsAndConditionsController";

export default class TermsAndConditionsScreen extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="term-page">
        <HeaderWeb />
        <div className="terms-wrapper">
          <div className="page-title">Terms and Conditions</div>

          <p>The agreement: The use of this platform and services on this platform provided by Vaishalica Technologies Private Limited (herein referred to as “Owner”) are subject to the following Terms and Conditions (hereinafter as <b>“Terms” or “Terms and Conditions” or “Terms of Use”</b>) all parts and subparts of which is are specifically incorporated by reference here together with the Privacy Policy and Disclaimer. Following are the Terms & Conditions governing Your use of <a href="https://www.yuvainvest.com" target="_blank">www.yuvainvest.com</a> or <a href="https://www.yuvainvest.in" target="_blank">www.yuvainvest.in</a> (hereinafter as <b>"Website" or “Platform”</b>), all pages on the Website and any services provided by or on its Website (“Services”). </p>

          <p>Information about us: The Website is owned and operated by Vaishalica Technologies Private Limited incorporated under the Companies Act, 2013 and abiding by the Rules made thereunder with CIN U72900BR2022PTC060739 having its registered office at Deep Nagar colony, Road No. - 3, Mehandiganj, Patna City, Patna, Bihar 800007.</p>

          <p>The Terms serve as a binding contract between us, the Website's owner, and You, the Website's user. Please refrain from accessing this Website if You don't agree with these Terms. Any agreement that refers to the Terms shall be deemed to be a part of such agreement, and the Terms shall be read in conjunction therewith.</p>

          <p>By accessing either directly or through a hyperlink, the Website and or purchasing from us, You engage in our “Service” and agree to be bound by the Terms & Conditions including those additional Terms & Conditions and policies referenced herein and or available by hyperlink. The Terms & Conditions apply to all users of the Website, including without limitation to browsers, third-parties and contributors of content. You acknowledge and agree that the Website may use Your personal information in the manner described in our Privacy Policy (hereinafter as “Policy”) which sets forth how information collected about You is collected, used and stored.</p>

          <ol className="terms-list">
            <li className="list-item">
              <span className="head">Definitions</span>
              <div className="sub-list alphbatic">
                <span>Owner, Us, We: The Owner, as the creator, operator of this Website, makes the Website and certain Services on it, available to users. Owner, Us, We, Our, Ours and other first-person pronouns will refer to the Owner, as Well as the Website, all employees and affiliates of the Owner.</span>

                <span>You, the User: You as the user of the Website, will be referred throughout these Terms & Conditions with second-person pronouns such as You, Your, Yours, or as User. For the purpose of this Terms & Conditions, the term “User” or “You” shall mean any natural or legal person, who is accessing the Website. The term “Your” shall be constructed accordingly.</span>

                <span>Parties: Collectively, the parties to these Terms and Conditions (the Owner and You) will be referred to as Parties.</span>

                <span>“Content” refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</span>

                <span>“Service” refers to the utility, facility, use or access of the Website.</span>
              </div>
            </li>

            <li className="list-item">
              <span className="head">Assent and Acceptance</span>
              <p>By using the Platform, You warrant that You have read and reviewed these Terms & Conditions and the Policy as setout and that You agree to be bound by it. If You do not agree to be bound by these Terms & Conditions, please leave the Platform immediately. We only agree to provide accessibility and use of this Platform and Services to You, if You assent to these Terms & Conditions. Furthermore, based on the Services obtained by You, additional terms and conditions (i.e., Terms of Service) with respect to the specific service shall apply, which shall be deemed to be the agreement between You and Us.</p>
            </li>

            <li className="list-item">
              <span className="head">About the Site</span>
              <p>Early-stage businesses can showcase their business concepts and conduct a fundraising campaign on the Yuva Invest platform's Website. We facilitate current or prospective investors, who want to make a discretionary investment in such company concepts, business ideas or in any enterprises. Additionally, We offer the early-stage businesses and potential investors any other information or services that are required or incidental to the main activity outlined therein. The use of this Platform is not regulated under any law for the time being in force, and We reserve the right to refuse the Service at our sole discretion to anyone, for any reasons whatsoever and at anytime. By accessing the Website, You assume all the risks associated while dealing with other users with whom You come in contact through this platform. You agree to use this Platform for all the lawful purposes without infringing the rights or restricting the use of this Platform by any third-party.</p>
            </li>

            <li className="list-item">
              <span className="head">Our rights in relation to the Website</span>
              <p>We have the right to discontinue or change our Services at any time and shall not be liable for the same. We shall have the right to delete your account or cancel access to the Website for any reason whatsoever, at any time at our discretion. We shall also suspend or limit your access to the Website as and when may be considered necessary. For the aforesaid, we shall make all reasonable efforts to notify you and inform you of such an action and the reasons thereof, in any. We shall disclose such confidential information as may be provided by you or such other details about yourself as may be necessary to satisfy any governmental department or authority under applicable law or to any third-party in accordance with the terms of the Privacy Policy. We may invite you to participate in the chat rooms (virtual/video/audio mode) or other features that will give you an opportunity to know about the platform, the companies and the running campaigns. The comments or other information provided by you on such chat rooms shall be deemed to have been licensed to us on a free and permanent basis.</p>
            </li>

            <li className="list-item">
              <span className="head">Content Restrictions</span>
              <p>We are not in any way responsible for the content of the Service users. You expressely understand and agree that You are solely responsible for the content and for all activity that occurs under Your account, whether done by You or any third person using Your account. You agree not to use the Platform or Services for any unlawful purpose or in a way that could damage the Platform, transmit any content that is unlawful, offensive, threatening, defamatory, obscene or otherwise objectionable, unlawful and unconstitutional under the law. We reserve the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. We further reserve the right to make formatting and edits and change the manner of any Content.</p>
            </li>

            <li className="list-item">
              <span className="head">Age restriction</span>
              <p>You must be at least 18 years old to access this site. If You are under 18 years old you are not permitted to use this site for any reason. You must be of legal age required by the law of the respective country to access this Website and to enter into any financial/transactional operations. By using this Website You are agreeing to the terms and conditions of the and warrant and represent that You are at least 18 years of age. Any misstatements and/or misrepresentations regarding the age of any user of this Website is not the responsibility of the Owner.</p>
            </li>

            <li className="list-item">
              <span className="head">User account and Registration Obligations</span>
              <p>You must always give current, accurate, and comprehensive information when setting up an account with Us. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service. You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service. You agree not to disclose Your password to any third party. You agree to keep your password secure. You are fully responsible for any loss or damage resulting from your failure to protect your password. You agree to immediately notify us of any unauthorized use of your password or any other breach of the security. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
            </li>

            <li className="list-item">
              <span className="head">Changes to these Terms and Conditions</span>
              <p>We reserve the right to periodically change and modify our Terms and Conditions, without giving you any prior notice. You acknowledge and agree that the Owner may modify and amend the Terms & Conditions at any time, and that any such modifications shall take the place of any prior versions of the Terms & Conditions posted on the Platform. The amended Terms & Conditions shall be in full force and effect as of the date of their posting on the Platform. Additionally, you undertake to regularly review these Terms & Conditions and to keep an eye on the Effective Date listed at the top to take notice of any additions or modifications. By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
            </li>

            <li className="list-item">
              <span className="head">Applicable Law</span>
              <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Patna, Bihar.</p>
            </li>

            <li className="list-item">
              <span className="head">Disclaimer</span>
              <p>We are neither a stock exchange nor we foresee to be recognized as a stock exchange by Securities and Exchange Board of India (SEBI) under the Securities Contract Regulation Act, 1956. We are not authorized by the SEBI to solicit investments. Vaishalica Technologies Pvt Ltd is not liable for any financial or any other loss incurred to the any user of the website on the basis of the content/ materials provided at this website. The convertible notes/agreements/securities traded at this platform are not traded on any regulated exchange, and are illiquid. Accessing the Platform or subscribing in any campaign is understood to be an informed decision with a comprehensive understanding of the potential downsides, as well as rewards, associated with the subscription/investment in any campaign/startup/business/company and the like, and acknowledging that investment in such campaign/startup/business/company/issuing company/issuer and the like is risky and can lead to a total loss of capital. The CSOP (Community Subscription Offer Plan) being executed after subscribing in any campaign is classified as an agreement entered into by the subscribers and the startups. The CSOP does not fall under the category of “securities” according to the Companies Act of 2013. It doesn't represent ownership in terms of equity or grant any voting privileges. Rather, it is a contract that follows the regulations of the Indian Contract Act of 1872.</p>
              <p>Any transactions that the startups/businesses/companies may offer or conclude with the User of this Platform shall be offered, issued, allotted or transferred in strict compliance with the applicable laws. The Platform acts solely as a facilitator to facilitate the subscribers/investors on the one hand and the startup/business/company on the other, assuming no liability/responsibility/accountability whatsoever, in the process of facilitating fundraising activities for the startup/company/business. None of the agreements/offer to subscribe/invest in any campaign/startup/business/company is offered to general public or people at large, but is limited to the registered Users of this Platform. However, it shall be the sole responsibility of the startup/business/company/issuing company/issuer/founder to comply with the provisions of applicable laws including Companies Act, 2013 and the Rules made thereunder. We do not assume any liability/responsibility/accountability whatsoever, in the process of facilitating fundraising activities for the startup/company/business.</p>
              <p>Nothing on this website is intended to constitute (i) an offer, or solicitation of an offer, to purchase or sell any security, other asset or service, (ii) investment advice or an offer to provide such advice, or (iii) a basis for making any investment decision.</p>
            </li>

            <li className="list-item">
              <span className="head">Limitation to liability:</span>
              <p>You agree that you cannot choose to request a return/refund after making successful transaction/investment/subscription in any campaign at the Website. Only when the campaign fails to reach 80% of the targeted amount, as specified on the campaign page, it would be considered as the unsuccessful campaign and the subscription amount shall be refunded to Your registered bank account within 14 working days from the date of closure of such campaign. You also agree and understand that the information displayed on the Website is for information purposes only and does not amount to any advice. The Platform acts solely as a facilitator facilitating the subscribers on the one hand and the startup/business/company on the other. To the extent permitted by applicable law, we disclaim our liability against any loss, damage, expenses, liabilities, claim, injury caused due to the failure of performance, omission, defect, deletion, interruption, error, delay, virus, communication, unauthorized access, theft, destruction, alteration or use of records, whether due to breach of the contract, negligence, tort or due to other cause of actions.</p>
              <p>Further, we shall not be responsible for any loss of profits, goodwill, revenue, consequential, exemplary, punitive damages or any financial or indirect loss. You further acknowledge and agree that we shall not be responsible for any defamatory, offensive or illegal conduct of third parties on our Website, including users and operators of third-party websites.</p>
              <p>Further, we shall not be responsible or be held liable for any inaccuracy, delay, omission or defect, transmission or delivery of any third-party data or any loss or damage arising from: (i) any inaccuracy, error, delay or omission of transmission of information; (ii) non-performance by any third-party; or (iii) interruption caused due to any third-party due to their negligent act or omission any other cause, not beyond the reasonable control of us. Notwithstanding anything in the Terms of Use, under no circumstances will we be liable to you for any direct, indirect, consequential and/or incidental damages in relation to the use of the Website or its Services.</p>
            </li>

            <li className="list-item">
              <span className="head">Communications</span>
              <p>When You use the Platform in any manner or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. By agreeing to these Terms and Conditions, you acknowledge that you are interested in availing the services including communications with you by email or by such other mode of communication, electronic or otherwise. You agree that the communication sent to you shall not be construed as spam or bulk under any law prevailing in any country where such communication is received.</p>
            </li>

            <li className="list-item">
              <span className="head">“AS IS” and “AS AVAILABLE” Disclaimer</span>
              <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults, defects, interruptions, errors, viruses or as to the accuracy, reliability, availability of the Content. To the maximum extent permitted under applicable law, We, on Our behalf and on behalf of Our Affiliates and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
              <p>Furthermore, We do not warrant that:
                This Platform will be constantly available, or available at all; or
                The information on this Platform is complete, true, accurate or non-misleading.
                Nothing on the Platform constitutes or is meant to constitute, advice of any kind. All the Services provided on the Platform are unregulated.</p>
            </li>

            <li className="list-item">
              <span className="head">Intellectual Property Policy</span>
              <p>We are the sole owner of all the contents displayed on the Website, including all graphics, logos, sound recordings, and software, except any third party contents that are expressly indentified as such. The law that is in effect in India shall apply to any infringement of our intellectual property rights. The license to access and use the Website does not include the right to copy or reproduce the information of our Website on any other platform or medium, without our prior written permission.</p>
              <p>Except where otherwise specified, any word, logo or device to which is attached the symbols ™ or ® shall be considered as a registered trademark that is either owned by us or which we have a license to use. The right to use the Website does not give a license to use those trademarks in any way.</p>
            </li>

            <li className="list-item">
              <span className="head">Online Payment Terms and Conditions: Refund Policy</span>
              <p>If a user is availing of the online payment services, it will be assumed that the user is in acceptance of our online payment policies and terms and conditions. In case the Website or Payment Service Provider’s webpage, that is linked to the Website, is experiencing any server related issues like ‘slow down’ or ‘failure’ or ‘session timeout’, the User shall, before initiating the second payment, check whether his/her Bank Account has been debited or not and accordingly resort to one of the following options:</p>

              <ul className="sub-list dist">
                <li>In case the Bank Account appears to be debited, ensure that he/ she does not make the payment twice and immediately thereafter contact the company via e-mail or any other mode of contact as provided by the sales to confirm payment. User can also reach our contact us page.</li>

                <li>In case the Bank Account is not debited, the User may initiate a fresh transaction to make payment.</li>

                <li>However, the User agrees that under no circumstances the Owner or its Subsidiaries, Associates or Group Companies or the Payment Gateway Service Provider shall be held responsible for such fraudulent/duplicate transactions and hence no claims should be raised to Payment Gateway Service Provider. No communication received by the Payment Service Provider(s) in this regards shall be entertained by the Payment Service Provider(s).</li>

                <li>
                  <span>The Owner assume no liability whatsoever for any monetary or other damage suffered by the User on account of:</span>
                  <ul className="sub-list alphbatic">
                    <li>The delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Payment Gateway or Services in connection there to; and/ or Any interruption or errors in the operation of the Payment Gateway. Although all reasonable care has been taken towards guarding against unauthorized use of any information transmitted by the User, which does not represent or guarantee that the use of the Services provided by/ through it will not result in theft and/or unauthorized use of data over the Internet.</li>
                    <li>The owner, the Payment Service Provider(s) and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Website.</li>
                  </ul>
                </li>

                <li>
                  <span className="head">Debit/Credit Card, Bank Account Details</span>
                  <ul className="sub-list alphbatic">
                    <li>The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.</li>
                    <li>
                      <span>The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:</span>
                      <span>The User is fully and lawfully entitled to use such credit / debit card, bank account for such transactions;</span>
                      <span>The User is responsible to ensure that the card/ bank account details provided by him/ her are accurate;</span>
                      <span>The User is authorizing debit of the nominated card/ bank account for the payment of fees selected by such User along with the applicable Fees.</span>
                      <span>The User is responsible to ensure sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.</span>
                    </li>
                  </ul>
                </li>

                <li>In the event there is any claim for/ of charge back by the User for any reason whatsoever, such User shall immediately approach the Owner or its Subsidiaries, Associates or Group Companies with his/ her claim details. Such refund (if any) shall be affected by the Owner or relevant Subsidiaries, Associates or Group Companies via payment gateway or any other means as the deems appropriate.</li>
              </ul>
            </li>

            <li className="list-item">
              <span className="head">Indemnification</span>
              <p>You agree and understand that You shall not sell Your access to the Website. You shall not transmit any unnecessary information or unwanted electronic communication viz. spam to other members of the Yuva Invest Platform. You will not misuse your right to the Website by introducing viruses, Trojans, worms, or other material likely to cause harm to the Website and shall indemnify and keep us indemnified in case any action is initiated against us due to any loss, injury, expenses or liability caused to any other user of the Website or any third-party. You shall further not gain any unauthorized access to the Website or on any other source to our Website. You agree that any use of the proprietary information displayed on the Website shall infringe our intellectual property rights for which you shall indemnify us. You agree to indemnify Us and any of our affiliates and hold Us harmless against any and all legal claims and demands (including attorney’s fees), which may arise from, or relate to Your use or misuse of the Platform and its Services, Your breach of Terms & Conditions or Your conduct or actions.</p>
            </li>

            <li className="list-item">
              <span className="head">License to use Platform</span>
              <p>We grant You limited, non-exclusive, non-transferable, non-sub licensable, revocable license to access, and make use of the services on the Platform. All rights not expressly granted to you in these Terms & Conditions, are reserved and retained by the Owner and its affiliates. We may provide You with certain information as a result of Your use of the Platform or Services. Such information may include but is not limited to documents, data, or information of other registered users. You agree not to collect information of any sort of other Users from the Platform or download or copy any information by means of unsolicited access as to communicate directly with them or for any reason whatsoever. Any unauthorized use by You shall terminate your access to the Platform. You agree that You shall not bypass any measures used by the Owner to prevent or restrict access to the Platform.</p>
            </li>

            <li className="list-item">
              <span className="head">Obligation of users</span>
              <p>As the User of the Platform or Services, you may be asked to register with Us. When you do so, You will choose a user identifier, which may be Your email address or another term, as well as a password. You will provide personal information not limited to Your name, email id, contact number etc. You shall be responsible for the accuracy of the information. This identifying information will enable You to use the Platform and its Services. You must not share such identifying information with any third-party. You agree to notify us immediately through an email, in case your identifying information has been compromised. You shall keep Us upraised in case of any changes in such information provided by You.</p>
            </li>

            <li className="list-item">
              <span className="head">Third party's Link</span>
              <p>Our Website may contain the links or provide links to other Websites or application that may collect personal information about You. We shall not be liable or responsible for any breach of this Policy by such Websites or privacy practices or the contents of those linked Websites and for any loss incurred to You after visiting those Websites. This Policy does not apply to the privacy practices of those Websites. You undertake to read and understand the Policy and also the Policy of such third-party Websites. You agree that your access to any third-party website is governed by the terms of use of that website, and has no relation to the Terms & Conditions of Our Website. You agree and understand that it is your responsibility to comply with the terms and conditions of that website as well.</p>
              <p>We do not endorse, review, control or examine third-party websites and we are not responsible for any content posted on such third-party websites. You understand that the inclusion of links on the Website is not intended as an endorsement or recommendation of any linked website or content of such website.</p>
              <p>The User Information provided by You may be Used by Us and provided to third party Websites, affiliates, consultants, employees in order to manage, collect, store, process the User Information in order to improve the quality, design, functionality, and content of the Website and to any governmental authority under applicable law.</p>
            </li>

            <li className="list-item">
              <span className="head">Amendments</span>
              <p>We reserve the right to amend the Terms from time to time. Any amendment that is made will come into effect from the moment it is displayed on the Website. The updated version of the Terms shall supersede any of the previous versions of it and shall be applicable retrospectively. We shall make reasonable efforts to notify You of such changes, however, it shall be Your responsibility to be updated with the Terms at all times. The continued use of the Website shall amount to Your acceptance to the Terms of the Website.</p>
            </li>

            <li className="list-item">
              <span className="head">Miscellaneous</span>
              <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
              <p>No partnership or agency The Terms of Use shall not be construed so as to create a partnership or joint venture between You and Us. Nothing in the Terms of Use shall be construed so as to constitute You and Us as agents of one another.</p>
              <p>Specific Performance: Each one of Us agrees that damages may not be an adequate remedy and that either of Us shall be entitled to an injunction, restraining order, right for recovery, suit for specific performance or such other equitable relief as a court of competent jurisdiction may deem necessary or appropriate to restrain the other, from committing any violation or enforce the performance of the covenants, representations and obligations contained in the Terms. These injunctive remedies are cumulative and are in addition to any other rights and remedies the Parties may have at law or in equity, including without limitation a right for damages.</p>
              <p>Severability: Each and every obligation under the Terms of Use shall be treated as a separate obligation and shall be severally enforceable as such in the event of any obligation or obligations being or becoming unenforceable in whole or in part. To the extent that any provision or provisions of the Terms are unenforceable, both of Us shall endeavor to amend such clauses as may be necessary to make the provision or provisions valid and effective. Notwithstanding the foregoing, any provision which cannot be amended as may be necessary to make it valid and effective shall be deemed to be deleted from the Terms and any such deletion shall not affect the enforceability of the remainder of the Terms not so deleted provided the fundamental terms of the Terms are not altered.</p>
              <p>Non-Exclusive Remedies: The rights and remedies herein provided are cumulative and none is exclusive of any other, or of any rights or remedies that any of Us may otherwise have at law or in equity. The rights and remedies of any of Us based upon, arising out of or otherwise in respect of any inaccuracy or breach of any representation, warranty, covenant or agreement or failure to fulfill any condition shall in no way be limited by the fact that the act, omission, occurrence or another state of facts upon which any claim of any such inaccuracy or breach is based may also be the subject matter of any other representation, warranty, covenant or agreement as to which there is no inaccuracy or breach.</p>
              <p>Partial Invalidity: If any provision of the Terms or the application thereof to any person or circumstance shall be invalid or unenforceable to any extent for any reason including by reason of any law or regulation or government policy, the remainder of the Terms and the application of such provision to persons or circumstances other than those as to which it is held invalid or unenforceable shall not be affected thereby, and each provision of the Terms shall be valid and enforceable to the fullest extent permitted by law. Any invalid or unenforceable provision of the Terms of Use shall be replaced with a provision, which is valid and enforceable and most nearly reflects the original intent of the invalid and unenforceable provision.</p>
              <p>Execution: The Terms shall be deemed duly executed and shall become effective and binding upon You and Us when you access the Website.</p>
              <p>The waiver or failure of either Party to exercise in any respect any right provided for in this Terms & Conditions shall not be deemed a waiver of any further right under this Terms & Conditions.</p>
              <p>This Terms & Conditions represents the entire understanding between the Parties with respect to the use of the Platform and subject matter herein and supersedes all prior agreements and understandings and writings of any kind, written or oral, expressed or implied, with respect to the subject matter hereof.</p>
            </li>

            <li className="list-item">
              <span className="head">Contact Us</span>
              <p>If you have any questions about these Terms and Conditions, visit our Website or contact us at <a href="mailto:hello@yuvainvest.com">hello@yuvainvest.com</a>.</p>
            </li>
          </ol>
        </div>
        <FooterWeb />
      </div>
      // Customizable Area End
    );
  }
}

