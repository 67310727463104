import React from "react";
import EkycDialogControllerWeb, {
  Props,
} from "./EkycDialogController.web";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import {
  Dialog, 
  Backdrop, 
  Button, 
} from '@material-ui/core'
import "../assets/css/EkycVerification.web.css";
import { warningIcon, closeIcon } from "./assets";
import '../assets/css/EkycDialogs.web.css';
// CUstomizable Area End

export class AskEkycModal extends EkycDialogControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    
    return (
      // Customizable Area Start
      <Dialog
        className="Dialog Ask-Ekyc"
        open={this.props.openState}
        onClose={this.props.onCloseFunc}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <div className="dialog-inner">
          <Button data-test-id="closeIcon" className="dialog-close-btn" onClick={this.props.onCloseFunc}>
            <img src={closeIcon} alt="close" width={"15px"}/>
          </Button>
          <img src={warningIcon} alt=""  className="icon-img" width={"80px"}/>
          <span className="dialog-title">KYC Pending</span>
          <span className="dialog-dis">Please complete your KYC to start investing in Startups</span>
          <div className="dialog-actions">
            <Button data-test-id="CompleteKYC" onClick={this.handleKyc}>
              Complete KYC
            </Button>
          </div>
        </div>
      </Dialog>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withRouter(AskEkycModal);
