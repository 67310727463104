// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

function Spinner() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="spinerRoot">
      <CircularProgress size="50px"  style={{'color': '#66bb6a'}}/>
    </div>
  );
}
export default Spinner;
// Customizable Area End