// Customizable Area Start
import React from "react";
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";

import {
  Box,
  Grid,
  Container,
  Button,
  Link,
  TextField,
  Backdrop,
  Modal,
} from "@material-ui/core";

import {
  pdfIcon,
  delIcon,
  closeIcon,
  successIcon,
  warningIcon,
} from "../src/assets";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: "#ffffff",
  border: '0',
  borderRadius: 24,
  boxShadow: "0px 2px 4px rgba(100,100,100,0.5)",
  p: 4,
};



import ContentManagementController from "./ContentManagementController.web";
import { placeholderCSS } from "react-select/src/components/Placeholder";
class RaiseForm extends ContentManagementController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  combineClassName(flagState:any ,valueState:any , classes:any){
    return `${!flagState ? classes.errorFrmControl : ""}  ${valueState !==""?classes.filledFrmControl:""}`
  }
  firstHalfForm(classes:any){
    return(
      <>
      <Box className={classes.frmControl}>
                  <TextField id="name" placeholder="Your Name*" className={`${!this.state.validName ? classes.errorFrmControl : ""}  ${this.state.name !==""?classes.filledFrmControl:""}`} value={this.state.name} onChange={this.onInputChange} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                  {!this.state.validName && <span className={classes.errorMsg}>Please enter name</span>}
                </Box>
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic" placeholder="Company Email*" variant="outlined" className={`${!this.state.emailErrorFlag  ? classes.errorFrmControl : ""}  ${this.state.companyEmail !=="" && this.state.emailErrorFlag ?classes.filledFrmControl:""}`} value={this.state.companyEmail} onChange={this.onChangeEmail} inputProps={{ style: { color: "black" } }}/>
                  {!this.state.emailErrorFlag && <span className={classes.errorMsg}>Please enter valid Email Address</span>} 
                </Box>
                {/* @ts-ignore */}
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic"  type="text"  className={`${!this.state.validNumber  ? classes.errorFrmControl : ""}  ${this.state.companyEmail !=="" && this.state.validNumber ?classes.filledFrmControl:""}`} placeholder="Company contact number*" variant="outlined" value={this.state.number} onChange={this.onNumberChange}  
                  inputProps={{
                    maxLength: 10
                }}
      />
                  {!this.state.validNumber && <span className={classes.errorMsg}>Please enter a valid 10 digit number</span>}
                </Box>
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic" placeholder="Founder's Linked URL*" className={this.combineClassName(this.state.validFounderLinkedinUrl,this.state.FounderLinkedinUrl , classes)} onChange={this.onFounderLinkedinUrlChange} value={this.state.FounderLinkedinUrl} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                  {!this.state.validFounderLinkedinUrl && <span className={classes.errorMsg}>Please enter Founder's Linked URL</span>}
                </Box>
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic" placeholder="Co-Founder's Linked URL" variant="outlined" onChange={this.onCoFounderLinkedinUrlChange} className={this.state.CoFounderLinkedinUrl!==""?classes.filledFrmControl:""} value={this.state.CoFounderLinkedinUrl} inputProps={{ style: { color: "black" } }}/>
                </Box>
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic" placeholder="Company's Registered Name*" className={this.combineClassName(this.state.validCompanyName ,this.state.CompanyName , classes)} onChange={this.onCompanyNameChange} value={this.state.CompanyName} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                  {!this.state.validCompanyName && <span className={classes.errorMsg}>Please enter Company's Registered Name</span>}
                
                </Box>
                <Box className={classes.frmControl}>
                  <TextField id="outlined-basic" placeholder="Company's LinkedIn Page*" className={this.combineClassName(this.state.validCompanyLinkedinPage ,this.state.CompanyLinkedinPage , classes)} onChange={this.onCompanyLinkedinPageChange} value={this.state.CompanyLinkedinPage} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                  {!this.state.validCompanyLinkedinPage && <span className={classes.errorMsg}>Please enter Company's LinkedIn Page</span>}
                </Box>
      </>
    )
  }
  secondHalfForm(classes:any){
    return(
      <>
        <Box className={classes.frmControl}>
                <TextField id="outlined-basic" className={this.combineClassName(this.state.validWebsite ,this.state.companyWebsite , classes)} placeholder="Website*" variant="outlined" value={this.state.companyWebsite} onChange={this.onWebsiteChange} inputProps={{ style: { color: "black" } }}/>
                {!this.state.validWebsite && <span className={classes.errorMsg}>Please enter your company's website</span>}
              </Box>
              <Box className={classes.frmControl}>
                <TextField id="outlined-basic" placeholder="Describe your previous fundraising rounds*" className={this.combineClassName(this.state.validWebsite ,this.state.companyWebsite , classes)} onChange={this.onRoundChange} value={this.state.rounds} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                {!this.state.validRounds && <span className={classes.errorMsg}>Please Describe your previous fundraising rounds</span>}
              </Box>
              <Box className={classes.frmControl}>
                <TextField id="outlined-basic" placeholder="Describe your product*" className={this.combineClassName(this.state.validDescribeProduct ,this.state.describeProduct , classes)} onChange={this.onDescribeProductChange} value={this.state.describeProduct} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                {!this.state.validDescribeProduct && <span className={classes.errorMsg}>Please Describe your product</span>}
              </Box>
              <Box className={classes.frmControl}>
                <TextField id="outlined-basic" placeholder="Describe the revenue you are generating*" className={this.combineClassName(this.state.validRevenue ,this.state.revenue , classes)} onChange={this.onRevenueChange} value={this.state.revenue} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                {!this.state.validRevenue && <span className={classes.errorMsg}>Please Describe the revenue you are generating</span>}
              </Box>
              <Box className={classes.frmControl + " "}>
                <TextField id="outlined-basic" placeholder="How big is the team size?*" className={this.combineClassName(this.state.validTeamSize ,this.state.teamSize , classes)} onChange={this.onTeamSizeChange} value={this.state.teamSize} variant="outlined" inputProps={{ style: { color: "black" } }}/>
                {!this.state.validTeamSize && <span className={classes.errorMsg}>Please enter team size</span>}
              </Box>
              <Box className={classes.frmControl}>
                <TextField id="outlined-basic" placeholder="Anything more, if you want to share" variant="outlined" onChange={this.onAnythingMoreChange} value={this.state.anythingMore} className={this.state.anythingMore!==""?classes.filledFrmControl:""} inputProps={{ style: { color: "black" } }}/>
              </Box>
      </>
    )
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    console.log(this.state.name , "this.state.name####");
    return (
      <>
        <Box className={classes.mainContainer} id="RaiseFormMain">
          {/* banner content */}
          <Box className={classes.bannerContainer}>
            <HeaderWeb />
            <Container fixed>
              <h1 className={classes.FuturaBTMedium + " " + classes.h1}>Raise fund for your startup</h1>
              <Box className={classes.raiseFrmWrapper}>
                <h3 className={classes.FuturaBTBook + " " + classes.h3}>Fill This Space</h3>
                {this.firstHalfForm(classes)}
                {this.secondHalfForm(classes)}
                {/* @ts-ignore */}
                {Object.keys(this.state.filesArr).length == 0 ? (
                  <>
                    <label htmlFor="fileUpload"  className={classes.customFileUpload} style={{
                     //@ts-ignore
                      border: !this.state.fileFlag && "2px dashed red" }}
                      >
                      <Box className={classes.FileUpload} >
                        <input
                          type="file"
                          id="fileUpload"
                          multiple={true}
                          className={classes.fileUploadInput + " " + classes.errorFrmControl}
                          // value={this.state.fileName}
                          onChange={this.onFileChange}
                          style={{boxSizing:"border-box"}}
                        />
                        <div className={classes.fileUploadLabel}>
                          <span>Upload your Pitch</span>
                          <span>Drag & Drop or click here to browse</span>
                        </div>
                      </Box>
                    </label>
                    {!this.state.fileFlag && <span className={classes.errorMsg}>This field is mandatory. Please upload your pitch</span>}
                  </>
                ):<>
                   <Box className={classes.attachmentSection}>
                      <h1 className={classes.FuturaBTMedium}>Attachments</h1>
                      {this.state.filesArr.map((file: any, index: number) => {
                        return (
                          <Box className={classes.attachmentRow} key={index}>
                            <Box className={classes.attachmentBlock}>
                              <img src={pdfIcon} alt="Pdf Icon" />
                              <h5>{file.name}</h5>
                            </Box>
                            <Button onClick={()=>this.handleOpenDelete(index)}>
                              <img src={delIcon} alt="Delete Icon" style={{width:" 30px"}}/>
                            </Button>
                          </Box> 
                        );
                      })}
                    </Box>
                </>
                }
                <Box className={classes.campAboutBtnRow}>
                  <Button className={classes.customButton + " " + classes.primaryButton} onClick={this.onRaiseFundClick} id="raiseBtn" >Raise Funds</Button>
                </Box>
                <Modal
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Box sx={style} className={classes.successModal}>
                    <Button className={classes.closeBtn} onClick={this.handleClose}>
                      <img src={closeIcon} alt="Delete Icon" width="15px"/>
                    </Button>
                    <img src={successIcon} className={classes.modalIcon} alt="Success Icon" />
                    <h4 className={classes.FuturaBTMedium+" model-head"}>Submitted Successfully</h4>
                    <p className={classes.FuturaBTBook+" model-subhead"}>We will get in touch with you within<br></br>3 business days</p>
                  </Box>
                </Modal>
                <>
                  <Modal
                    open={this.state.openDelete}
                    onClose={this.handleCloseDelete}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Box sx={style} className={classes.successModal}>
                      <Button className={classes.closeBtn} onClick={this.handleCloseDelete}>
                        <img src={closeIcon} alt="Delete Icon"  style={{height: "15px" , width: "15px"}}/>
                      </Button>
                      <img src={warningIcon} className={classes.modalIcon} alt="Success Icon" />
                      <h4 className={classes.FuturaBTMedium}>Are you sure, you want to delete the attachment?</h4>
                      <Box className={classes.btnRow}>
                        <Button className={classes.customButton + " " + classes.dangerButton}onClick={()=>this.deleteItem(this.state.delIndex)} >Delete</Button>
                        <input
                          type="file"
                          id="fileReUpload"
                          className={classes.fileUploadInput + " " + classes.errorFrmControl}
                          onChange={this.onFileReuploadChange}
                        />
                        <label className={classes.customButton +" "+ classes. reuploadLabel} htmlFor="fileReUpload" style={{width: "auto"}}> Re-Upload</label>
                      </Box>
                    </Box>
                  </Modal>
                </>
              </Box>
            </Container>
            <FooterWeb />
          </Box>
        </Box>
      </>
    );
  }
};
//RAISE FORM SCREEN CSS
const RaiseFormStyle = {
  mainContainer: {
    height: "100%",
    // paddingBottom: 20,
    overflowX: "hidden",
    backgroundColor: "#f9fdfd",
    "& .MuiContainer-fixed":
    {
      "@media(max-width: 1024px)": {
        maxWidth: "1000px",
      } 
    }
  },
  errorMsg:
  {
    color: "red",
    fontWight: "600",
    fontSize: 13,
    textAlign: "left",
    paddingTop: 5,
    width: "100%",
    display:"inline-block",
  },
  h1:
  {
    color: "#474747",
    fontSize: 30,
    lineHeight: "60px",
    textAlign: "center",
    letterSpacing:"1px",
    "@media(max-width: 1024px)": {
      fontSize: 25,
      lineHeight: "30px",
    },
  },
  h3:
  {
    color: "#333",
    fontSize: 21,
    marginTop: 0,
    fontWeight:600,
  },
  fonts:
  {
    fontFamily: 'FuturaBT-Book, sans-serif',
    fontWeight: "400",
  },
  customButton:
  {
    color: "#00BC4A",
    border: "1px solid #00BC4A",
    fontFamily: 'FuturaBT-Book, sans-serif',
    padding: "5px 10px",
    textTransform: "capitalize",
    backgroundColor: "#edfff4",
    width: "100%",
    "&:hover":
    {
      backgroundColor: "#00BC4A",
      color: "#fff",
    },
    "& .MuiButton-label":
    {
      fontSize: "13px",
      fontWeight: 600,
    }
  },
  FuturaBTBook:{
    fontFamily: "FuturaBT-Book !important",
  },
  FuturaBTMedium:{
    fontFamily: "FuturaBT-Medium !important",
  },
  FuturaMedium:{
    fontFamily: "FuturaBT-Medium !important",
  },
  FuturaBold:{
    fontFamily: "Futura-Bold !important",
  },
  reuploadLabel:
  {
    display: "block",
    borderRadius: "4px",
    padding: "10px",
    marginTop: "10px",
  },
  primaryButton:
  {
    color: "#fff",
    padding: "10px",
    backgroundColor: "#00BC4A",
    "&:hover":
    {
      border: "1px solid #00BC4A",
    },
    "& .MuiButton-label":
    {
      fontSize: "16px",
    }
  },
  dangerButton:
  {
    color: "#fff",
    padding: "10px",
    backgroundColor: "#FF3B30",
    border: "1px solid #FF3B30",
    "&:hover":
    {
      border: "1px solid #FF3B30",
      backgroundColor: "#FF3B30",
    },
    "& .MuiButton-label":
    {
      fontSize: "16px",
    }
  },
  campAboutBtnRow:
  {
    paddingTop: 50,
    width: "100%",
  },
  raiseFrmWrapper:
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 150px",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#fff",
    "@media(max-width: 767px)": {
      padding: "0px",
      maxWidth: "100%",
    },
  },
  frmControl:
  {
    // width: "100%",
    // padding: "15px",
    // border: "1px solid #ccc",
    // borderRadius: "5px",
    // outline: 0,
    // fontSize: "15px",
    // color: "#878787",
    // "&:placeholder": {
    //   color: "#878787",
    // }
    width: "100%",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
      "& .MuiInputBase-input": {
        color: "#000",
        fontSize: "15px",
        "&::placeholder": {
          color: "#a9a9a9",
          opacity: "1",
        }
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #dbdbdb",
      },
      "&.Mui-focused": {
        "& fieldset": {
          "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "#36bc58",
          }
        }
      }
    },
    "& input": {
      fontFamily:"FuturaBT-Book, sans-serif",
      color:"#000",
    }
    // "& .MuiFormControl-root": {
    //   width: "100%",
    //   "&.Mui-focused": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: "0px !important",
    //     }
    //   }
    // }
  },
  errorFrmControl:
  {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid red",
      },
      "&.Mui-focused": {
        "& fieldset": {
          "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          }
        }
      }
    },

  },
  filledFrmControl:
  {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid black !important",
      },
    },

  },
  customFileUpload:
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed #ccc",
    borderRadius: "10px",
    padding: "70px 20px",
    width: "100%",
    boxSizing: "border-box",
  },
  fileUploadLabel:
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#a9a9a9",
    cursor: "pointer",
    fontSize:"16px",
    "& span:first-child": {
      fontWeight:600,
      marginBottom:"10px",
      fontSize:"18px",
      color:"#8a8a8a",
    }
  },
  fileUploadInput: {
    display: "none",
  },
  attachmentSection:
  {
    width: "100%",
    marginTop: 30,
    "& h1":
    {
      textAlign: "center",
    }
  },
  attachmentRow:
  {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E1E1E1",
    padding: "10px 20px",
    borderRadius: "5px",
    marginBottom: "10px",
    boxSizing:"border-box",
    "& img":
    {
      width: 25,
      height: 25,
      objectFit: "contain",
    },
    "& h1":
    {
      textAlign: "center",
    },
    "& h5":
    {
      paddingLeft: 10,
      margin: 0,
    }
  },
  attachmentBlock:
  {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  successModal:
  {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 30px",
    position: "relative",
    "@media (max-width: 767px)": {
      boxSizing:"border-box",
      maxWidth:"85%",
    },
    "& .model-head": {
      fontSize:"22px",
      lineHeight:"26px",
      margin:"20px 0",
    },
    "& .model-subhead": {
      margin:0,
    }
  },
  closeBtn:
  {
    position: "absolute",
    top: 12,
    right: 12,
    minWidth:"auto",
  },
  modalIcon:
  {
    width: 80,
    height: 80,
    objectFit: "centain",
  },
  btnRow:
  {
    width: "100%",
    "& button":
    {
      margin: "5px 0",
      padding: "10px",
      width: "100%",
    },

  }
}
//@ts-ignore
export default withStyles(RaiseFormStyle)(withRouter(RaiseForm))
// Customizable Area End