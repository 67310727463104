import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  faq: any;
  isVisible: boolean;
  activeId: number;
  activeTitle: string;
  activeContent: string;
  activeCreatedAt: string;
  activeUpdatedAt: string;
  title: string;
  content: string;
  value: any;
  investersFaqs: any;
  startupsFaqs: any;
  toggleStatus: number | null;
  is_startupshow: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LearnFaqController extends BlockComponent<Props, S, SS> {
  value: any;
  editorState: any;
  faqApiCallId: any;
  deleteFaqApiCallId: any;
  addFaqApiCallId: any;
  richtext: any;
  editor: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null; 

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      faq: [],
      isVisible: false,
      activeId: 0,
      activeTitle: "",
      activeContent: "",
      activeCreatedAt: "",
      activeUpdatedAt: "",
      title: "",
      content: "",
      // Customizable Area Start
      investersFaqs: [],
      startupsFaqs: [],
      toggleStatus: null,
      is_startupshow: false,
      // Customizable Area End
      value: this.value
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentDidMount() {
    // Customizable Area Start
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    this.faqApiCallId = await this.faqApiCall({
      contentType: "application/json",
      token: `${user.token}`,
      method: "GET",
      endPoint: `faqs`,
    });
    this.setState({ toggleStatus: 0 });
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if(apiRequestCallId === this.faqApiCallId) {
        if (responseJson.errors && responseJson.errors.length > 0) {
          if (responseJson.errors[0].token.toLowerCase().includes("token")) {
              localStorage.clear()
              window.location.href = "/";
              return;
          }
      }  else {
        const newInvesterFaq = responseJson.investors?.data?.map((item:any) => ({
          que: item.attributes.question,
          ans: item.attributes.answer
        })) || [];
        const newStartupFaq = responseJson.startups?.data?.map((item:any) => ({
          que: item.attributes.question,
          ans: item.attributes.answer
        })) || [];

        this.setState({
          investersFaqs: newInvesterFaq,
          startupsFaqs: newStartupFaq,
        });
      }
      }
    }
  }
  // Customizable Area End
  // Customizable Area Start
  faqApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token
    };
    const requestMessagefaq = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagefaq.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagefaq.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessagefaq.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessagefaq.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessagefaq.id, requestMessagefaq);
    return requestMessagefaq.messageId;
  };

  handleAccordion = (accNo: number) => {
    this.setState(prevState => ({
      toggleStatus: prevState.toggleStatus === accNo ? null : accNo,
    }));
  };
  // Customizable Area End
}
