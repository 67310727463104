import React from "react";
import LandingPageController, {Props} from "./LandingPageController";
// Customizable Area Start
import {
  Grid,
  Container
} from '@material-ui/core';
import HeaderWeb from "./Header.web";
import FooterWeb from "./Footer.web";
import '../assets/css/Charter.web.css';
import { charter1, charter2, charter3, charter4, charter5 } from "./assets";
// Customizable Area End

export default class CharterScreen extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Customizable Area Start
      <>
        <HeaderWeb/>
        <Container fixed className="charter-main">
          <div className="page_title">
            <span>Charter:</span> A List of rules adopted by Yuva Invest
          </div>
          <Grid container className="section-container">
            <div className="img-wrapper">
              <img src={charter1} alt="" className="charter-img"/>
            </div>
            <div className="data-wrapper">
              <span className="data-head">ESG Friendly</span>
              <p className="data-body">We endeavor to consume minimum possible energy, water and raw materials as we can while reducing carbon footprint or creating ways to use the resources in a more sustainable and eco-friendly manner. We are also committed for paperless work until and unless it is very important to print. We will plant one fruit-bearing tree for every raise of USD 10,000 (equivalent to INR 8,20,000) at our platform, and strive to look after the planted trees and replace the damage, if any, till next 2 years of time with our own resources. It will help in battling the negative impact of Climate Change, thereby giving our community a safe future for their next generation.</p>
            </div>
          </Grid>
          <Grid container className="section-container reverce">
            <div className="data-wrapper">
              <span className="data-head">We donate 10% of our net profit</span>
              <p className="data-body">Everyone has the fundamental right to good health and education. We promise to donate 10% of our net profit to any organization working in the area of health and/or education, By supporting these social cause, we aim to make a positive change in our community.</p>
            </div>
            <div className="img-wrapper">
              <img src={charter2} alt="" className="charter-img"/>
            </div>
          </Grid>
          <Grid container className="section-container">
            <div className="img-wrapper">
              <img src={charter3} alt="" className="charter-img"/>
            </div>
            <div className="data-wrapper">
              <span className="data-head">We encourage Women Empowerment</span>
              <p className="data-body">Yuva Invest is co-founded by women. We promise to maintain at least 33% of the total work force, either full-time or part-time, as women employees. It will help in Women Empowerment to an extent, and promote equality in society by providing equal opportunity.</p>
            </div>
          </Grid>
          <Grid container className="section-container reverce">
            <div className="data-wrapper">
              <span className="data-head">LGBTQ friendly</span>
              <p className="data-body">LGBTQ respondents mention prejudice during the recruiting process, and at work place. It has a negative impact on the personal and professional lives of these people who are specially oriented. If they disclosed their orientation, it may sometimes result in losing their jobs as well. In such cases, Yuva Invest pledges to foster a climate that is secure, considerate, and courteous towards the people of fluid orientation.</p>
            </div>
            <div className="img-wrapper">
              <img src={charter4} alt="" className="charter-img"/>
            </div>
          </Grid>
          <Grid container className="section-container">
            <div className="img-wrapper">
              <img src={charter5} alt="" className="charter-img"/>
            </div>
            <div className="data-wrapper">
              <span className="data-head">We are Shariah & Sanatan Friendly</span>
              <p className="data-body">We strive to adhere to the Shariah and Sanatan guidelines. As a result, we promise not to list or promote any businesses on our platform that are to some part/ wholly engaged in commercial operations associated with drugs (such as psychotropic substances, etc.), pornography or nudity, gambling, etc.</p>
            </div>
          </Grid>
          <Grid container className="section-container">
            <span className="updated-date">November 2022.</span>
          </Grid>
        </Container>
        <FooterWeb/>
      </>
      // Customizable Area End
    );
  }
}
