// Customizable Area Start
import React from "react";
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "./Footer.web";
import HeaderWeb from "./Header.web";
import {
    Box,
    Grid,
    Container,
    Button,
    Link,
    Accordion,
    AccordionDetails,
    AccordionSummary, 
} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    founderProfile,
}
    from "./assets";
import LandingPageController from "./LandingPageController.web";
import  "../../EkycVerification/assets/css/EkycDialogs.web.css"
import AskEkycModalWeb from "../../EkycVerification/src/AskEkycModal.web";
import AskSignupModalWeb from "../../EkycVerification/src/AskSignupModal.web";
import '../assets/css/Campaigns.web.css';
// Customizable Area End
class CampaignDetailWebPage extends LandingPageController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     peopleBehind:any;
     investorsAdvisers:any;
    async componentDidMount() {
        let url = window.location.pathname;
        let id = url.substring(url.lastIndexOf('/') + 1);
        await this.getCampaignDetail(id);
      }
       investButton(classes:any,is_verified:any,d2:any){
        const currUrl = window.location.pathname;
        const campaignID = currUrl.substring(currUrl.lastIndexOf('/') + 1);
        return (   
        <>
        {this.state.userToken && is_verified ? 
                                    <Box className={classes.campAboutBtnRow}>
                                        {this.state.campaignStatus === 'Live' && <Button className={classes.customButton + " " + classes.primaryButton} onClick={()=>this.props.history.push({pathname: "/checkout",state: {title: d2, campaignId: campaignID},})} style={{color:"#fff"}} id="subscribe-btn">Subscribe Now</Button>}
                                    </Box> :  <Box className={classes.campAboutBtnRow}>
                                        {this.state.campaignStatus === 'Live' && <Button className={classes.customButton + " " + classes.primaryButton} onClick={()=>this.state.userToken ? this.setState({askEkycDialog:true}) : this.setState({askSignupDialog:true})}  disabled={is_verified} style={{color:"#fff"}}>Subscribe Now</Button>}
                                    </Box> }
            </>
        )
                }
                tagButton(classes:any){
                    return(
                    <Box className={classes.campProfileBtnBlock}>
                                {this.state.campaignDetail?.attributes?.service_type_tag_1&&<Button className={classes.customButton}>{this.state.campaignDetail?.attributes?.service_type_tag_1}</Button>}
                                {this.state.campaignDetail?.attributes?.service_type_tag_2&&<Button className={classes.customButton}>{this.state.campaignDetail?.attributes?.service_type_tag_2}</Button>}
                                {this.state.campaignDetail?.attributes?.service_type_tag_3&&<Button className={classes.customButton}>{this.state.campaignDetail?.attributes?.service_type_tag_3}</Button>}
                                </Box>
                )}
sectionone(classes:any) {
    return(
        <>
        {this.state.value === 0 && (
                        <>                  
                       {this.state.campaignDetail?.attributes?.sub_sections?.sub_section_one.data[0]?.attributes && 
                            <Box className={classes.verizonTabContent + " about-camp-box"} id="termRoot">
                                <Container fixed className={classes.campDetailContactSection}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <h2 className={classes.FuturaBtMedium + " campaignTabTitle " + classes.h2}>  {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[0]?.attributes?.title_1}</h2>
                                            <p className={classes.FuturaBtMedium + " " + classes.leadText}>
                                            {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[0]?.attributes?.description_1}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{width:"620px" }}>
                                            <img src= {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[0]?.attributes?.image_one} className={classes.campDetailContactImg}  alt="Contact Img" />
                                        </Grid  >
                                    </Grid>
                                </Container>
                            </Box> }
                            {this.state.campaignDetail?.attributes?.sub_sections?.sub_section_one.data[1]?.attributes && 
                                <Box className={classes.campDetailOurWorkSection + " about-camp-box " + classes.commonSections} >
                                    <Container fixed className={classes.campDetailContactSection}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={7} style={{width:"680px" }} >
                                                <img src=    {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[1]?.attributes?.image_one} className={classes.campDetailContactImg}  alt="Contact Img" />
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <h2 className={classes.FuturaBtMedium + " campaignTabTitle " + classes.h2}>   {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[1]?.attributes?.title_1}</h2>
                                                <p className={classes.FuturaBtMedium + " " + classes.leadText}>
                                                {this.state.campaignDetail.attributes?.sub_sections.sub_section_one.data[1]?.attributes?.description_1}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Box >
                            }
                            {this.state.campaignDetail?.attributes?.sub_sections?.sub_section_two?.data.length > 0 && <>
                                {( this.state.services !== null && this.state.services !== 0 )&&<Box className={classes.campDetailVersionSection + " services-box " + classes.services}  >
                                <Container fixed className={classes.campDetailVersionContainer} id="ourService">
                                    <h2 className={classes.FuturaBold + " " + classes.h2 +" "+ classes.ccpad} >Our Services</h2>
                                    <div className={`${classes.descriptionOurServices} ${classes.FuturaBtMedium} ${classes.leadText}`}>
                                   
                                    </div>
                                    <Grid container spacing={2} className={classes.campDetailInvestsCardRow}>
                                        {this.state.services.map((item:any , index:number)=>{
                                                return(
                                                    <Grid item xs={12} sm={4} key={index}>
                                            <Box className={classes.campDetailInvestsCard}>
                                                <img src={item?.attributes?.service_logo} className={classes.campDetailInvestImg} alt="Growth Icon" />
                                                <Box className={classes.campDetailInvestContentBlock}>
                                                    <h5 className={classes.FuturaBold + " " + classes.h5}>{item?.attributes?.title}</h5>
                                                </Box>
                                            </Box>
                                        </Grid>
                                                )
                                        })}
                                    </Grid>
                                </Container>
                            </Box >}
                            </>}
                            {/* campaign invest services Section */}
                            
                           { ( this.state.campaignDetail?.attributes?.sub_sections?.sub_section_three?.data !== null && this.state.campaignDetail?.attributes?.sub_sections?.sub_section_three?.data?.attributes?.stakeholder_image !== null )&&<Box className="founder-main-box">
                                <Container fixed className={classes.campDetailServicesSection + " " + classes.commonSections}>
                                    <h2 className={classes.FuturaBold + " " + classes.h2}>{this.state.campaignDetail?.attributes?.sub_sections?.sub_section_three?.data?.attributes?.stakeholder_designation}</h2>
                                    <Grid container spacing={2}>

                                        <Grid className={classes.founderImageBg} style={{background: "url(" + this.state.campaignDetail?.attributes?.sub_sections?.sub_section_three?.data?.attributes?.stakeholder_image + ")",}}>
                                            <Grid item xs={12} sm={6}>
                                                <Box className={classes.founderContent}>
                                                    <h5 className={classes.fonts + " " + classes.h5}>{this.state.campaignDetail?.attributes?.sub_sections?.sub_section_three?.data?.attributes?.stakeholder_name}</h5>
                                                    <p className={classes.FuturaBtBook + " " + classes.leadTexts}>
                                                        {this.state.campaignDetail?.attributes?.sub_sections.sub_section_three?.data?.attributes?.description}
                                                    </p>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box >}
                        </>
                    )}
        </>
    )
}
sectiontwo(classes:any) {
    return(
        <>
         {this.state.value === 1 && (
                        <Box className={classes.termsTabContent +" "+ classes.termsTabContentBlock} id="termRoot">
                            <Container fixed className={classes.campDetailContactSection}>
                                <h2 className={classes.fonts + " " + classes.h2}>Deal Terms</h2>
                                <Grid className={classes.termtableBlock}>
                                    <Grid className={classes.termBlocks}>
                                        {this.state.campaignDetail?.attributes?.term_sections?.data[0]?.attributes?.terms?.data.map((item:any , index:number )=>{
                                            return(<div className="tbl-row">
                                                <span key={index} className={classes.FuturaBtBook + " " + classes.h5}  >{item?.attributes?.term_title}</span>
                                                <span key={index} className={classes.FuturaBtBook + " " + classes.h5}>{item?.attributes?.term_description}</span>
                                            </div>);
                                        })}
                                    </Grid>
                                  
                                </Grid>
                            </Container>
                            <Container fixed className={classes.campDetailContactSection}>
                                <Box className={classes.docTermBlock}>
                                    <h2 className={classes.fonts + " " + classes.h2}>Documents</h2>
                                    <Grid container className={classes.termsTable}>
                                        <Grid item className={classes.termBlocks}>
                                        {this.state.campaignDetail?.attributes?.term_sections?.data[0]?.attributes?.term_documents?.data.map((item:any , index:number)=>{
                                            return( <div className="tbl-row">
                                                <span key={index} className={classes.FuturaBtBook + " " + classes.h5}>
                                                    {item?.attributes?.document_name}
                                                </span>
                                                <span className={classes.FuturaBtBook + " " + classes.h5} key={index}> 
                                                    <Link  onClick={()=>this.downloadFile(item?.attributes?.upload_file)}>Download</Link>
                                                </span>
                                            </div>
                                            )
                                        })} 
                                        </Grid>
                                      
                                    </Grid>

                                </Box>
                            </Container>
                        </Box>
                    )}
        </>
    )
}
    // Customizable Area End
    render() {
        const { classes } = this.props;
        //@ts-ignore
        this.peopleBehind = 
        this.state.campaignDetail?.attributes?.team_sections?.data[0]?.attributes?.people_behinds?.data.map((item:any, index:number)=>{
            return ({
                //@ts-ignore
                "index": index,
                "headline": item?.attributes?.description,
                "headlineTitle": item?.attributes?.name,
                "headlineSubTitle": item?.attributes?.designation,
                "src": item?.attributes?.image,
            })
        }) 
        this.investorsAdvisers = this.state.campaignDetail?.attributes?.team_sections.data[0]?.attributes?.investors_and_advisors?.data.map((item:any, index:number)=>{
            return ({
                //@ts-ignore
                "index": index,
                "headline": item?.attributes?.description,
                "headlineTitle": item?.attributes?.name,
                "headlineSubTitle": item?.attributes?.designation,
                "src": item?.attributes?.image,
            })
        })            
        // Customizable Area Start
        const userStatus = localStorage.getItem("userVerified");
        const is_verified = userStatus === "true" ? true : false;
        const upcomingsec =()=> {
            return(
                <>
                <Box className={classes.campAboutContentRow}>
                                {this.state.campaignDetail?.attributes?.status === "Upcoming" ?
                                <Box className={classes.liveinContentBlock}>
                                <h6 className={classes.FuturaBTBook + " " + classes.h6}>Live in</h6>
                                <h2 className={classes.fonts + " " + classes.h2}><span className={classes.boldTitle}>{`${this.state.campaignDetail?.attributes?.live_in} days`}</span></h2>
                            </Box>:
                            <>
                            <Box className={classes.campAboutContentBlock}>
                                    <h6 className={classes.FuturaBTBook + " " + classes.h6}>Percent Raised</h6>
                                    <h2 className={classes.fonts + " " + classes.h2}><span className={classes.boldTitle}>{`${this.state.campaignDetail?.attributes?.percent_raised}`}</span></h2>
                                </Box>
                                {this.state.campaignStatus === 'Live' &&
                                <Box className={classes.campAboutContentBlock}>
                                    <h6 className={classes.FuturaBTBook + " " + classes.h6}>Closes In</h6>
                                    <h2 className={classes.fonts + " " + classes.h2}><span className={classes.boldTitle}>{`${this.state.campaignDetail?.attributes?.closes_in} days`}</span></h2>
                                </Box>  }
                            </>
                                }
                            </Box>
                </>
            )
        }

        const upperSection1Jsx = ()=>{
            return(
                
                <>
                  <Box className={classes.campDetailBannerSection}>
                    <Container fixed className={classes.campDetailBannerContainer}>
                        <Box className={classes.campProfileRow}>
                            
                            <img src={this.state.campaignDetail?.attributes?.campaign_logo} className={classes.campIcon} />
                            <Box className={classes.campProfileRightBlock}>
                                <h2 className={classes.FuturaBtMedium + " " + classes.h2} style={{textAlign:"left"}}>{this.state.campaignDetail?.attributes?.campaign_name}</h2>
                                {this.tagButton(classes)}
                            </Box>
                        </Box>
                        <Box className={classes.campVideoRow} id="videosection">
                            {this.state.campaignDetail.attributes?.show_image? 
                        <img src={this.state.campaignDetail.attributes?.campaign_image_video} className={classes.mainContainet}/>
                           : <video controls autoPlay muted src={this.state.campaignDetail.attributes?.campaign_video} className={classes.mainContainet} >
                            <source src={this.state.campaignDetail.attributes?.campaign_video} type="video/ogg"/>
                            <source src={this.state.campaignDetail.attributes?.campaign_video} type="video/mp4"/>
                            <source src={this.state.campaignDetail.attributes?.campaign_video} type="video/webM"/>
                                Your browser does not support the video tag.
                                </video>
        }
                          
                        </Box>
                        <Box className={classes.campAboutRow}>
                            <h2 className={classes.fonts + " " + classes.h2}>{`About ${this.state.campaignDetail?.attributes?.campaign_name}`}</h2>
                            <p className={classes.FuturaBtBook + " " + classes.leadText}>
                                {this.state.campaignDetail?.attributes?.campaign_description}
                            </p>
                        </Box>
                        <Box className={classes.campAboutBottomRow}>
                            {upcomingsec()}
                            {this.investButton(classes,is_verified,this.state.campaignDetail?.attributes?.campaign_name)}
                        </Box>
                    </Container>
                </Box>
                {renderWhyInvestSection()}
                </>
            )            
        }
        
        const renderWhyInvestSection = () => {
            return(<>
                {( this.state.campaignDetail?.attributes?.why_invest_in_campaigns?.data?.attributes?.why_invest_in_campaign_descreption ) &&
                    <>
                    {(this.state.campaignDetail?.attributes?.why_invest_in_campaigns?.data?.attributes?.why_invest_sub_sections?.data.length === 3 || this.state.campaignDetail?.attributes?.why_invest_in_campaigns?.data?.attributes?.why_invest_sub_sections?.data.length === 6) &&
                        <Box className={classes.campDetailVersionSection + " " + classes.commonSections} style={{marginBottom:0}}>
                            <Container fixed className={classes.campDetailVersionContainer}>
                                <h2 className={" section-head " + classes.h2}>
                                    {`Why subscribe in ${this.state.campaignDetail?.attributes?.campaign_name} ?`}
                                </h2>
                                <p className={classes.FuturaBtBook + " " + classes.leadText}>
                                    {this.state.campaignDetail?.attributes?.why_invest_in_campaigns?.data?.attributes?.why_invest_in_campaign_descreption}
                                </p>
                                <Grid container spacing={2} className={classes.campDetailInvestsCardRow}>
                                    {this.state.campaignDetail?.attributes?.why_invest_in_campaigns?.data?.attributes?.why_invest_sub_sections?.data.map((item:any , _index:number)=> <>
                                        <Grid item xs={12} sm={4} id="whyInvest" key={item?.attributes?.image}>
                                            <Box className={classes.campDetailInvestsCard}>
                                                <img src={item?.attributes?.image} className={classes.campDetailInvestImg} alt="Growth Icon" />
                                                <Box className={classes.campDetailInvestContentBlock}>
                                                    <h5 className={classes.FuturaBold + " " + classes.h5}>{item?.attributes?.sub_title}</h5>
                                                    <p className={classes.FuturaBtBook + " " + classes.lead}>
                                                        {item?.attributes?.sub_description}
                                                    </p>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </>)}
                                </Grid>
                            </Container>
                        </Box>
                    }
                    </> 
                }
            </>)
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.mainContainer} id="CampaignDetailsRoot">
                {/* Header content */}
                <HeaderWeb />
                {upperSection1Jsx()}
                {/* ============ MAIN CON   ENT ============ */}
                {/* campaign detail banner Section */}
              
                {/* Four Tabs Are Below Here  */}
                <Container fixed className={classes.campDetailServicesSection+ " "+classes.tabsBlock}>
                    <h2 className={classes.FuturaBold + " " + classes.h2} style={{marginTop:"10px"}}>{`Learn more about ${this.state.campaignDetail?.attributes?.campaign_name}`}</h2>
                    <Box className={classes.campDetailCustomTab}>
                        <Tabs value={this.state.value} data-test-id="tabSection"   onChange={this.handleChange}>
                            {!this.state.sectionOneStatus && <Tab value={0} label={this.state.campaignDetail?.attributes?.campaign_name} />}
                            <Tab value={1} label="Terms" id="term"/>
                            <Tab value={2} label="Team" />
                            <Tab value={3} label="FAQs" />
                        </Tabs>
                    </Box>
                    {this.sectionone(classes)}
                    {this.sectiontwo(classes)}
                   
                    {this.state.value === 2 && (
                        <>
                            <Box className="slides-section meet-people" id="meetPeope">
                                <h2 className={classes.fonts + " " + classes.h2 + " heading"} style={{ marginBottom: "25px" }}>{`Meet the people behind ${this.state.campaignDetail?.attributes?.campaign_name}`}</h2>
                                <div className="slides-inner-container">
                                    {this.peopleBehind.map((item:any , index:number) => 
                                        <div className="section-card" key={index}>
                                            <div className="card-img-wrapper">
                                                <img src={item?.src} alt=""/>
                                            </div>
                                            <span className="people-name">{item?.headlineTitle}</span>
                                            <span className="people-designation">{item?.headlineSubTitle}</span>
                                        </div>
                                    )}
                                </div>
                            </Box>

                           { this.state.campaignDetail?.attributes?.team_sections.data[0]?.attributes?.investors_and_advisors?.data.length !== 0 && <Box className="slides-section invester-section">
                                <h2 className={classes.fonts + " " + classes.h2 + " heading"} style={{ marginBottom: "25px" }}>Subscriber & Advisors</h2>
                                <div className="slides-inner-container">
                                    {this.investorsAdvisers.map((item:any , index:number) => 
                                        <div className="section-card" key={index}>
                                            <div className="card-img-wrapper">
                                                <img src={item?.src} alt=""/>
                                            </div>
                                            <span className="people-name">{item?.headlineTitle}</span>
                                            <span className="people-designation">{item?.headlineSubTitle}</span>
                                        </div>
                                    )}
                                </div>
                            </Box>}
                            {/* </Box> */}
                        </>
                    )}
                    {this.state.value === 3 && (
                        <Box className={classes.termsTabContent} id="faq">
                            <Container fixed className={classes.faqAccordionContainer}>
                                <h2 className={classes.fonts + " " + classes.h2}>Frequently Asked Questions</h2>
                                {this.state.campaignDetail?.attributes?.faq_sections?.data.map((item:any , index:number)=>{
                                    return(
                                        <Accordion className={classes.faqAccordion} defaultExpanded={index===0?true:false} key={index}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        // expandIcon={ index==0?<RemoveCircleOutlineIcon /> :<ControlPointIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="acccordion-header"
                                    >
                                        <h5 className={classes.FuturaBtMedium + " " + classes.h5} style={{fontSize:"20px",wordBreak:"break-word"}}>{item?.attributes?.question}</h5>
                                    </AccordionSummary>
                                    <AccordionDetails className="acccordion-dis">
                                        <p className={classes.FuturaBtBook + " " + classes.leadText} style={{fontSize:"18px",wordBreak:"break-word"}}>
                                            {item?.attributes?.answer}
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                                    )
                                })}
                            </Container>
                        </Box>
                    )}
                    {/* @ts-ignore */}
                    <AskEkycModalWeb id="askEkycModalWeb" openState={this.state.askEkycDialog}  onCloseFunc = {()=>this.handleAskEkycDialog()}/>
                    {/* @ts-ignore */}
                    <AskSignupModalWeb id="askSignupModalWeb" openState={this.state.askSignupDialog} onCloseFunc={()=>this.handleAskSignupDialog()}/>
                  
                    {this.investButton(classes,is_verified,this.state.campaignDetail?.attributes?.campaign_name)}
                </Container>
                < FooterWeb />
                
            </Box >

            // Customizable Area End
        );
    }
   
};

// Customizable Area Start
const campaignDetailscreenStyle = {
    mainContainer: {
        height: "100%",
        overflowX: "hidden",
        backgroundColor: "#fff",
        "& .MuiContainer-fixed":
        {
            "@media(max-width: 1024px)": {
                maxWidth: "1000px",
            }
        }
    },
    hr: {
        borderTop: "2px solid #E0E0E0",
    },
    brandHr: {
        borderTop: "1px solid #E0E0E0",
        margin: "30px 0",
    },
    pt: {
        paddingTop: 50,
    },
    commonSections: {
        margin: "50px 0",
    },
    fonts:
    {
        fontFamily: 'Futura-Bold !important',
        fontWeight: "400",
    },
    FuturaBold:
    {
        fontFamily: 'Futura-Bold !important',
        fontWeight: "400",
    },
    ccpad:{
        paddingBottom:"100px",
    },
    FuturaBtBook:
    {
        fontFamily: 'FuturaBT-Book !important',
        fontWeight: "400",
    },
    FuturaBtMedium:
    {
        fontFamily: 'FuturaBT-Medium !important',
        fontWeight: "400",
    },
    FuturaItalic:
    {
        fontFamily: 'Futura-italic !important',
        fontWeight: "400",
    },
    FuturaLight:
    {
        fontFamily: 'Futura-light !important',
        fontWeight: "400",
    },
    headerTitle:
    {
        textAlign: "center",
    },
    termDesc:
    {
     whiteSpace: "nowrap",
    },
    h1:
    {
        color: "#474747",
        fontSize: 40,
        lineHeight: "60px",
        fontWeight: "800",
        "@media(max-width: 1024px)": {
            fontSize: 25,
            lineHeight: "30px",
        },
    },
    h2:
    {
        color: "#333",
        fontSize: 30,
        margin: 0,
        // padding: "50px 0",
        fontFamily:"FuturaBT-Medium, sans-serif !important",
        fontWeight: 600,
        textAlign: "center",
        "@media(max-width: 1024px)": {
            fontSize: 22,
        },
    },
    services:{
        width: "100vw !important"
    },
    h3:
    {
        color: "#414141",
        fontSize: 20,
    },
    h5:
    {
        color: "#393939",
        fontSize: 24,
        lineHeight: "30px",
        margin: 0,
    },
    h6:
    {
        color: "#474747",
        fontSize: 18,
        margin: 0,
        paddingLeft: "10px",
    },
    span:
    {
        color: "#787878",
        fontSize: 15,
        margin: 0,
    },
    leadText:
    {
        color: "#393939",
        fontSize: 15,
        lineHeight: "28px",
        margin: 0,
    },
    lead:
    {
        color: "#6D6D6D",
        fontSize: 18,
        lineHeight: "28px",
    },
    customButton:
    {
        color: "#00BC4A",
        border: "1px solid #00BC4A",
        fontFamily: 'FuturaBT-Book, sans-serif',
        padding: "5px 10px",
        textTransform: "capitalize",
        backgroundColor: "#edfff4",
        "&:hover":
        {
            backgroundColor: "#00BC4A",
            color: "#fff",
        },
        "& .MuiButton-label":
        {
            fontSize: "13px",
            fontWeight: 600,
        }
    },
    primaryButton:
    {
        color: "#fff",
        padding: "10px",
        backgroundColor: "#00BC4A",
        "&:hover":
        {
            border: "1px solid #00BC4A",
        },
        "& .MuiButton-label":
        {
            fontSize: "16px",
        }
    },
    campProfileRow:
    {
        display: "flex",
        alignItems: "center",
        padding: "30px 0",
    },
    campIcon:
    {
        height: 90,
        width: 90,
        border: "2px solid #dcd2d2",
        borderRadius: "50%",
        objectFit: "cover",
    },
    campProfileRightBlock:
    {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 15,
        "& h2":
        {
            padding: "10px 0",
            letterSpacing:"1px",
        }
    },
    campProfileBtnBlock:
    {
        display: "flex",
        "@media (max-width: 767px)": {
            flexWrap: "wrap",
        },
        "& button":
        {
            marginRight: 10,
            pointerEvents:"none",
            "@media (max-width: 767px)": {
                marginBottom:'10px',                
            },
        }
    },
    campVideoRow:
    {
        "& iframe":
        {
            borderRadius: 12,
            border: 0,
            outline: 0,
        }
    },
    campAboutRow:
    {
        padding: "50px 0",        
        "& p":
        {
            textAlign: "center",
            fontSize: 18,
            color: "#2C2C2E",
            paddingTop: "20px",
        }
    },
    boldTitle:
    {
        fontWeight: "600",
    },
    tabsBlock: {
        marginTop:"60px",
    },
    campDetailVersionContainer: {
        "& .section-head": {}
    },
    campAboutBottomRow:
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    campAboutContentRow:
    {
        backgroundColor: "#EDFFF4",
        display: "flex",
        borderRadius: 12,
    },
    liveinContentBlock:
    {
        padding:"20px",
        boxSizing:"border-box",
        "& h2":
        {
            padding: 0,
            fontFamily:"FuturaBT-Medium,sans-serif !important",
            fontWeight:600,
        },
        "& h6":
        {
            paddingBottom: 20,
            textAlign: "center",
            paddingLeft: 0,
        },
        "&:nth-child(2)":
        {
            borderRight: 0,
        }
    },

    campAboutContentBlock:
    {
        
        padding: 30,
        "& h2":
        {
            padding: 0,
            fontFamily:"FuturaBT-Medium,sans-serif !important",
            letterSpacing:"0.5px",
        },
        "& h6":
        {
            paddingBottom: 20,
            paddingLeft:0,
            textAlign:"center",
        },
        "&:nth-child(2)":
        {
            borderRight: 0,
            borderLeft: "1px solid #D8D8D8",
        }
    },
    campAboutBtnRow:
    {
        paddingTop: 30,
        "& button":
        {
            padding: "10px 25px",
        },
    },
    campDetailVersionSection:
    {
        backgroundColor: "#f0f9f9",
        padding: 70,
        "@media(max-width: 767px)": {
            padding: "70px 10px",
        },
        "& p": {
            padding: "15px 0 30px 0",
            textAlign: "center",
            fontSize: 19,
            color: "#2c2c2e",
        },

    },
    campDetailInvestsCard:
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& h5   ":
        {
            color: "#4a4a4a",
            fontSize: 20,
            fontFamily:"FuturaBT-Medium,sans-serif !important",
            fontWeight: 600,
            letterSpacing:"0.5px",
        },
        "& p":
        {
            fontSize: 15,
            marginTop: 0,
            padding: "10px 0 0 0",
            lineHeight: "18px",
            color: "#2c2c2e",
            width: "85%",
            margin: "0 auto",
            "@media(max-width: 767px)": {
                width: "100%",
            },
        },

    },
    campDetailInvestImg:
    {
        height: 100,
        width: 100,
        objectFit: "cover",
        borderRadius:"50%",
    },
    campDetailInvestsCardRow:
    {
        paddingTop: 20,
        textAlign: "center",
    },
    campDetailInvestContentBlock:
    {
        paddingTop: "20px"
    },
    campDetailContactSection:
    {
        "& h2": {
            textAlign: "left",
            fontSize: 28,
        },
        "& p": {
            fontSize: 17,
            paddingTop: "20px",
            color: "#2c2c2e",
        },
    },
    campDetailContactImg:
    {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        borderRadius:10,
        "@media(max-width: 767px)": {
            height: "auto",
        },
    },
    campDetailOurWorkSection:
    {
        "& h2": {
            textAlign: "right",
        },
        "& p": {
            textAlign: "right",
        }
    },
    termsTabContent:
    {
        "& h2": {
            textAlign: "center",
        },
        "& p": {
            textAlign: "center",
        }
    },
    termsTabContentBlock:
    {
        maxWidth: 1000,
    },
    
    termsTable:
    {
        marginTop: 20,
        "& .MuiGrid-item":
        {
            textAlign: "center",
            padding: 0,
            "& h5":
            {
                fontSize: 20,
                padding: 10,
                border: "1px solid #D8D8D8",
                "& a":
                {
                    color: "#393939",
                    textDecoration: "underline",
                    "&:hover":
                    {
                        color: "#00BC4A",
                    }
                }
            }
        }
    },
    termBlocks:
    {
        minWidth: 200,
        width:"100%",
        display:"flex",
        flexWrap:"wrap",
        alignItems:"center",
        "& .tbl-row": {
            width:"100%",
            display:"flex",
            flexWrap:"nowrap",
            border:"1px solid #D8D8D8",
        },
        "& span": {
            width:"50%",
            wordBreak:"break-word",
            fontSize:"18px",
            lineHeight:"20px",
            Height:"100%",
            boxSizing:"border-box",
            padding:"15px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            color:"#000000",
            "&:first-child": {
                borderRight:"1px solid #D8D8D8",
            },
            "& a": {
                color:"#000000",
                cursor:"pointer",
            }
        }
    },
    termtableBlock:
    {
        display: "flex",
        marginTop: 25,
        "& h5":
        {
            padding: 10,
            textAlign: "center",
            border: "1px solid #D8D8D8",
        }
    },
    docTermBlock:
    {
        paddingTop: "30px",
    },
    faqAccordionContainer:
    {
        "& h2":
        {
            marginBottom: "30px",
        },
        "& p":
        {
            textAlign: "left",
        }
    },
    faqAccordion: {
        boxShadow:"none",
        borderBottom:"1px solid #D8D8D8",
        "&::before" : {
            display:"none",
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: 0,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            marginBottom: 0,
        }
    },
    campDetailServicesSection:
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& h2": {
            fontSize: 28,
            fontFamily:"FuturaBT-Medium, sans-serif !important",
            color:"#333",
            letterSpacing:"0.5px",
           
        },
        "& .leadText": {
            fontSize: 17,
            paddingTop: "20px",
            color: "#2c2c2e",
        },
        "& .MuiBox-root:not(.services-box)": {
            maxWidth:"100%",
            "@media (max-width:600px)": {
                boxSizing:"border-box",
            },
        },
        "& .MuiBox-root.founder-main-box": {
            width:"100%",
        },
        "& .MuiBox-root.about-camp-box" : {
            width:"100% !important"
        }
    },
    campDetailCustomTab:
    {
        marginTop:"25px",
        marginBottom:"40px",
        borderRadius: 38,
        backgroundColor: "#edfff4",
        "& .Mui-selected":
        {
            backgroundColor: "#00BC4A",
            borderRadius: 38,
            "& .MuiTab-wrapper":
            {
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
            },
        },
        "& .MuiTab-textColorInherit":
        {
            opacity: 1,
            margin: "0 3px",
            "& .MuiTab-wrapper":
            {
                fontWeight: "bold",
                fontSize: 16,
                textTransform: "capitalize",
            },
            "&:hover":
            {
                backgroundColor: "#00BC4A",
                borderRadius: 38,
                "& .MuiTab-wrapper":
                {
                    color: "#fff",
                },
            }
        },
        "& .MuiTabs-indicator":
        {
            display: "none",
        },
        "& .MuiTabs-flexContainer":
        {
            padding: 7,
            "@media (max-width:767px)": {
                justifyContent:"space-between",
            }
        },
        "@media(max-width: 767px)": {
            width: "100%",
            "& .MuiTabs-scroller":
            {
                overflowX: "auto !important",
                "&::-webkit-scrollbar-thumb": {
                    width:"3px",
                    height:"3px",
                }
            },
            "& .MuiTab-root":
            {
                minHeight: 40,
                padding: "6px 17px",
            }
        },

    },
    founderImageBg:
    {
        marginTop: 50,
        position: "relative",
        zIndex: 1,
        background: "url(" + founderProfile + ")",
        backgroundRepeat: "no-repeat !important",
        backgroundSize: "cover !important",
        backgroundPosition: "center top !important",
        borderRadius:5,
        // paddingBottom: 50,
        width: "100%",
        height: "700px",
        "@media(max-width: 767px)": {
            height: "auto",
            padding:"10px 0"
        }
    },
    founderContent:
    {
        margin: "0 auto",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0 10px",
        width: "50%",
        height: "100%",
        "@media(max-width: 1024px)": {
            width: "100%",
        },
        "&::before": {
            position: "absolute",
            content: "' '",
            top: 0,
            left: 0,
            zIndex: 3,
            width: "50%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,.5)",
            "@media(max-width: 767px)": {
                width: "100%",
                padding: "0",
                boxSizing:"border-box",
            },
        },
        "& h5": {
            color: "#fff",
            zIndex: 4,
            position: "relative",
        },
        "& p": {
            zIndex: 4,
            position: "relative",
            color: "#fff",
            paddingTop: "10px",
            textAlign: "center",
            // width: "60%",
            fontWeight: "600",
            lineHeight: "25px",
        }
    },
    sliderTabContent:
    {
        "& .slider": {
            height: "65vmin",
            "@media(max-width: 1600px)": {
                height: "90vmin",
            }
        },
        "& .sliderBlock": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        "& .headlineText": {
            display: "flex",
            flexDirection: "column",
        },
        "& p": {
            lineHeight: "30px",
            fontSize: 19,
        },
        "& h2": {
            marginBottom: "50px",
        },
        "& h3": {
            margin: "10px 0",
            fontFamily: 'FuturaBT-Book, sans-serif',
            fontWeight: "400",
            fontSize: 20,
        },
        "& h5": {
            margin: 0,
            fontFamily: 'FuturaBT-Book, sans-serif',
            fontWeight: "400",
            fontSize: 18,
        },
        "& .slide__image": {
            margin: 0,
            height: 550,
            width: 400,
            borderRadius: 15,
        },
        "& .slide": {
            height: 550,
            width: 400,
        },
        "& .btn": {
            display: "none",
        },
        "& .slide__content": {
            height: 550,
            display: "flex",
            alignItems: "center",
            position: "absolute",
            zIndex: "3",
        },
        "& .slide__image-wrapper": {
            height: 530,
            width: 400,
            backgroundColor: "transparent",
            // zIndex: "1",
            borderRadius: 15,
            position: "relative",
            "&::before": {
                content: "' '",
                height: "99%",
                width: "95%",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: "2",
                borderRadius: 15,
                position: "absolute",
                left: 0,
                top: 0,
            }
        }
    },
    sliderTabContent2:
    {
        margin: "150px 0"
    },
    descriptionOurServices: {
        display: 'flex',
        justifyContent: "center",
        "&>p": {
            width: '50%',
            textAlign: 'center'
        }
    },
    mainContainet:{
        width:"100%" , height:"560" , objectFit: "fill" , borderRadius:5,
    
        "@media(max-width: 500px)": {
            height:"200px",
        },
    }

}
//@ts-ignore
export default withStyles(campaignDetailscreenStyle)(withRouter(CampaignDetailWebPage))
// Customizable Area End