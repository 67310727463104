import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Share } from "react-native";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  match?: any;
  location?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  copyStatus: boolean;
  referalCode: any;
  sharingMsg: string;
  boxPosition: any;
  sharingLink: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReferAndEarnController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProfileDetailsCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start 
    this.getProfileDetails=this.getProfileDetails.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      copyStatus:false,
      referalCode: '',
      sharingMsg: '',
      sharingLink: '',
      boxPosition: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
      this.getProfileDetails();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    
    console.log("@@@ MESSAGE EMAIL ACCOUNT REGISTRATION ===", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     
      if (this.getProfileDetailsCallId === apiRequestCallId) {
        this.setState({ referalCode: responseJson?.data?.attributes?.refer_code }, () => this.getReferralUrl());
      }
    }   
  }

  // Customizable Area Start   
  apiCallFunc = async (data: any) => {
    const { contentType, method, endPoint, token } = data;
    
    const header = new Headers();
    header.append("token", token);
    header.append("Content-Type", contentType);

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getProfileDetails = async () => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    this.getProfileDetailsCallId = await this.apiCallFunc({
      contentType: 'apication/json',
      method: 'GET',
      token: user.token,
      endPoint: `account_block/accounts/get_account`,
    });
  }

  getReferralUrl = () => {
    const currUrl:any = window.location.href.split("/");
    currUrl.pop();
    const currHost = currUrl.join("/");
    const fianlReferLink = `${currHost}/SocialMediaAccountRegistrationScreen?${this.state.referalCode}`;
    this.setState({ sharingLink: fianlReferLink });
  }

  copyReferLink = () => {
    navigator.clipboard.writeText(this.state.sharingLink);
    this.setState({ copyStatus: true });
  }

  handleClosePopover = () => {
    this.setState({ boxPosition: null });
  }
  
  shareReferCode = async (e:any) => {

    const message = `Hi there! You've been referred to Yuva Invest, a platform for smart investing. To receive exclusive benefits, sign up using the referral code "${this.state.referalCode}" shared with you. Don't forget to enter the code at Signup! Click here: ${this.state.sharingLink} to start investing today! - Yuva Invest Team`

    this.setState({ sharingMsg: message });

    if(window.innerWidth > 768) {
      this.setState({ boxPosition: e.currentTarget });
    }else {
      try {
        await Share.share({ message: message });
      }catch (error:any) {
        console.error(error.message);
      }
    }
  }
  // Customizable Area End

}
