Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getPortFollioApiEndPoint="bx_block_invest_raises/investments"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DashboardGuests";
exports.labelBodyText = "DashboardGuests Body";
exports.getWalltesApiendPoint="get_wallet";
exports.documentApiEndPoint="bx_block_invest_raises/get_leegality_document";
exports.documentApiRequestHeader = "KmzVdAB1AYGRMF1PgUQ7EO1tIz6Ex3kV";

exports.btnExampleTitle = "CLICK ME";

exports.companyData = [
  {
    name: "Comapny 1",
    invested_amount: 1000,
    invested_date: "12/20/2023",
    id: 1,
  },
  {
    name: "Comapny 2",
    invested_amount: 1000,
    invested_date: "12/20/2023",
    id: 2,
  },
];

exports.portfolioApiErrorResponse = {
  errors: "Something went wrong",
};

// Customizable Area End