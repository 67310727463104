import React from "react";
// Customizable Area Start
import { withRouter } from 'react-router';
import HeaderWeb from "../../landingpage/src/Header.web";
import { Box, Grid, Tab, Tabs, TextField, Button,CircularProgress } from "@material-ui/core";
import CheckoutController from "./CheckoutController.web";
import "../../Payments/assets/css/PaymentsWeb.web.css";
// Customizable Area End

class CheckoutWeb extends CheckoutController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 
// Customizable Area End
  render() {
  
    return (
      // Customizable Area Start
      <>
      <Box className="paymentpage" id="Main">
        <HeaderWeb />

        <Grid container className="payment-main-box">
          <Box className="page-title-wrapper">
            <span className="main-title">Invest In {this.state?.campingTitle}</span>
          </Box>
          <Box className="subtitle-wrapper"> 
            {/* <span className="main-title-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum text ever since the 1500s</span> */}
          </Box>

          <Grid container className="payment-details-block">
            <Box className="stapper">
              <Tabs value={0} TabIndicatorProps={{style:{display:"none"}}}>
                <Tab label="Step1" disabled className="stapper-tab" />
                <Tab label="Step2" disabled className="stapper-tab" />
              </Tabs>
            </Box>

            {this.state.count === 0 &&
            <>
              <div className="investment-form">
                <div className="input-head">Enter the Amount to be Invested</div>
                <TextField id="outlined-basic" variant="outlined" type="number" 
                placeholder="Enter Amount"
                value={this.state.investedAmount}
                onChange={(e) => this.handleInvestedAmountChange(e)}
                className="payment-textfield" />
                {this.state.amountValid && 
                  <span className="validation-msg">The minimum amount that can be invested is ₹5000</span>
                }
                {this.state.maxamount && 
                  <span className="validation-msg">The maximum amount that can be invested is ₹10 lakhs</span>
                }
                {this.state.isMultiplcable && 
                  <span className="validation-msg">The invested amount should be in multiples of ₹5000</span>
                }
              </div>
              <Button id="btntxtt" className="payment-Btn" onClick={this.handleContinueClick} >Continue</Button>
              </>
            }
            {this.state.count === 1 &&
            <>
              {this.state.isLoading&& <div className="loader" style={{height:100}}><CircularProgress color="primary" size={50}/></div>}
              <Grid className="payment-info-tbl step1" style={{opacity:this.state.isLoading ? 0.5 : 1}}>
                <div className="tbl-row">
                  <span className="key-span">Investment Amount</span>
                  <span className="value-span text-right">₹{this.state.investedAmount}</span>
                </div>
                <div className="tbl-row">
                  <span className="key-span" style={{ flexWrap: "wrap" }}>
                    Convenience Fee <p style={{width:"100%"}}>Processing fee ({this.state.feecal.proccessing_fee_p}%) +<br></br>Legal fee({this.state.feecal.legal_fee_p}%)</p>
                  </span> 
                  <span className="value-span text-right">₹{this.state.feecal.proccessing_fee + this.state.feecal.legal_fee}</span>
                </div>
                <div className="tbl-row">
                  <span className="key-span">GST ({this.state.feecal.gst_p}%)</span>
                  <span className="value-span text-right">₹{this.state.feecal.gst}</span>
                </div> 
                {this.state.checkboxFlag ?
                  <div className="tbl-row" id="term">
                    <span className="key-span">Wallet Amount</span>
                    <span className="value-span text-right">₹500.00</span>
                  </div> : "" 
                }
                <div className="tbl-row">
                  <span className="key-span">Total Payable Amount</span>
                  <span className="value-span text-right">₹{this.state.feecal.proccessing_fee + this.state.feecal.legal_fee + this.state.feecal.amount + this.state.feecal.gst}
                  </span>
                </div>
              </Grid>
              <Button className="payment-Btn" id="ptbtn" style={{opacity:this.state.isLoading ? 0.5 : 1}} onClick={this.handleContinueClick2} >Continue</Button>
            
              </>
            }
            
          </Grid>
        </Grid>
      </Box>
      </>
    );
  }
}

//@ts-ignore
export default withRouter(CheckoutWeb);