import React from "react";
// Customizable Area Start
import { Grid, TextField, Button, Popover } from '@material-ui/core';
import '../assets/css/ReferAndEarn.web.css';
import DashBoardHeaderWeb from "../../dashboard/src/DashBoardHeader.web";
import DashboardSidebarWeb from "../../dashboard/src/DashboardSidebar.web";
import { referIcon, step1Icon, step2Icon, uploadIcon } from "./assets";
import ReferAndEarnController, { Props } from "./ReferAndEarnController.web";
import {
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  LinkedinIcon,
  WhatsappIcon
} from 'react-share';
// Customizable Area End

export default class ReferAndEarn extends ReferAndEarnController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { boxPosition, sharingMsg } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid className="profile-page-main">
          <DashBoardHeaderWeb />
          <div className="profile-container-main">
            <DashboardSidebarWeb />
            <div className="profile-bg-main referrals">
              <div className="page-title">
                <img src={referIcon} width="30px" />
                <span>Refer & Earn</span>
              </div>
              <div className="referlink-section">
                <TextField
                  id="link-input"
                  className="link-input"
                  variant="outlined"
                  value={this.state.sharingLink}
                  disabled={true}
                />
                <Button id="copy-refercode" className="copy-btn" onClick={() => this.copyReferLink()}>
                  Copy Link
                </Button>
                {this.state.copyStatus && <span className="input-msg">Link Copied!</span>}
              </div>
              <div className="referrals-guild">
                <div className="heading">How it works</div>
                <div className="guild-steps">
                  <div className="step-box">
                    <img src={step1Icon} alt="step 1" width={"45px"} />
                    <span>Refer Your Friends to Yuva Invest</span>
                  </div>
                  <span className="seperater"></span>
                  <div className="step-box">
                    <img src={step2Icon} alt="step 1" width={"45px"} />
                    <span>Get ₹200/- wallet cash, on their first investments</span>
                  </div>
                </div>
              </div>
              <Button id="shareReferCode" className="invite-btn" onClick={(e) => this.shareReferCode(e)}>
                <img src={uploadIcon} width="20px" />
                Invite your friend now
              </Button>
            </div>
          </div>
        </Grid>
        {/* social share buttons for desktop */}
        <Popover 
          id="social-dropdown"
          className="social-popover"
          open={Boolean(boxPosition)}
          anchorEl={boxPosition}
          onClose={() => this.handleClosePopover()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <FacebookShareButton url={sharingMsg} className="social-button">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton url={sharingMsg} className="social-button">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={sharingMsg} className="social-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <EmailShareButton url={sharingMsg} className="social-button">
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Popover>
      </>
      // Customizable Area End
    );
  }
}
