import React from "react";
import PrivacyPolicyController, { Props } from "./PrivacyPolicyController";
// Customizable Area Start
import '../assets/css/PrivacyPolicyScreen.web.css';
import HeaderWeb from "../../landingpage/src/Header.web";
import FooterWeb from "../../landingpage/src/Footer.web";
// Customizable Area End
export default class PrivacyPolicyScreen extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="policy-page">
        <HeaderWeb />
        <div className="policy-wrapper">
          <div className="page-title">Privacy Policy</div>

          <p>This Privacy Policy (hereinafter as “Policy”) has been framed by strictly adhering to the Information Technology Act, 2000 (hereinafter as “Act”) and the Rules made there under (hereinafter as “Rules”) for dealing or collecting any information, reasonable security practices and procedures. By visiting the Yuva Invest platform, providing Your information or availing out any product/services, You expressly agree to abide and bound by the terms and conditions governing the Use, collection and retention of data as set forth in the Policy along with Your free consent to be governed by the laws of India and not limited only to the data protection and privacy laws. If You do not agree to this, please do not Use or access our platform.</p>

          <p>We appreciate the confidence which You have in Us and understand how crucial secured transactions and confidentiality are. This Policy governs the Use as to how this Website and its Users’ data will be collected, Used and shared or otherwise be processed through the Yuva Invest Website <a href="https://www.yuvainvest.com" target="_blank">www.yuvainvest.com</a> or <a href="www.yuvainvest.in" target="_blank">www.yuvainvest.in</a> (hereinafter as “Website” or “Platform”). It will help You to understand how Vaishalica Technologies Private Limited (the sole owner of this Website <a href="https://www.yuvainvest.com" target="_blank">https://www.yuvainvest.com</a> or <a href="www.yuvainvest.in" target="_blank">www.yuvainvest.in</a>) (“Website”, “We”, “Us”, “Owner”), and services being provided at this Website is dealt with relation to the Users data and privacy along with the other applicable laws.</p>

          <p>The owner reserves the right to update or change the Policy at any time. Hence, We advise You to visit the Website frequently for knowing any significant changes in the Privacy Policy. We will further alert You any significant changes or any notices by sending You an email if required by the law for the time being in force.</p>

          <div className="policy-topic">
            <span className="topic-head">Restricting the Collection of Your Personal Data</span>
            <p>While accessing our Yuva Invest platform, We do not collect any information from You. However, We do collect, keep, and process the information You give Us via email or by filling out forms on the Website. It is not only limited to personal information such as Your name, address, phone number, email address, information about the company You are acting for (if applicable) but can also include any personal information such as financial information such as a bank account, identity documentation, communication address, father's name, etc.</p>
            <p>We do not and will not sell or distribute any Users’ personal information to any third party entity. Your personal information will be used when We need to communicate with You regarding any happening at our Website. However, Users are responsible for ensuring the protection of login and password records of their accounts on our Website.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">What User Data We Collect</span>
            <p className="m-0">We may collect personal information and data which means and includes Your:</p>
            <ul className="inner-list">
              <li>E mail address</li>
              <li>Name</li>
              <li>Phone number</li>
              <li>Credit/debit card or other payment instrument details</li>
              <li>IP address</li>
              <li>Location</li>
              <li>Demographic Information</li>
              <li>Navigation patter at our Website</li>
              <li>Date of birth;</li>
              <li>Business name, address, email, phone number, and names of owners and management, if applicable;</li>
              <li>Balance sheets, profit and loss statements, bank account information, average bank balance, bank statement and other financial data, including tax returns, as applicable, in order to help verify your accreditation status or "Qualified Purchaser" status;</li>
              <li>Tax ID number or social security number, as applicable, citizenship status, and state or country of formation for investment entities, if applicable;</li>
              <li>Responses to further questions answered during the profile creation; and</li>
              <li>Further information in order to help validate your identity such as your driver’s license information or government issued identification number.</li>
              <li>If You send Us personal correspondence, such as emails or letters, or if other Users or third parties send Us correspondence about Your activities or postings on the Website, details of the organization on behalf of whom You are acting (if applicable), We may collect such information into a file specific to You.</li>
              <li>Other information as may deem necessary to Us from time to time for carrying out the business or transacting operations.</li>
              <li>We collect, store, and process such other information including but not limited to Your Authentication Token, communication made by You, information posted by You on the chat rooms, and any modifications made to the information provided by You.</li>
              <li>Device Data: information about your device, including the type of device, the operating system, application IDs, unique device identifiers, and crash data.</li>
              <li>Other Webform Data: information collected when you engage with us through our webforms, surveys and questionnaires, blogs, discussion forums, or live chat.</li>

            </ul>
            <p className="m-0">We also collect the information in different ways which means and includes:</p>
            <ul className="inner-list">
              <li>Information directly from You</li>
              <li>Information from You passively such as by Using Google Analytics, Google Webmaster, Google Search Console, Browser Cookies and others.</li>
              <li>Information from the third party such as third party social media sites and others.</li>
            </ul>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Our Uses of Personal Information</span>
            <p className="m-0">We use Your information to, but not limited to:</p>
            <ul className="inner-list">
              <li>Operate and provide you with the Services;</li>
              <li>Communicate with You and send You marketing communications;</li>
              <li>Your account secure and protect our Platform, Service, and other Users;</li>
              <li>Analyze Your preferences, interests and behavior in order to provide You with tailored content and the most relevant content and communications; and</li>
              <li>Enforce our Terms of Use, enforce our legal rights, comply with applicable law, and respond to government and legal requests.</li>
              <li>To determine demographics of platform usage.</li>
            </ul>
            <p className="m-0">We use investor/subscriber information to, but not limited to:</p>
            <ul className="inner-list">
              <li>Verify Your identity and status with respect to securities and other laws;</li>
              <li>Facilitate Your payment or any escrow of funds;</li>
              <li>Record Your investment with our third-party agent;</li>
              <li>Provide You with necessary tax reporting information; and</li>
              <li>For other purposes for which We provide specific notice at the time of collection of the information.</li>

            </ul>
            <p className="m-0">The act of providing Your Aadhaar is voluntary in nature, which will only be required for completing Your account opening procedure online and for digitally signing the membership agreements (while opening your account with Us) or any investment agreements (While making an investment in any of the Start-ups through our platform), if any & if required. Your Aadhaar may also be used as a proof of address as an alternative choice to drivers license or Passport copy, which is a voluntary decision to be made by You. We do not store any Aadhaar Information, and the masked Aadhaar is reflected in the User’s Profile, which is accessible only to the User, where only last four digits of the Aadhaar is shown, while the first eight digits are masked.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Safeguarding and Securing the Data</span>
            <p>We are committed to secure Your data and keep it confidential with reasonable physical, electronic and procedural safeguards and to protect it from any unauthorized access in consonance with the Act and the Rules written aforesaid. We offer the Use of a secure server for any of Your transactions or accessing of Your account on our Website. We have done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help Us safeguard all the information We collect online. Notwithstanding aforesaid, despite our efforts to maintain privacy and confidentiality of the information of User, We may not be able to protect or prevent the unauthorized access or Use, software failure, traffic or fault, or other factors that may expose the User to risk regarding the Use of this platform. You agree and understand that the Internet is not a secure source and therefore, we cannot guarantee the protection of such User Information.</p>
            <p>To protect Your information, We maintain appropriate physical, technological, and procedural measures. However, by Using our platform Yuva Invest and our Website, the Users accept the inherent security implications of data transmission over the internet and World Wide Web which cannot be called as guaranteed and completely secure, and therefore, there would always remain certain inherent risks regarding the Use of this platform.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Cookie Information</span>
            <p>Once you agree to allow our Platform to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and Platforms you visit).
              <br />
              The data we collect by using cookies is used to customize our Platform to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.
              <br />
              Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.
              <br />
              If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).
            </p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Third party’s Link</span>
            <p>Our Website may contain the links or provide links to other Websites or application that may collect personal information about You. We shall not be liable or responsible for any breach of this Policy by such Websites or privacy practices or the contents of those linked Websites and for any loss incurred to You after visiting those Websites. This Policy does not apply to the privacy practices of those Websites. You undertake to read and understand the Policy and also the Policy of such third-party Websites.</p>
            <p>The User Information provided by You may be Used by Us and provided to third party Websites, affiliates, consultants, employees in order to manage, collect, store, process the User Information in order to improve the quality, design, functionality, and content of the Website and to any governmental authority under applicable law.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Sharing of information with third-parties</span>
            <p className="m-0">We shall share the information with the third-parties who perform services on our behalf such as those who manage our online registration process, payment processors or transactional message processors. Some of them may be located outside the territory of India and they may Use the information as described in their Privacy Policies. We shall share information with our business partners who may be:</p>
            <ul className="inner-list">
              <li>An individual</li>
              <li>A HUF (Hindu Undivided Family)</li>
              <li>A company, incorporated in India or under the laws of a country outside India</li>
              <li>A firm</li>
              <li>An association of persons or body of individuals, whether incorporated or not</li>
              <li>Every artificial juridical person</li>
            </ul>
            <p>
              We may share information if We think We have to in order to comply with the law or to protect ourselves.<br /><br />
              We shall share information to respond to a court order or subpoena.<br /><br />
              We may also share if the government agency or investigatory authority requests.<br /><br />
              We may share information when We are investigating any potential fraud.<br /><br />
              We may share information with any successor to all or part of our business and if required, list of transactions with our customers in case our company is sold, any winding up proceeding is commenced or liquidating, or closing as per the prevailing laws in India.<br /><br />
              We may also share those information for reasons not described in this Policy. We will let You know before We do this.
            </p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Your rights</span>
            <p>We take every reasonable step to ensure that any of Your personal information that We process is accurate and up to date and if inaccurate in case be rectified or erased. You may access, correct and update Your personal information directly through the functionalities provided on the Website. You can also write Us at the contact information provided to assist You with these requests or any of Your grievances.</p>
            <p>You are not legally bound to provide Us with all the information. Without prejudice, You have an option to withdraw Your consent by writing Us an email. We will verify such requests before acting upon Your request. However, the withdrawal shall be in strict accordance with the terms of this Policy, related terms and the applicable laws for the time being in force. If You revoke the consent You have granted Us under this Policy, it may be difficult for You to access the Platform or We may not be able to provide You with the services which We deem You need.</p>
            <p>You affirm that, to the best of Your knowledge, the Personal Information and Other Information You provide is true and accurate. You acknowledge and accept that We will not be held responsible for the accuracy of the Personal Information and Other Information that You give.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Your consent</span>
            <p>You assent to the collection, Use, storage, disclosure, and other processing of Your information (including sensitive personal information) on the Platform in accordance with this Policy by Using our Platform or by supplying Your information. You warrant that You have the right to do so and that You have given Us permission to Use any personal information about other persons that You reveal to Us in conformity with this Policy. With Your consent, We may have access to Your SMS, instant messages, contacts in Your directory, location, camera, and device information. We may also request to provide Your PAN, GST Number, any Government issued ID cards/numbers and KYC details for carrying out the business transactions. You undertake that Your access to these products/services may be affected in the event consent is not provided to Us.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Payment Details</span>
            <p>All payment shall be made through BillDesk (IndiaIdeas.com) at our platform, and in respect to any credit card, debit card or other payment processing details provided by You to BillDesk. BillDesk shall be liable to secure the confidential details; kindly check <a href="https://www.billdesk.com/web/security-and-privacy/#privacy" target="_blank">https://www.billdesk.com/web/security-and-privacy/#privacy</a>. We shall not be held liable for any loss nor do We collect or store any of the confidential information with respect to Your payment details.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Grievance Redressal Mechanism</span>
            <p>In order to address any of Your grievances or discrepancies of the information displayed on the Website, the Website shall designate a Grievance Redressal Officer on receipt of any grievance. We would try to resolve the grievances as early as possible. However, it may take up to thirty days to get the same resolved.
              <br />
              <span>Name- Manish Riju</span>
              <br />
              <span>Contact details- <a href="mailto: grievanceofficer@yuvainvest.com" target="_blank">grievanceofficer@yuvainvest.com</a></span>
            </p>
            <p>You can also mail Us for any questions regarding Policy or privacy concerns at <a href="mailto:hello@yuvainvest.com">hello@yuvainvest.com</a>.</p>
          </div>

          <div className="policy-topic">
            <span className="topic-head">Updates to the Policy</span>
            <p>This policy was last updated on 14/11/2023. However, it may change from time to time as may deem necessary. Please check the Website periodically for updates.</p>
          </div>
        </div>
        <FooterWeb />
      </div>
      // Customizable Area End
    );
  }
}
