import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
   // Customizable Area Start
import fileDownload from 'js-file-download';
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string; 
  // Customizable Area Start
  classes?: any;
  history?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  value: any,
  logos: any,
  liveCampaigns: any,
  upcomingCampaigns: any,
  campaignDetail: any,
  videoId: any,
  accordianExpand: any,
  toolTip: boolean,
  team: any,
  drawerStatus?:boolean
  userToken:any,
  askEkycDialog:boolean,
  askSignupDialog:boolean,
  upcomingFlag:boolean,
  liveFlag:boolean,
  services:any,
  aboutcamp:any,
  userInfo: any,
  campaignStatus: string;
  urlID: any;
  currUrl: any;
  sectionOneStatus: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLogosCallId:string="";
  getTeamCallId:string="";
  getLiveCampaignCallId:string="";
  getUpcomingCampaignCallId:string="";
  getCampaignDetailCallId :string="";
  getProfileDetailsCallId: any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    this.getLogos = this.getLogos.bind(this);
    this.getLiveCampaigns = this.getLiveCampaigns.bind(this);
    this.getUpcomingCampaigns = this.getUpcomingCampaigns.bind(this);
    this.getCampaignDetail = this.getCampaignDetail.bind(this);
    this. getTeam = this. getTeam.bind(this);
    this.downloadFile= this.downloadFile.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    this.state = {
      value: 0,
      logos: [],
      liveCampaigns: [],
      upcomingCampaigns: [],
      campaignDetail: {},
      videoId: "",
      accordianExpand: "",
      toolTip: false,
      team: [],
      drawerStatus: false,
      userToken: JSON.parse(localStorage.getItem("token") || "{}").token,
      askEkycDialog:false,
      askSignupDialog:false,
      upcomingFlag:true,
      liveFlag:true,
      services:[],
      aboutcamp:[],
      userInfo: [],
      campaignStatus:"",
      urlID: "",
      currUrl: window.location.pathname,
      sectionOneStatus: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (responseJson.errors && responseJson.errors.length > 0) {
        if (responseJson.errors[0].token === "Token has Expired") {
            this.props.history.push("/");
            localStorage.clear()
            return;
        }
      }else{  
        this.firstHalfConditions(responseJson , apiRequestCallId);
        this.secondHalfConditions(responseJson , apiRequestCallId);
      }
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  firstHalfConditions(responseJson: any, apiRequestCallId : any){
    if(this.getLogosCallId === apiRequestCallId ){
      this.setState({
        logos: responseJson?.data?.data,
      })
  } 
  if (this.getProfileDetailsCallId === apiRequestCallId) {
    if(responseJson?.data ){
    localStorage.setItem('myuser',JSON.stringify(responseJson?.data));
    localStorage.setItem('userVerified', responseJson?.data?.attributes.is_ekyc_verified);
    this.setState({userInfo: responseJson?.data?.attributes}); 
  return;
  }
  
  if(responseJson?.message && responseJson?.message.includes('Account not found')){
      localStorage.clear();
      window.location.href = "/";
    }
  }
 if(this.getTeamCallId === apiRequestCallId){
    this.setState({
      team: responseJson?.data,
    })
  }
  if(this.getLiveCampaignCallId === apiRequestCallId ){
    this.setState({
      liveCampaigns: responseJson?.data,
    })
    if(responseJson?.data?.length === 0){
      this.setState({
        liveFlag:false
      })
    }
  }
  }
  secondHalfConditions(responseJson: any, apiRequestCallId : any){
    if(this.getUpcomingCampaignCallId === apiRequestCallId){
      this.setState({
        upcomingCampaigns: responseJson?.data,
      })
      if(responseJson?.data.length === 0){
        this.setState({
          upcomingFlag:false
        })
      }
    }
    if(this.getCampaignDetailCallId === apiRequestCallId){
      if(responseJson?.data  && !responseJson.error  && !responseJson.errors){
        this.setState({
          campaignDetail:responseJson.data, 
          services:responseJson.data.attributes?.sub_sections.sub_section_two.data,
          aboutcamp:responseJson.data.attributes?.sub_sections.sub_section_one.data,
          campaignStatus: responseJson.data?.attributes?.status,         
        }, () => {
          const {sub_section_three} = this.state.campaignDetail?.attributes?.sub_sections
          if(this.state.services.length === 0 && this.state.aboutcamp.length === 0 && !sub_section_three.data) {
            this.setState({ value: this.state.value+1 , sectionOneStatus : true});
          }
        });
      }
    }
  }

  downloadFile = (url:any) => {
    fetch(url) 
  .then(async response => {
    const contentType = response.headers.get('content-type')
    const blob = await response.blob()
    //@ts-ignore
    const file = new File([blob], "abcd", { contentType })
    console.log(file , "####file")
    //@ts-ignore
    fileDownload(file, `download.${contentType.split("/")[1]}` );
  })
	}
    getLogos = async () => {
      this.getLogosCallId = await this.apiCall({
        contentType:"application/json",
      method: "GET",
      endPoint: `account_block/logos`,
    });
  }
    getTeam = async () => {
      this.getTeamCallId = await this.apiCall({
        contentType:"application/json",
      method: "GET",
      endPoint: `bx_block_admin/teams`,
    });
  }
  getLiveCampaigns = async (stat:any) => {
    this.getLiveCampaignCallId = await this.apiCall({
      contentType:"application/json",
      method: "GET",
      endPoint: `bx_block_landingpage3/campaigns/live_campaigns/?home=${stat}`,
    });
  }
  getUpcomingCampaigns = async () => {
    this.getUpcomingCampaignCallId = await this.apiCall({
      contentType:"application/json",
      method: "GET",
      endPoint: `bx_block_landingpage3/campaigns/upcoming_campaigns`,
    });
  }
  getCampaignDetail = async (id:any) => {
    this.getCampaignDetailCallId = await this.apiCall({
      contentType:"application/json",
      method: "GET", 
      endPoint: `bx_block_landingpage3/campaigns/show?id=${id}`,
    });
  }
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      // token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleChange(event: any, newValue: any) 
  {
    this.setState({value: newValue})
  }
  handleTooltipOpen() {
    this.setState({ toolTip: !this.state.toolTip });
  }
  handleTooltipClose() {
    this.setState({ toolTip: false });
  }
  handleLogOut() {
    localStorage.clear();
    window.location.href = "/";
  }
  handleAskEkycDialog = () => {
    this.setState({ askEkycDialog: false });
  }
  handleAskSignupDialog = () => {
    this.setState({ askSignupDialog: false });
  }

  getProfileDetails =async () => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");

    const header = {
      "Content-Type" : "application/json",
      "token" : user.token
    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailsCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/get_account`
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area End
}