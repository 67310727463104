import React from "react";

import {
  Container,
  Box,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl, 
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

import EkycVerificationController, {
  Props,
  configJSON,
} from "./EkycVerificationController.web";
import { withRouter } from "react-router-dom";
import "../assets/css/EkycVerification.web.css";
import { backIcon } from "./assets";
import Loader from "../../../components/src/Loader";
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import { editIcon } from "../../mobile-account-login/src/assets";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";

export class EkycVerifications extends EkycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    
    const { loading } = this.state;
    
    return (
      <div>
        <Loader loading={loading} />
        {/* Customizable Area Start */}
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

        <Container className="ekyc-container" id="Main">
          <div className="page-title">{configJSON.labelTitleText}</div>

          <div className="stpper-wrapper">
            <div className="step-box active">
              <span className="step-num">1</span>
              <span className="step-info">Step 1</span>
            </div>
            <div className="step-box">
              <span className="step-num">2</span>
              <span className="step-info">Step 2</span>
            </div>
            <div className="step-box">
              <span className="step-num">3</span>
              <span className="step-info">Step 3</span>
            </div>
          </div>

          <Box className="ekyc-form-box">
            <div className="ekyc-field">
              <label className="input-label">Pan Number</label>
              <FormControl
                className="ekyc-form-control"
                variant="outlined"
              >
                <OutlinedInput
                 data-test-id="panNumberInput"
                 
                  type="text"
                  name="panNumber"
                   value={this.state.panNumber}
                   onChange={(e) => this.setPanNumber(e)}
                  // onChange={(e) =>
                  //   this.handleUserDataChange(e.target.name, e.target.value)
                  // }
                  placeholder="Enter your pan number"
                  id="inputpan"
                  endAdornment={ this.state.panName && 
                    <InputAdornment
                      position="end"
                      className="input-addon"
                    >
                      <IconButton
                        data-test-id="setPanName"
                        onClick={() => this.setState({panName: ''})}
                        edge="end"
                      >
                        <img src={editIcon} height={16} width={16} /> &nbsp;
                        <span
                          style={{
                            fontFamily: "FuturaBT-Book",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: 16,
                            color: "#939393"
                          }}
                        >
                          Edit
                        </span>
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {this.state.panDuplicationError && <div className="validation-msg">{this.state.panDuplicationError}</div>}
              {this.state.validPan ? '':( <div className="validation-msg">Please enter valid PAN number</div>)}
             
            </div>
{this.state.panName ? (<div data-test-id="ekycDiv" className="ekyc-field">
              <label className="input-label">Name</label>
              <FormControl
                className="ekyc-form-control"
                variant="outlined"
              >
                <OutlinedInput
                  data-test-id="panNameInput"

                  id="inputname"
                  type="text"
                  name="panName"
                   value={this.state.panName}
                  onChange={(event) =>
                    this.handleUserDataChange(event)
                  }
                  placeholder="Name"
                  disabled
                />
              </FormControl>
              {/* <div className="validation-msg">Please recheck your PAN number or contact our team for further asstance</div> */}
            </div>) :''}
            
            <>
             
              {this.state.panName ? ( <button
              data-test-id="EkycVerificationStep2"
                className="form-button"
                onClick={() => this.props.history.push('/EkycVerificationStep2')}
                id="btntxt"
              >
                Next
              </button>):( <><button
                className="form-button"
                data-test-id="xyzButton"
                onClick={() =>{this.state.panNumber===""? this.setState({validPan:false}) : this.verifyPanDuplication(this.state.panNumber)}} 
                id="btntxtt"
              >
                Confirm
              </button> <span data-test-id="historpush" onClick={() => this.handleSkipBtnClick()} className="skipn">Skip For Now</span></>)}
             
            </>
          </Box>
        </Container>
        <LoginCommonFooterWeb/>
      </div>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withRouter(EkycVerifications);