// Customizable Area Start
import React from "react";
import { withRouter } from "react-router";
import { withStyles,makeStyles } from "@material-ui/core/styles";
//MUI COMPONENTS
import { Grid, Button, Link, Drawer, List, ListItem,Tooltip } from "@material-ui/core";
// Customizable Area Start
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  storeLogo,
  toggleIcon,
  backIcon
} from "./assets";
import "../assets/css/DashboardHeader.web.css";
import { loginImage, logoImg } from "../../landingpage/src/assets";
import DashboardController from "./DashboardController.web";
// Customizable Area End
class DashboardHeader extends DashboardController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  async componentDidMount(): Promise<void> {
    this.getProfileDetails();
    const token = JSON.parse(localStorage.getItem('token') || "");
    this.setState({ userToken: token });
  }
  // Customizable Area End
  render() {
    //@ts-ignore
    const { classes } = this.props;
    // Customizable Area Start
    const currURL = window.location.pathname;
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.white,
        fontSize: 24,
        right: 10,
      },
      tooltip: {
        backgroundColor: theme.palette.common.white,
        padding: 0,
        top: 15,
        margin: 0,
      },
    }));
    function BootstrapTooltip(props: any) {
      const classes = useStylesBootstrap();
      return <Tooltip arrow classes={classes} {...props} />;
    }
    // Customizable Area End    
    return (
      // Customizable Area Start
      <Grid container className="dashboard-header">
        <div className="logo-wrapper" onClick={() => this.props.history.push('/')}>
          <img src={storeLogo} alt="YuvaInvest" />
        </div>
        <div className="user-info">
          <img src={this.state.profileUrl ? this.state.profileUrl : loginImage} alt="" width={"40px"} height={"40px"} className="user-profile" />
          <div className="username">{this.state.userName !== '' ? this.state.userName : 'Username'}</div>
         
          <ClickAwayListener
                  data-test-id="clickList"
                  onClickAway={() => this.handleCloseTooltip()}
                >
                  <div>
                    <BootstrapTooltip
                      id="bootstarpTooltip2"
                      data-test-id="bootstarpTooltip2"
                      interactive={true}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={this.state.toolTip}
                      onClick={() => this.TooltipOpen()}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="bottom-end"
                      title={
                        <div
                          style={{
                            width: "122px",
                            height: "124px",
                            display: "flex",
                            paddingLeft: 16,
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            alignItems: "start",
                          }}
                        >
                          <span
                            style={DashboardHeaderScreenStyle.tooltipList}
                            onClick={() => this.handleNAvigation('/UserProfile')}
                            id="tooltipProfile"
                          >
                            My Profile
                          </span>
                          <span
                            id="dashboard"
                            style={DashboardHeaderScreenStyle.tooltipList}
                            onClick={()=>this.handleNAvigation('/DashboardGuests')}
                          >
                            Portfolio
                          </span>
                          <span
                            id="logout"
                            data-test-id="logout"
                            style={DashboardHeaderScreenStyle.tooltipList}
                            onClick={this.handleLogOutHeader}
                          >
                            Logout
                          </span>
                        </div>
                      }
                    >
                       <img src={backIcon} id="tooltips"  alt="" width={"15px"} className="arrow-img" />
                     
                    </BootstrapTooltip>
                  </div>
                </ClickAwayListener>
        </div>

        {/* mobile Header */}
        <div className="mobile-menu">
          <Button className="mobile-menu-btn" onClick={this.handleDrawerOpen}>
            <img src={toggleIcon} alt="" width={"30px"} />
          </Button>
          <Drawer
            className='drawer-container'
            variant="temporary"
            anchor="right"
            hideBackdrop={false}
            transitionDuration={500}
            open={this.state.drawerStatus}
            onClose={this.handleDrawerClose}
          >
            {this.state.userToken ? <>
              <div className="drawer-header">
                <div className="loggedin-user-info">
                  <img src={this.state.profileUrl ? this.state.profileUrl : loginImage} alt="" width={"45px"} height={"45px"} />
                  <div className="info">
                    <span>{this.state.userName !== '' ? this.state.userName : 'Username'}</span>
                    <small>{this.state.userEmail !== '' ? this.state.userEmail : "username@gmail.com"}</small>
                  </div>
                </div>
              </div>
              <List className="navlink-list">
                <ListItem button className="nav-link" selected={currURL === '/UserProfile'}>
                  <Link className="sidebar-link" href="/UserProfile">My Profile</Link>
                </ListItem>
                {}
                <ListItem button className="nav-link" selected={currURL === '/DashboardGuests'}>
                  <Link className="sidebar-link" href="/DashboardGuests">Portfolio</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/ReferAndEarn'}>
                  <Link className="sidebar-link" href="/ReferAndEarn">Refer & Earn</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/CampaignWebPage'}>
                  <Link className="sidebar-link" href="/CampaignWebPage">Campaigns</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/raise'}>
                  <Link className="sidebar-link" href="/raise">Raise</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/BlogPostsManagement'}>
                  <Link className="sidebar-link" href="/BlogPostsManagement">Blogs</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/InteractiveFaqs'}>
                  <Link className="sidebar-link" href="/InteractiveFaqs">Learn</Link>
                </ListItem>
                <ListItem button className="nav-link">
                  <Link className="sidebar-link" onClick={this.handleLogOutHeader}>Logout</Link>
                </ListItem>
              </List>
            </> : <>
              <div className="drawer-header">
                <img src={logoImg} alt="logoImg"/>
              </div>
              <List className="navlink-list">
                <ListItem button className="nav-link" selected={currURL === '/CampaignWebPage'}>
                  <Link className="sidebar-link" href="/CampaignWebPage">Campaigns</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/raise'}>
                  <Link className="sidebar-link" href="/raise">Raise</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/BlogPostsManagement'}>
                  <Link className="sidebar-link" href="/BlogPostsManagement">Blogs</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/InteractiveFaqs'}>
                  <Link className="sidebar-link" href="/InteractiveFaqs">Learn</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/SocialMediaAccountLoginScreen'}>
                  <Link className="sidebar-link" href="/SocialMediaAccountLoginScreen">Login</Link>
                </ListItem>
                <ListItem button className="nav-link" selected={currURL === '/SocialMediaAccountRegistrationScreen'}>
                  <Link className="sidebar-link" href="/SocialMediaAccountRegistrationScreen">Register</Link>
                </ListItem>
              </List>
            </>}
          </Drawer>
        </div>
      </Grid>
      // Customizable Area End
    );
  }
}
//HOME SCREEN CSS
// Customizable Area Start
const DashboardHeaderScreenStyle = {
  tooltipList:{
    fontFamily: "sans-serif",
    fontSize: 16,
    fontWeight: 400,
    color: "#353535",
    cursor: "pointer",
  }
};
// Customizable Area End
//@ts-ignore
export default withStyles(DashboardHeaderScreenStyle)(withRouter(DashboardHeader));
// Customizable Area End
