import { RouteComponentProps } from 'react-router';

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCallCommon } from '../../../components/src/ApiCallCommon.web';

// Customizable Area Start
const serverUrl = require('../../../framework/src/config');
// Customizable Area End
export const configJSON = require("./config");




export interface Props {
  navigation: any;
  id: string;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  match: RouteComponentProps<{ id: string }>['match'];
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  investedCompanies: [];
  totalInvestmentAmount: string;
  totalInvestedCompanies: string
  searchValue: string
  walletAmount: number
  noRecordsFound: boolean;
  currDocument: string;
  // Customizable Area End
}

interface SS {
  id: string | number | undefined;
  // Customizable Area Start
  // Customizable Area End
}

export default class
  extends BlockComponent<
    Props,
    S,
    SS
  > {
  // Customizable Area Start
  portfolioApiId: string = "";
  getWalletApicallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      walletAmount: 0,
      investedCompanies: [],
      totalInvestmentAmount: "0",
      totalInvestedCompanies: "0",
      searchValue: "",
      noRecordsFound: false,
      currDocument: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.portfolioApiId) {
        this.handlePortfolioApiResponse(responseJson, errorResponse);
      }
      if (apiRequestCallId === this.getWalletApicallID) {
        this.handleGetWalletApiResponse(responseJson, errorResponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getInvestedCompanies()
    this.getWallets()
  }
  handleSearchValue = (event: { target: { value: string } }) => {
    this.setState({ searchValue: event.target.value });
    if (event.target.value.length === 0) {
      this.getInvestedCompanies()
    }
  }
  clearSearch = () => {
    this.setState({ searchValue: "" }, () => {
      this.getInvestedCompanies()
    })

  }
  handleDownloadDoc = async (docId: any, companyName: string) => {
    const user = JSON.parse(localStorage.getItem("token") ?? "{}");
    console.log("@@__", );

    const documentLink = `${serverUrl.baseURL}/${configJSON.documentApiEndPoint}?documentId=${docId.toUpperCase()}&file_name=${companyName}&token=${user.token}`;

    window.open(documentLink, "_blank");
  }
  getInvestedCompanies = (event?: any) => {
    event?.preventDefault()
    const user = JSON.parse(localStorage.getItem("token") || "{}");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.portfolioApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPortFollioApiEndPoint}?search=${this.state.searchValue}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getWallets = () => {
    const user = JSON.parse(localStorage.getItem("token") || "{}");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": `${user.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWalletApicallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWalltesApiendPoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  handlePortfolioApiResponse = (responseJson: any, errorResponse: any) => {
    if (errorResponse) {
      console.log(errorResponse);
    }
    else if (responseJson && responseJson.investments) {
      this.setState({
        investedCompanies: responseJson.investments.data,
        totalInvestedCompanies: responseJson.total_start_up,
        totalInvestmentAmount: responseJson.total_invested_amount,
        noRecordsFound: false
      })

    } else if (responseJson && responseJson.message) {
      this.setState({ noRecordsFound: true })
    }


  }
  handleGetWalletApiResponse = (responseJson: any, errorResponse: any) => {
    if (errorResponse) {
      console.log(errorResponse);
    }
    else if (responseJson) {
      this.setState({
        walletAmount: responseJson.wallet_amount
      })

    }
  }
  // Customizable Area End
}
