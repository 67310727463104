import React from "react";
import UserProfileBlockControllerWeb, {
  Props
} from "./UserProfileBlockController.web";
// Customizable Area Start
import { withRouter } from "react-router";
import { Grid } from '@material-ui/core';
import DashBoardHeaderWeb from "../../dashboard/src/DashBoardHeader.web";
import DashboardSidebarWeb from "../../dashboard/src/DashboardSidebar.web";
import { editIcon } from "../../mobile-account-login/src/assets";
import '../assets/css/profileBio.web.css';
import { myprofileIcon, userProfile } from "../../dashboard/src/assets";
// Customizable Area End

export class UserProfileBlock extends UserProfileBlockControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getProfileDetails();
    this.getWalletsApiFunc()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      profile_image,
      is_ekyc_verified,
      pan,
      passport,
      adhaar,
      full_phone_number,
      bank_name,
      bank_account_holder_name,
      bank_account_number,
      bank_account_ifsc_code,
      created_at,
      full_name,
      email
    }=this.state.profileDetails;
    return (
      <Grid className="profile-page-main">
       
        <DashBoardHeaderWeb />
        <div className="profile-container-main">
          <DashboardSidebarWeb />

          <div className="profile-bg-main" id="Main">
            <div className="profile-header">
              <div className="profile-title"><img src={myprofileIcon} alt="" width={"30px"}/>&nbsp;My Profile</div>
              <button data-test-id="editImage" className="edit-btn" id="btnedit" onClick={() => window.location.href = "/UserProfileEdit"}>
                <img src={editIcon} alt="" width={"20px"} />
                <span>Edit</span>
              </button>
            </div>

            <div className="user-info-box">
              <div className="info-wrapper">
              <div className="profile-wrapper">
                  <img 
                    data-test-id="proImage" 
                    src={profile_image ? profile_image : this.state.user_image} 
                    alt="" 
                    width={"45px"} 
                    height={"45px"} 
                  />
                  <label htmlFor="user_profile">Edit</label>
                  <input 
                    data-test-id="userInput" 
                    type="file" 
                    accept="images/*" 
                    id="user_profile" 
                    className="user-profile-input" 
                    onChange={(event: any) => {
                     
                          this.custURL(event)
                      
                    }}
                  />
                </div>
                <div className="account-info">
                  <span className="data-label">Account Opening Date</span>
                  <span className="data-info">{created_at}</span>
                </div>
                <div className="kyc-info">
                  <span className="data-label">KYC</span>
                  {is_ekyc_verified ?(<span data-test-id="Verified" className="data-info verified-tag">Verified</span>):
                  (<span className="data-info unverified-tag" data-test-id="notVerified" onClick={this.handleKycBtn}>Not Verified</span>) }
                </div>
                <div className="wallet-info" data-test-id="wallet"  style={{cursor:'pointer'}} onClick={() =>  this.props.history.push("/wallet")}>
                  <span className="data-label">wallet</span>
                  <span className="data-info">{this.state.walletAmount ===undefined? 0 : this.state.walletAmount}</span>
                </div>
              </div>
            </div>

            <div className="info-card personal-info">
              <div className="card-header">
                <h3>Personal details</h3>
              </div>
              <div className="card-body">
                <div className="f-row">
                  <div className="info-cell">
                    <span className="data-label">Name</span>
                    <span data-test-id="fullname" className="data-info">{full_name}</span>
                  </div>
                  {is_ekyc_verified && 
                  <div className="info-cell">
                    <span className="data-label">PAN</span>
                    <span className="data-info Bword">{pan}</span>
                  </div> }
                  {is_ekyc_verified && 
                  <div className="info-cell">
                    <span className="data-label">Adhar Number</span>
                    <span className="data-info Bword">{adhaar? adhaar:'-'}</span>
                  </div>}
                  {is_ekyc_verified && 
                  <div className="info-cell">
                    <span className="data-label">Passport Number</span>
                    <span className="data-info Bword">{passport ? passport:'-'}</span>
                  </div>
                  }
                </div>
                <div className="f-row">
                  <div className="info-cell">
                    <span className="data-label">KYC</span>
                    {is_ekyc_verified ?(<span className="data-info verified-tag">Verified</span>):
                  (<span className="data-info unverified-tag" id="btnnon" onClick={this.handleKycBtn}>Not Verified</span>) }
                  </div>
                </div> 
              </div>
            </div>

            <div className="info-card contact-info">
              <div className="card-header">
                <h3>contact details</h3>
              </div>
              <div className="card-body">
                <div className="f-row">
                  <div className="info-cell">
                    <span className="data-label">Email ID</span>
                    <span className="data-info Bword">{email}</span>
                  </div>
                  <div className="info-cell">
                    <span className="data-label">Contact No.</span>
                    <span className="data-info Bword">{full_phone_number}</span>
                  </div>
                </div>
              </div>
            </div>
{this.state.profileDetails.is_ekyc_verified && 
            <div className="info-card bank-details">
              <div className="card-header">
                <h3>Bank details</h3>
              </div>
              <div className="card-body">
                <div className="f-row">
                  <div className="info-cell">
                    <span className="data-label">Bank Name</span>
                    <span className="data-info Bword">{bank_name}</span>
                  </div>
                  <div className="info-cell">
                    <span className="data-label">Account Holder Name</span>
                    <span className="data-info Bword">{bank_account_holder_name}</span>
                  </div>
                  <div className="info-cell">
                    <span className="data-label">Account No.</span>
                    <span className="data-info Bword">{bank_account_number}</span>
                  </div>
                  <div className="info-cell">
                    <span className="data-label">IFSC Code</span>
                    <span className="data-info Bword">{bank_account_ifsc_code}</span>
                  </div>
                </div>
              </div>
            </div>
  }
          </div>
        </div>
      </Grid>
    );
    // Customizable Area End
  }
 
}
// Customizable Area Start
//@ts-ignore
export default (withRouter(UserProfileBlock));
// Customizable Area End
