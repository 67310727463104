import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  OutlinedInput,
  Tooltip,
  Fade,
  withStyles
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withRouter } from "react-router";
import '../assets/css/DashboardGuestWeb.css';
import { clearIcon, dateIcon, graphImg, infoIcon, portfolioTitleIcon, searchIcon, totalInvestmentIcon, walletIcon } from "./assets";
import DashBoardHeaderWeb from "../../dashboard/src/DashBoardHeader.web";
import DashboardSidebarWeb from "../../dashboard/src/DashboardSidebar.web";
export const SearchComponent = ({
  searchValue,
  handleSearchValue,
  clearSearch
}: any) => {
  return (
    <>
      <OutlinedInput
        value={searchValue}
        id="searchinput"
        type="text"
        className="search-input"
        placeholder="Search"
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { handleSearchValue(event) }}
        startAdornment={
          <InputAdornment position="start">
            <img src={searchIcon} width="20px" />
          </InputAdornment>
        }
        endAdornment={
          
          <InputAdornment position="end">
            {searchValue && 
            <img style={{cursor:'pointer'}} data-test-id="crossImage" onClick={() => clearSearch()} src={clearIcon} width="20px" /> }
          </InputAdornment>
        }
      />
      <Button data-test-id="searchButton" type="submit" >search</Button>
    </>
  )
}

const NoDocumentsTooltip = withStyles({
  tooltip: {
    maxWidth:"310px",
    width:"100%",
    color: "#565656",
    backgroundColor: "#FFFFFF",
    fontSize: 14,
    lineHeight: 1.5,
    fontFamily: "futuraBT-Book, sans-serif",
    border: "1px solid rgba(0, 0, 0, 0.125)",
    boxShadow: "5px 5px 10px rgba(0, 0, 0 , 0.15)",
    borderRadius: 3,
    textAlign: "center",
    "& .MuiTooltip-arrow": {
      "&::before": {
        backgroundColor: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.125)",
        boxShadow: "5px 5px 10px rgba(0, 0, 0 , 0.15)",
      },
    }
  },
})(Tooltip);
// Customizable Area End

import DashboardGuestsController, {
  Props,
} from "./DashboardguestWebController.web";

class DashboardGuests extends DashboardGuestsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid className="dashboard-page">
          <DashBoardHeaderWeb />
          <div className="profile-container-main">
            <DashboardSidebarWeb />
            <div className="profile-bg-main dashboard-wrapper">
              <Grid container className="status-box-guest-row-guest">
                <Box className="status-box-guest">
                  <img src={totalInvestmentIcon} width="45px" />
                  <span>INR {this.state.totalInvestmentAmount}</span>
                  <small>Total Investment</small>
                </Box>
                <Box className="status-box-guest" style={{background:"white"}}>
                  <small style={{color:"#4d4747",fontWeight:"bold"}}>Startup Invested In</small>
                  <span style={{color:"black"}}>{this.state.totalInvestedCompanies}</span>
                    <img src={graphImg} className="graphimg" />
                </Box>
                <Box data-test-id="walletBox" className="status-box-guest" style={{cursor:'pointer'}} onClick={() =>  this.props.history.push("/wallet")}>
                  <img src={walletIcon} width="45px" />
                  <span>INR {this.state.walletAmount ===undefined ? 0 : this.state.walletAmount}</span>
                  <small>Wallet</small>
                </Box>
              </Grid>

              <Grid container className="dashboard-inner">
                <div className="page-title">
                  <img src={portfolioTitleIcon} width="30px" />
                  <span style={{ marginLeft: "10px" }}>My Portfolio</span>
                </div>

                
                <form onSubmit={(event) => this.getInvestedCompanies(event)} className="dashboard-search">
                  <SearchComponent
                    searchValue={this.state.searchValue}
                    handleSearchValue={this.handleSearchValue}
                    clearSearch={this.clearSearch}
                  />
                </form>

                <Grid container className="portfolio-cards-sections">
                  {this.state.noRecordsFound ? 
                  <div className="potfolio-no-reports-main"> <span className="portfolio-no-reports-span" >No search results found</span> </div>: <>
                  {this.state.investedCompanies.length > 0 && this.state.investedCompanies.map(({ attributes }: any, index) => (
                    <Box className="portfolio-card" key={attributes?.document_id}>
                      <div className="card-row">
                        <div className="date-row">
                          <span className="data-label">Company Name</span>
                          <div className="date">
                            <img src={dateIcon} width="18px" />{attributes.created_at}
                          </div>
                        </div>
                        <span className="label-info">{attributes.company_name}</span>
                      </div>
                      <div className="card-row">
                        <span className="data-label">invest Amount</span>
                        <span className="label-info">{attributes.invested_amount}</span>
                      </div>
                      <div className="card-row">
                        <span className="data-label">Documents</span>
                        {!attributes?.document_id ? 
                          <span className="no-document-msg">
                            No Documents
                            <NoDocumentsTooltip
                              arrow
                              TransitionComponent={Fade}
                              title="Campaign ongoing or funding goal not achieved."
                              enterTouchDelay={0}
                            >
                              <img src={infoIcon} alt="" height={18}/>  
                            </NoDocumentsTooltip>
                          </span> :
                          <button
                            className="document"
                            id="document-btn"
                            onClick={() => this.handleDownloadDoc(attributes.document_id, attributes.company_name)}
                          >
                            <span>PDF</span> 
                          </button>
                        }
                      </div>
                    </Box>
                  ))}</>}
              
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withRouter(DashboardGuests as any);
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
