import React from "react";
import { withRouter } from "react-router";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardActions,
  CardMedia,
  CardContent
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
//import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//import VisibilityOff from "@material-ui/icons/VisibilityOff";
//import Visibility from "@material-ui/icons/Visibility";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
//import { post_css} from "./assets";
import "../assets/Blogs.css"

// Customizable Area End

import BlogPostsManagementController, {
  Props,
} from "./BlogsPostsController.web";
import Spinner from "../../landingpage/src/Spinner.web";

class BlogPostDetails extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    await this.getBlogs();
    await this.getBlogsDetails();
    
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <HeaderWeb />
        <Container fixed className="blogdetails" id="Main">
          {!this.state.blogdetails.blog_image ? 
            (<div className="loader-wrapper"><Spinner /></div>) : 
            (<>
              <Box className="blogdetailshead">
                <h3>Our Blogs</h3>
                <h2>{this.state.blogdetails.title}</h2>
                <p>  {this.state.blogdetails.description} </p>
                <h5>{this.state.blogdetails?.first_name}  {this.state?.blogdetails.last_name} </h5>
                <h5>{this.state.blogdetails.created_at} </h5>
                <img src={this.state.blogdetails.blog_image}  />
              </Box>
              <Box>
                <div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div>
              </Box>
            </>)
          }            
        <hr/>
        </Container>
        <Container fixed className="bloglist">
        <h3 style={{textAlign:'center'}}>Similar Blog</h3>
          <Grid container spacing={3}>
            {this.state.bloglist?.length === 0 ? 
              (<div className="loader-wrapper"><Spinner /></div>) : 
              (<>
                {this.state?.bloglist?.map((item: any, index: any)=>{
                  if (index < 3) {
                    return (
                  <Grid item xs={12} sm={12} md={4}>
                    <Card >

                      <CardMedia
                        component="img"
                        height="220"
                        image={item.attributes.blog_image}
                        alt="green iguana"
                      />
                      <CardActions>
                        <h5>{item.attributes.first_name} {item.attributes.last_name}</h5>
                        <h5>{item.attributes.created_at}</h5>
                      </CardActions>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {item.attributes.title}
                        </Typography>
                        <Typography variant="body2" className="blog-discription">
                        {item.attributes.description}
                        </Typography>
                      </CardContent>

                      <CardActions>
                                    <Button id="readart" onClick={()=>{this.props.history.push(`/blogpostdetails/${item.id}`); this.getBlogsDetails()}} size="small" color="primary">
                          Read Article
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  );
                  }
                })}
              </>)}
          </Grid>
        </Container>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
//@ts-ignore
export default (withRouter(BlogPostDetails));
// Customizable Area End
