import React from "react";

// Customizable Area Start
import {
  SafeAreaView,
  Dimensions,
  PixelRatio,
  View,
  Text,
  FlatList,
  SectionList,
  StyleSheet,
  Button,
  TouchableOpacity,
  CheckBox,
  Switch,
  Platform,
  Image,
  TextInput,
  Picker,
  ActivityIndicator,
  Alert,
  ImageBackground,
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,
} from "react-native-responsive-dimensions";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import RadioForm, {
  RadioButton,
  RadioButtonInput,
} from "react-native-simple-radio-button";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";

//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import PaymentsController, {
  Props,
  configJSON,
} from "./PaymentsController";

export default class Payments extends PaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Dimensions.addEventListener("change", (e) => {
    //   MergeEngineUtilities.init(
    //     artBoardHeightOrg,
    //     artBoardWidthOrg,
    //     Dimensions.get("window").height,
    //     Dimensions.get("window").width
    //   );
    //   this.forceUpdate();
    // });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <ScrollView keyboardShouldPersistTaps="always" >
        <TouchableWithoutFeedback
         
        >
          <View>
       

            <Text
              testID="labelBody" //Merge Engine::From BDS
            
            >
              {" "}
              {/* UI Engine::From Sketch */}
               {/* UI Engine::From Sketch */}
            </Text>

            <Text testID="txtSaved">
              This is the reviced value:
              {this.state.txtSavedValue}{" "}
              {/* Merge Engine::From BDS - {...this.testIDValue} */}
            </Text>

            <View >
              <TextInput
                testID="txtInput" //Merge Engine::From BDS
                
                placeholder={configJSON.txtInputPlaceholder} //UI Engine::From Sketch
              
              />

              <TouchableOpacity
                testID={"btnShowHide"} //Merge Engine::From BDS
             
               
              >
              
              </TouchableOpacity>
            </View>

            <Button
              testID={"btnExample"} //Merge Engine::From BDS
              title={configJSON.btnExampleTitle} //UI Engine::From Sketch
              {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}
            />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
