import React from "react";
import { withRouter } from "react-router";
import {
  Container,
  Box,
  Card,
  Grid,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import DashboardGuestsWebController, {
  Props,
  configJSON,
  CompanieDataWithInvestType,
} from "./DashboardGuestsController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export class Portfolio extends DashboardGuestsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    const guestUserId = this.props.match.params.id ;
    this.getPortfolios(guestUserId);
  }
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />

        <Box sx={portfolioWebStyle.portfolioTopSpace}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {this.state.investedCompanies.map((values: CompanieDataWithInvestType ) => (
                <Grid key={values.id} item xs={12} sm={6} md={4} lg={4}>
                  <Card>
                    <Box sx={portfolioWebStyle.portfolioCardSpace}>
                      <Box sx={portfolioWebStyle.portfolioFlexBox1}>
                        <Box sx={portfolioWebStyle.portfolioCircleImage}></Box>
                        <Box sx={portfolioWebStyle.portfolioNameSpace}>
                          <Typography>{values.attributes.company_name}</Typography>
                        </Box>
                      </Box>
                      <Box sx={portfolioWebStyle.portfolioFlexBox}>
                        <Box>
                          <Typography>{configJSON.inputAmountLabel}</Typography>
                          <Typography variant="h5">
                            {values.attributes.invest_amount}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {configJSON.investedDateLabel}
                          </Typography>
                          <Typography variant="h5">
                            {values.attributes.date_of_invest}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const portfolioWebStyle = {
  portfolioTopSpace: {
    marginTop: "90px",
  },
  portfolioCardSpace: {
    padding: "20px",
  },
  portfolioNameSpace: {
    paddingLeft: "20px",
  },
  portfolioFlexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0px",
  },
  portfolioFlexBox1: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0px",
  },
  portfolioCircleImage: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    background: "red",
  },
  portfolioMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};

export default withRouter(Portfolio);
// Customizable Area End

