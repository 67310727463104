import React from "react";

import {
  Container,
  Box,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

import EkycVerificationController, {
  Props,
  configJSON,
} from "./EkycVerificationController.web";
import { withRouter } from "react-router-dom";
import "../assets/css/EkycVerification.web.css";
import { backIcon } from "./assets";
import Loader from "../../../components/src/Loader";
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import { editIcon } from "../../mobile-account-login/src/assets";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";
export const RenderDefaultForm = ({
  aadhaarNo,
  is_passportSelect,
  aadharvalid,
  handlePassportToggle,
  setAdharNo,
  adharDuplicationError,
  verifyAadhaarDuplication,
}: any) => {
  return (
    <Box className="ekyc-form-box step-two" id="renderdefaultid">
      <div className="ekyc-field">
        <label className="input-label">Enter Aadhar Number</label>
        <FormControl
          className="ekyc-form-control"
          variant="outlined"
        >
          <OutlinedInput
            data-test-id="inputaadhar"
            id="inputaadharone"
            type="text"
            name="panNumber"
            value={aadhaarNo}
            onChange={(e: any) => setAdharNo(e)}
            placeholder="1234 1234 1234 1234"
            disabled={is_passportSelect}
          />
        </FormControl>
        {adharDuplicationError && <div className="validation-msg">{adharDuplicationError}</div>}
        {!aadharvalid && <div data-test-id="validAdharDiv" className="validation-msg">Please enter valid Aadhar number</div>}
      </div>

      <div className="ekyc-field passport-switch">
        <label className="label_text" htmlFor="isPassportSelect">Verify your Passport, if you are out of india</label>
        <label className="switch-wrapper">
          <input
            data-test-id="isPassportSelect"
            type="checkbox"
            id="isPassportSelect"
            onChange={(e) => handlePassportToggle(e)}
          />
          <span className="switch"></span>
        </label>
      </div>

      <>
        <button
          data-test-id="confirmButton"
          className="form-button"
          onClick={() => verifyAadhaarDuplication(aadhaarNo)}
          id="confirmad"

        >
          Confirm
        </button>

      </>
    </Box >
  );
}
export const RenderAdharForm = ({
  aadhaarNo,
  handleUserDataChange,
  aadharvalid,
  otpDigit1,
  inputfocus,
  otpDigit2,
  otpDigit3,
  otpDigit4,
  otpDigit5,
  otpDigit6,
  resendotp,
  otpverifyEvent,
  validotp,
  otpsendverifyEvent,
  setAdharContinue,
  setOtpValue
}: any) => {
  return (
    <>
      <Box className="ekyc-form-box step-two" id="Main">
        <div className="ekyc-field">
          <label className="input-label">Enter Aadhar Number</label>
          <FormControl
            className="ekyc-form-control"
            variant="outlined"
          >
            <OutlinedInput
              data-test-id="panNumber"
              id="ip7"
              type="text"
              name="panNumber"
              value={aadhaarNo}
              onChange={(e) =>
                handleUserDataChange(e)
              }
              placeholder="1234 1234 1234 1234"
              endAdornment={
                <InputAdornment
                  position="end"
                  className="input-addon"
                >
                  <IconButton
                    data-test-id="adharContinue"
                    onClick={() => setAdharContinue()}
                    edge="end"
                  >
                    <img src={editIcon} height={16} width={16} /> &nbsp;
                    <span
                      style={{
                        fontFamily: "FuturaBT-Book",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#939393"
                      }}
                    >
                      Edit
                    </span>
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {!aadharvalid && <div data-test-id="validAdharDiv" className="validation-msg">Please enter valid Aadhar number</div>}

        </div>
        <div className="ekyc-field">
          <label className="input-label">OTP</label>
          <div className="otp-fieldset">
            <input
              data-test-id="inputotp1"
              id="ip1"
              autoFocus
              name="otp1"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit1}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(1, e)}
              tabIndex={1} maxLength={1} onKeyUp={e => inputfocus(e)}
            />
            <input
              data-test-id="inputotp2"
              id="ip2"
              name="otp2"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit2}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(2, e)}
              tabIndex={2} maxLength={1} onKeyUp={e => inputfocus(e)}

            />
            <input
              data-test-id="inputotp3"
              id="ip3"
              name="otp3"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit3}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(3, e)}
              tabIndex={3} maxLength={1} onKeyUp={e => inputfocus(e)}

            />
            <input
              data-test-id="inputotp4"
              id="ip4"
              name="otp4"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit4}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(4, e)}
              tabIndex={4} maxLength={1} onKeyUp={e => inputfocus(e)}
            />

            <input
              data-test-id="inputotp5"
              id="ip5"
              name="otp5"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit5}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(5, e)}
              tabIndex={5} maxLength={1} onKeyUp={e => inputfocus(e)}
            />

            <input
              data-test-id="inputotp6"
              id="ip6"
              name="otp6"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpDigit6}
              // onKeyPress={this.keyPressed}
              onChange={(e: any) => setOtpValue(6, e)}
              tabIndex={6} maxLength={1} onKeyUp={e => inputfocus(e)}
            />
          </div>
          <div className="input-addtional-info">
            {resendotp &&
              <div className="actions">
                <span>Didn't received OTP?</span>
                <button data-test-id="resendOtp" style={{ cursor: "pointer" }}
                  onClick={() => {
                    otpverifyEvent(aadhaarNo);
                  }}
                  id="btnresend" >Resend OTP</button>
              </div>
            }
            {!validotp && <div data-test-id="wrongOtpDiv" className="validation-msg">Entered OTP is wrong!</div>}
          </div>
        </div>

        <button
          data-test-id="confirmButton"
          className="form-button"
          onClick={() => otpsendverifyEvent()}
          id="adharotp"
        >
          Confirm
        </button>


      </Box>
      <span className="ekyc-otp-note">It may take few seconds to receive the OTP. Do not close the tab or refresh the page.</span>
    </>

    // Customizable Area End
  );
}
export const RenderPasspostForm = ({
  is_passportDataShow,
  is_passportSelect,
  aadharvalid,
  handlePassportToggle,
  passportNo,
  dobno,
  passportvalid,
  passportverifyEvent,
  passportdetails,
  history,
  setpassportvalid,
  setIs_passportDataShow,
  setDobno,
  setPassportNo,
  full_name,
  passport_number,
  date_of_application
}: any) => {
  return (

    <>
      {!is_passportDataShow ?
        (<>
          <Box className="ekyc-form-box step-two" id="Main">
            <div className="ekyc-field">
              <label className="input-label">Enter Aadhar Number</label>
              <FormControl
                className="ekyc-form-control"
                variant="outlined"
              >
                <OutlinedInput
                  data-test-id="panNumber"
                  id="pa1"
                  type="text"
                  name="panNumber"
                  //  value={this.state.aadhaarNo}
                  //  onChange={(e: any) => {this.setState({ aadhaarNo: e.target.value })}}
                  placeholder="1234 1234 1234 1234"
                  disabled={is_passportSelect}
                />
              </FormControl>
              {!aadharvalid && <div data-test-id="validAdharDiv" className="validation-msg">Please enter valid Aadhar number</div>}
            </div>

            <div className="ekyc-field passport-switch">
              <label className="label_text" htmlFor="isPassportSelect">Verify your Passport, if you are out of india</label>
              <label className="switch-wrapper">
                <input
                  data-test-id="isPassportSelect"
                  type="checkbox"
                  id="isPassportSelect"
                  checked
                  onChange={(e) => handlePassportToggle(e)}
                />
                <span className="switch"></span>
              </label>
            </div>
            <div className="ekyc-field" id="getpassportd">
              <label className="input-label">Passport File Number</label>
              <FormControl
                className="ekyc-form-control"
                variant="outlined"
                id="formcont"
              >
                <OutlinedInput
                  data-test-id="setPAssword"
                  id="pa2"
                  type="text"
                  name="Passport File Number"
                  value={passportNo}
                  onChange={(e: any) => setPassportNo(e)}

                  placeholder="22-0019604589"
                />
              </FormControl>
            </div>
            <div className="ekyc-field">
              <label className="input-label">Date of Birth</label>
              <FormControl
                className="ekyc-form-control"
                variant="outlined"
              >
                <OutlinedInput
                  data-test-id="dobno"
                  id="pa3"
                  type="date"
                  name="DOB"
                  value={dobno}
                  onChange={(e: any) => setDobno(e)}
                  placeholder="22-0019604589"
                />
              </FormControl>
            </div>
            {passportvalid ? <div data-test-id="validPassport" className="validation-msg">Please enter valid Passport number or D.O.B</div> : ""}
            <>
              <button
                className="form-button"
                data-test-id="confirmButton"
                id="btn2next"
                onClick={() => {
                  passportNo === "" || dobno === "" ? setpassportvalid() :
                    passportverifyEvent(passportNo, dobno)
                }}
              >
                Confirm
              </button>
            </>
          </Box>
        </>) :
        (<Box className="ekyc-form-box step-two" id="getdataapi">
          <div className="filled-back-info">
            <button data-test-id="backBtn" className="back-btn" id="btnback" onClick={() => setIs_passportDataShow()}>
              <img src={backIcon} alt="" width={"15px"} />&nbsp;Back
            </button>
            <div className="filled-data">
              <div className="info-box">
                <span>Full Name</span>
                <small>{full_name}</small>
              </div>
              <div className="info-box">
                <span>Passport Number</span>
                <small>{passport_number}</small>
              </div>
              <div className="info-box">
                <span>Application Date</span>
                <small>{date_of_application}</small>
              </div>
            </div>
          </div>
          <>
            <button
              data-test-id="btnconfpass"
              className="form-button"
              id="btnconfpass"
              onClick={() => history.push('/EkycVerificationStep3')}
            >
              Confirm
            </button>
          </>
        </Box>)
      }
    </>

    // Customizable Area End
  );
}
export class EkycVerificationStep2 extends EkycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePassportToggle = (e: any) => {
    if (e.target.checked) {
      this.setState({ is_passportSelect: true, aadharvalid: true , aadhaarNo: '' });
    } else {
      this.setState({ is_passportSelect: false, aadharvalid: true, passportdetails: '' });
    }
  }




  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    const { loading } = this.state;
    const {date_of_application,full_name,passport_number}=this.state.passportdetails
    return (
      <div>
        <Loader loading={loading} />
        {/* Customizable Area Start */}
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader} 
        />

        <Container className="ekyc-container" id="Main">
          <div className="page-title">{configJSON.labelTitleText}</div>

          <div className="stpper-wrapper">
            <div className="step-box">
              <span className="step-num">1</span>
              <span className="step-info">Step 1</span>
            </div>
            <div className="step-box active">
              <span className="step-num">2</span>
              <span className="step-info">Step 2</span>
            </div>
            <div className="step-box">
              <span className="step-num">3</span>
              <span className="step-info">Step 3</span>
            </div>
          </div>
          {!this.state.is_passportSelect && !this.state.is_adharContinue ? (<RenderDefaultForm
            aadhaarNo={this.state.aadhaarNo}
            is_passportSelect={this.state.is_passportSelect}
            aadharvalid={this.state.aadharvalid}
            handlePassportToggle={this.handlePassportToggle}
            otpverifyEvent={this.otpverifyEvent}
            setAdharNo={this.setAdharNo}
            adharDuplicationError={this.state.adharDuplicationError}
            verifyAadhaarDuplication={this.verifyAadhaarDuplication}
          />) : (<></>)}

          {this.state.is_adharContinue && !this.state.is_passportSelect ? (<RenderAdharForm
            aadhaarNo={this.state.aadhaarNo}
            handleUserDataChange={this.handleUserDataChange}
            aadharvalid={this.state.aadharvalid}
            otpDigit1={this.state.otpDigit1}
            inputfocus={this.inputfocus}
            otpDigit2={this.state.otpDigit2}
            otpDigit3={this.state.otpDigit3}
            otpDigit4={this.state.otpDigit4}
            otpDigit5={this.state.otpDigit5}
            otpDigit6={this.state.otpDigit6}
            resendotp={this.state.resendotp}
            otpverifyEvent={this.otpverifyEvent}
            validotp={this.state.validotp}
            otpsendverifyEvent={this.otpsendverifyEvent}
            setAdharContinue={this.setAdharContinue}
            setOtpValue={this.setOtpValue}
          />) : (<></>)}

          {this.state.is_passportSelect && !this.state.is_adharContinue ? (<RenderPasspostForm
            is_passportDataShow={this.state.is_passportDataShow}
            is_passportSelect={this.state.is_passportSelect}
            aadharvalid={this.state.aadharvalid}
            handlePassportToggle={this.handlePassportToggle}
            passportNo={this.state.passportNo}
            dobno={this.state.dobno}
            passportvalid={this.state.passportvalid}
            passportverifyEvent={this.passportverifyEvent}
            passportdetails={this.state.passportdetails}
            history={this.props.history}
            setpassportvalid={this.setpassportvalid}
            setIs_passportDataShow={this.setIs_passportDataShow}
            setDobno={this.setDobno}
            setPassportNo={this.setPassportNo}
            full_name={full_name}
            passport_number={passport_number}
            date_of_application={date_of_application}
            />) : (<></>)}

        </Container>
        <LoginCommonFooterWeb/>
      </div>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withRouter(EkycVerificationStep2);
