// Customizable Area Start
import React from "react";
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";

//MUI COMPONENTS
import { Box, Grid, Container, Button, Link, } from "@material-ui/core";

//RESOURCES
// const bannerBgImage = require("../assets/banner-bg.png");
// Customizable Area Start

import {
  logoImg,
  fbIcons,
  instagramIcons,
  twitterIcons,
  linkdinIcons,
  emailIcon,
  phoneIcon,
  locationIcon,
}
  from "../src/assets";
import LandingPageController from "./LandingPageController.web";
class Footer extends LandingPageController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    //@ts-ignore
    const { classes } = this.props;
     const user = JSON.parse(localStorage.getItem("token") || "{}");

    return (
      // Customizable Area Start
      
      <>
        {/* footer section */}
        <Box className={classes.footerSection} id="FooterMain">
          {/* <Box className={classes.hr}></Box> */}
          <Container fixed>
            <Grid container spacing={2} className={classes.footerTopRow}>
              <Grid item xs={12} sm={3} className={classes.footerLogoWrapper}>
                <img src={logoImg} alt="Logo Img" />
              </Grid>
              <Grid item xs={12} sm={9}>
                <p className={classes.FuturaBTBook + " footer_info " + classes.lead}  style={{ color:"#000000"}}>
                  Are you owner of a startup looking to raise funds or an individual
                  looking to subscribe in top notch startups then you are <br></br>at the right place.</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.footerBottomRow}>
              <Grid item xs={12} sm={3}>
                <Box className={classes.socialIconsRow}>
                  <Link href="https://www.facebook.com/profile.php?id=100089193859655" target="_blank">
                    <img src={fbIcons} className={classes.footerIcons} alt="instagram Icon" />
                  </Link>
                  <Link href="https://instagram.com/yuvainvest?igshid=YmMyMTA2M2Y=" target="_blank">
                    <img src={instagramIcons} className={classes.footerIcons} alt="instagram Icon" />
                  </Link>
                  <Link href="https://twitter.com/YuvaInvest" target="_blank">
                    <img src={twitterIcons} className={classes.footerIcons} alt="twitter Icon" width={18}/>
                  </Link>
                  <Link href="https://www.linkedin.com/company/85632556/admin/" target="_blank">
                    <img src={linkdinIcons} className={classes.footerIcons} alt="linkdin Icon" />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}> 
                <Grid container>
                  <Grid xs={12} sm={6} lg={4}>
                    <Box className={classes.footerContactBlock}>
                      <img src={emailIcon} className={classes.footerIcons} alt="fb Icon" />
                      <span className={classes.FuturaBTBook + " " + classes.h6}  style={{color:"#000"}}>hello@yuvainvest.com</span>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Box className={classes.footerContactBlock}>
                      <img src={phoneIcon} className={classes.footerIcons} alt="fb Icon" />
                      <span className={classes.FuturaBTBook + " " + classes.h6} id="contactNumber" style={{color:"#000"}}>9136578301</span>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Box className={classes.hr}></Box>
          <Container fixed>
            <Grid container spacing={2} className={classes.footerLinksSection}>
              <Grid item xs={12} sm={6}>
                <p className={classes.FuturaBTBook + " footer-dis " + classes.lead}>
                  This website (yuvainvest.com or yuvainvest.in) is owned and operated by Vaishalica Technologies Pvt Ltd. We are neither a stock exchange nor we foresee to be recognized as a stock exchange by Securities and Exchange Board of India (SEBI) under the Securities Contract Regulation Act, 1956. We are not authorized by the SEBI to solicit investments. Vaishalica Technologies Pvt Ltd is not liable for any financial or any other loss incurred to the any user of the website on the basis of the content/ materials provided at this website. The contracts and agreements executed here are not traded on any regulated exchange, and are illiquid. This website and its contents are updated on a regular basis.</p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2} style={{justifyContent:"center"}}>
                  <Grid item xs={6} sm={5} className={classes.footerLinkBlock}>
                    <p className={classes.lead} style={{fontFamily:"FuturaBT-Medium" , fontWeight:400 , color:"#000000"}}>
                      Discover
                    </p>
                    <Box className={classes.footerLinksRow}>
                      <Link style={{ cursor: "pointer", color:"#000000" }} id="campaignLink" onClick={() => { this.props.history.location.pathname != "/CampaignWebPage" && this.props.history.push("/CampaignWebPage")}}>Campaigns</Link>
                      <Link href="/charter" style={{ fontFamily: "FuturaBT-Book", color:"#000000" }}>Charter</Link>
                      <Link style={{ cursor: "pointer", fontFamily: "FuturaBT-Book", color:"#000000" }}
                      id="raiseLink"
                        onClick={() => {
                          this.props.history.location.pathname != "/raise"
                            ? this.props.history.push("raise")
                            : "";
                        }}>Raise</Link>
                      <Link href="/InteractiveFaqs" style={{ fontFamily: "FuturaBT-Book" ,  color:"#000000"}}>Learn</Link>
                      <Link href="/BlogPostsManagement" style={{ fontFamily: "FuturaBT-Book" ,  color:"#000000"}}>Blog</Link>
                      {user.token && <Link id="linkRef" href="/ReferAndEarn" style={{ fontFamily: "FuturaBT-Book", color:"#000000" }}>Refer & Earn</Link>}
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={5} className={classes.footerLinkBlock}>
                    <p className={" " + classes.lead } style={{fontFamily:"FuturaBT-Medium" , fontWeight:400 , color:"#000000"}}>
                      Quick Links
                    </p>
                    <Box className={classes.footerLinksRow}>
                    {!user.token ? (
                      <>
                      <Link id="routerLoginSection" onClick={() => { this.props.history.location.pathname != "/SocialMediaAccountLoginScreen" && this.props.history.push("/SocialMediaAccountLoginScreen") }} style={{ cursor: "pointer", fontFamily: "FuturaBT-Book" , color:"#000000"}}>Login</Link>
                      <Link id="routerReg" onClick={() => { this.props.history.location.pathname != "/SocialMediaAccountRegistrationScreen" && this.props.history.push("/SocialMediaAccountRegistrationScreen") }} style={{ cursor: "pointer", fontFamily: "FuturaBT-Book" , color:"#000000"}}>Register</Link>
                      </> ):''}
                      {/* <Link href="#" style={{ fontFamily: "FuturaBT-Book" }}>Privacy Poliy</Link> */}
                      <Link href="/TermsConditions" style={{ fontFamily: "FuturaBT-Book", color:"#000000" }}>Terms & Conditions</Link>
                      <Link href="/PrivacyPolicies" style={{ fontFamily: "FuturaBT-Book", color:"#000000" }}>Privacy Policy</Link>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.footerCopyRight}>
          <p className={ classes.lead} style={{fontFamily:"FuturaBT-Book" , fontWeight:600, color:"#000000"}}>
            Vaishalica Technologies Private Limited,© 2022 All rights reserved
          </p>
        </Box>
      </>
      // Customizable Area End
    );
  }
};
// Customizable Area Start
//FOOTER CSS
const FooterStyle = {
  hr: {
    borderTop: "1px solid #EFEFEF",
    width: "100%",
    margin: "30px 0",
  },
  fonts:
  {
    fontFamily: 'FuturaBT-Book, sans-serif',
    fontWeight: "400",
  },
  boldTitle:
  {
    fontWeight: "600",
  },
  h2:
  {
    color: "#474747",
    fontSize: 30,
    margin: 0,
    "@media(max-width: 1024px)": {
      fontSize: 22,
    },
  },
  h3:
  {
    color: "#414141",
    fontSize: 20,
  },
  h5:
  {
    color: "#393939",
    fontSize: 24,
    lineHeight: "30px",
    margin: 0,
  },
  lead:
  {
    color: "#6D6D6D",
    fontSize: 18,
    lineHeight: "28px",
    "@media (max-width: 1279px)": {
      "&.footer_info": {
        textAlign: "justify",
        "& br": {
          display: "none"
        }
      }
    }
  },
  h6:
  {
    color: "#474747",
    fontSize: 18,
    margin: 0,
    paddingLeft: "10px",
  },
  FuturaBTBook: {
    fontFamily: "FuturaBT-Book",
  },
  FuturaBTMedium: {
    fontFamily: "FuturaBT-Medium",
  },
  FuturaMedium: {
    fontFamily: "Futura-Medium",
  },
  FuturaBold: {
    fontFamily: "Futura-Bold",
  },
  footerSection: {
    borderTop: "1px solid rgba(151,151,151,0.44);",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    padding: "30px 0",
    marginTop: "60px",
    "& p":
    {
      fontSize: 16,
    },
  },
  footerIcons:
  {
    marginRight: 20,
    "@media(max-width: 768px)": {
      marginRight: 15,
      marginBottom:8,
    },

  },
  footerContactBlock: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      margin: "5px 0",
    },
    "& h6":
    {
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: "25px",
      "@media(max-width: 768px)": {
        fontSize: 14,
      },
    },
    "& img": {
      margin: 0,
    }
  },
  footerAddBlock: {
    alignItems: "flex-start",
  },
  footerLinkBlock:
  {
    "& p":
    {
      paddingLeft: 0,
      fontSize: 20,
      lineHeight:"22px",
      color: "#373737",
    },
  },
  footerLinksRow:
  {
    display: "flex",
    flexDirection: "column",
    "& a":
    {
      fontSize: 16,
      color: "#373737",
      fontFamily: 'FuturaBT-Book, sans-serif',
      fontWeight: "400",
      marginBottom: "10px",
      "&:hover":
      {
        // color: "#00BC4A",
        // textDecoration: "none",
        // fontWeight: "600",
      }
    }
  },
  footerCopyRight:
  {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #EFEFEF",
    padding: 15,
    "& p":
    {
      fontSize: 15,
      color: "#373737",
      margin: 0,
    }
  },
  socialIconsRow: {
    margin: 0,
  }
  ,footerLinksSection: {
    "& .footer-dis": {
      paddingRight:"30px",
      fontSize:16,
      lineHeight:"24px",
      color:"#000000",
      textAlign:"justify",
      "@media (max-width:600px)": {
        paddingRight: 0,
      }
    }
  },
  footerBottomRow: {
      margin: 0,
  },
  footerTopRow: {
    margin: 0,
    "@media (max-width: 599px)": {
      width: "100%",
      "& .MuiGrid-item": {
        paddingLeft: 0,
        paddingRight: 0,
      }
    },
  },
  footerLogoWrapper: {
    display: "flex",
    alignItems: "center",
  }
}
// Customizable Area End
//@ts-ignore
export default withStyles(FooterStyle)(withRouter(Footer))
// Customizable Area End
