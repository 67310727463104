import React, { Component } from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";

import { Box, Container, Button, Link } from "@material-ui/core";

import { logoImg, loginImage } from "./assets";
// Customizable Area End
type Props = {
  onRegisterClick: () => void;
  onLoginPress: () => void;
  // Customizable Area Start
  // Customizable Area End
};

export class LoginCommonHeaderWeb extends Component<Props> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    //@ts-ignore
    const { classes, history } = this.props;
    const user = JSON.parse(localStorage.getItem("token") || "{}");
    console.log(user,"+++++++++");
    const isEkycPage = window.location.pathname.includes('EkycVerification');
    console.log(isEkycPage, "@____");
    const is_referredUser = localStorage.getItem("refer_code") ?? "";
     // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Container className={classes.headerMainContainer} id="Main">
          <Box className={classes.mainHeader}>
            <img
              data-test-id="logo"
              src={logoImg}
              id="imgtest"
              alt="Logo Img"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
            <Box className={classes.rightNav}>
              {user.token && !isEkycPage ? (
                <img src={loginImage} height="48" width="48" />
              ) : (
                <>
                  <Link
                    data-test-id="login"
                    className={classes.loginTextStyle}
                    onClick={() => this.props.onLoginPress()}
                    hidden={Boolean(is_referredUser)}
                  >
                    Login
                  </Link>
                  <Button
                    data-test-id="register"
                    className={classes.customButton}
                    onClick={() => this.props.onRegisterClick()}
                  >
                    Register
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Container>
        <Box className={classes.hr} />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const NavbarStyle = {
  headerMainContainer: {
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    margin: 0,
    padding:0,
  },
  mainHeader: {
    padding: "14px 158px",
    display: "flex",
    maxWidth: "100%",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    "@media(max-width: 1024px)": {
      padding: "14px 98px",
    },
    "@media(max-width: 768px)": {
      padding: "14px 76px",
    },
    "@media(max-width: 425px)": {
      padding: "14px 44px",
    },
    "@media(max-width: 320px)": {
      padding: "14px 22px",
    },
  },
  hr: {
    borderTop: "2px solid #E0E0E0",
  },
  rightNav: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "& .MuiTypography-root": {
      padding: "0 15px",
      color: "#353535",
      opacity: "0.79",
      fontFamily: "FuturaBT-Medium, sans-serif",
      fontWeight: "normal",
      "&:hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 767px)": {
      display: "none !important",
    }
  },
  fonts: {
    fontFamily: "FuturaBT-Book, sans-serif",
    fontWeight: "400",
  },
  customButton: {
    border: "1px solid #00BC4A",
    borderRadius: 4,
    textTransform: "none",
    color: "#00BC4A",
    fontWeight: "normal",
    marginLeft: 41,
    width: 68,
    height: 40,
    "&:hover": {
      backgroundColor: "#00BC4A",
      color: "#fff",
    },
    "& .MuiButton-label": {
      fontSize: 14,
      fontFamily: "FuturaBT-Medium, sans-serif",
    },
  },
  loginTextStyle: {
    fontWeight: "400",
    fontSize: 16,
    color: "#353535",
    cursor: "pointer",
    fontFamily: "FuturaBT-Book",
  },
};
// Customizable Area End
//@ts-ignore
export default withStyles(NavbarStyle)(withRouter(LoginCommonHeaderWeb));
