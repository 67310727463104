import React from "react";
// Customizable Area Start
import { withRouter } from 'react-router';
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "./Footer.web";
import HeaderWeb from "./Header.web";
import Spinner from "./Spinner.web";
//MUI COMPONENTS
import { Box, Grid, Container, Button } from "@material-ui/core";
import LandingPageController, { Props } from "./LandingPageController.web";
import AskEkycModalWeb from "../../EkycVerification/src/AskEkycModal.web";
import AskSignupModalWeb from "../../EkycVerification/src/AskSignupModal.web";
import '../assets/css/Campaigns.web.css';
// Customizable Area End
class CampaignWebPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start	
    async componentDidMount() {
        await this.getLiveCampaigns(false)
        await this.getUpcomingCampaigns()
    }
    liveCampaignJsx(classes: any) {
        return (
            <>
                {this.state.liveCampaigns && this.state.liveCampaigns.length === 0 ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}> <Spinner /> </div> :
                    this.state.liveCampaigns.map((item: any, index: number) => <>
                        <Grid item xs={12} sm={4} key={item?.attributes?.campaign_name}>
                            <Box className={classes.campaignCard} style={{ cursor: "pointer" }} id="liveCampaginCard">
                                <div id="routeCampaign" onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)}>
                                    <Box className={classes.campaignHeaderRow}>
                                        <img src={item?.attributes?.campaign_logo} className={classes.campIcon} alt="Camp Icon" />
                                        <h6 className={classes.h6 + " " + classes.FuturaBTMedium}>{item?.attributes?.campaign_name}</h6>
                                    </Box>
                                    <p className={classes.leadText + " card_dis " + classes.FuturaBTBook} style={{ fontWeight: 400 }}>
                                        {item?.attributes?.campaign_description && (item.attributes.campaign_description).substring(0, 180)}</p>
                                </div>
                                <Box className={classes.campBtnrow}>
                                    {item?.attributes?.service_type_tag_1 && <Button key={item.toString()} className={classes.customButton}>{item?.attributes?.service_type_tag_1}</Button>}
                                    {item?.attributes?.service_type_tag_2 && <Button key={item.toString()} className={classes.customButton}>{item?.attributes?.service_type_tag_2}</Button>}
                                    {item?.attributes?.service_type_tag_3 && <Button key={item.toString()} className={classes.customButton}>{item?.attributes?.service_type_tag_3}</Button>}
                                </Box>
                                <Box className={classes.campaignFooterRow} id="routeCampaign" onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)}>

                                    <Box className={classes.campaignFooterBlocks}>
                                        <span className={classes.span + " " + classes.FuturaBTBook} style={{ fontWeight: 400 }}>Percent Raised</span>
                                        <span className={classes.campTitle} style={{ fontFamily: "FuturaBT-Medium", fontWeight: 600 }}>{` ${item?.attributes?.percent_raised}`}</span>
                                    </Box>
                                    {item?.attributes.status !="Closed"  && 
                                    <Box className={classes.campaignFooterBlocks}>
                                        <span className={classes.span + " " + classes.FuturaBTBook} style={{ fontWeight: 400 }}>Closes In</span>
                                        <span className={classes.campTitle} style={{ fontFamily: "FuturaBT-Medium", fontWeight: 600 }}>{`${item?.attributes?.closes_in} Days`}</span>
                                        {/* <h1 className={classes.campTitle} style={{ fontFamily: "Futura-Bold", fontWeight: 600 }}>19 Days</h1> */}
                                    </Box> }
                                </Box>
                                {this.invetsNow(classes, item?.attributes?.campaign_name, item?.id, item?.attributes.status)}
                            </Box>
                        </Grid>
                    </>)
                }
            </>
        )
    }
    upcomingCampaignJsx(classes: any) {
        return (
            <>
                {this.state.upcomingCampaigns && this.state.upcomingCampaigns.length === 0 ?
                    <div id="loader" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}> <Spinner /> </div> :
                    this.state.upcomingCampaigns.map((item: any, index: number) => <>
                        <Grid item xs={12} sm={4} key={item?.attributes?.campaign_name}>
                            <Box className={classes.campaignCard} style={{ cursor: "pointer" }} id="upcomingCampaignHeader">
                                <div id="routeCampaign" onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)}>
                                    <Box className={classes.campaignHeaderRow}>
                                        <img src={item?.attributes?.campaign_logo} className={classes.campIcon} alt="Camp Icon" />
                                        <h6 className={classes.h6 + " " + classes.FuturaMedium} style={{ fontWeight: 600 }}>{item?.attributes?.campaign_name}</h6>
                                    </Box>
                                    <p className={classes.leadText + " card_dis " + classes.FuturaBTBook} style={{ fontWeight: 400 }}>
                                        {(item?.attributes?.campaign_description.substring(0, 180))}</p>
                                </div>
                                <Box className={classes.campBtnrow}>
                                    {item?.attributes?.service_type_tag_1 && <Button key={item?.attributes?.service_type_tag_1} className={classes.customButton}>{item?.attributes?.service_type_tag_1}</Button>}
                                    {item?.attributes?.service_type_tag_2 && <Button key={item?.attributes?.service_type_tag_1} className={classes.customButton}>{item?.attributes?.service_type_tag_2}</Button>}
                                    {item?.attributes?.service_type_tag_3 && <Button key={item?.attributes?.service_type_tag_1} className={classes.customButton}>{item?.attributes?.service_type_tag_3}</Button>}
                                </Box>
                                <Box id="routeCampaign" className={classes.campaignFooterRow} onClick={() => this.props.history.push(`/CampaignDetailWebPage/${item?.id}`)}>
                                    <Box className={classes.campaignFooterBlocks}>
                                        <span className={classes.span + " " + classes.FuturaBTBook} style={{ fontWeight: 400 }}>Live in</span>
                                        <span className={classes.campTitle} style={{ fontFamily: "FuturaBT-Medium", fontWeight: 600 }}>{`${item?.attributes?.live_in} Days`}</span>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </>)
                }
            </>
        )
    }
     tokenverify=() => {
        this.state.userToken
                    ? this.setState({ askEkycDialog: true })
                    : this.setState({ askSignupDialog: true })
      }
    invetsNow(classes: any, d1: any, campaignId: any, d2: any) {
        const userStatus = localStorage.getItem("userVerified");
        const is_verified = userStatus === "true" ? " disabled" : "";
      
        const isClosed = d2 === "Closed";
      
        const getButtonComponent = () => {
          const commonProps = {
            className: `${classes.customButton} ${classes.primaryButton}`,
          };
      
          if (this.state.userToken && is_verified) {
            return isClosed ? (
              <Box className={classes.campFooterBtnRow} id="investClick">
                <Button {...commonProps} className={classes.disbaleButton + " " + classes.primaryButton} disabled>
                  Closed
                </Button>
              </Box>
            ) : (
              <Box
                className={classes.campFooterBtnRow}
                onClick={() =>
                  this.props.history.push({
                    pathname: "/checkout",
                    state: { title: d1, campaignId: campaignId },
                  })
                }
                id="investClick"
              >
                <Button {...commonProps}>Subscribe Now</Button>
              </Box>
            );
          } else {
            return isClosed ? (
              <Box className={classes.campFooterBtnRow} id="investClick">
                <Button {...commonProps} className={classes.disbaleButton + " " + classes.primaryButton} disabled>
                  Closed
                </Button>
              </Box>
            ) : (
              <Box
                className={`${classes.campFooterBtnRow} ${is_verified}`}
                onClick={() =>
                  this.tokenverify()
                }
                id="investClick"
              >
                <Button {...commonProps}>Subscribe Now</Button>
              </Box>
            );
          }
        };
      
        return <>{getButtonComponent()}</>;
      }
      
    // Customizable Area End
    render() {
        // Customizable Area Start
        //@ts-ignore
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.mainContainer} id="CampaignMain">
                {/* banner content */}
                <Box className={classes.bannerContainer}>
                    <HeaderWeb />
                </Box>
                {/* MAIN CONTENT */}
                {/* campaign Section */}
                <Box className={classes.campaignSection} id="CampaignMain">
                    {(this.state.liveFlag || this.state.upcomingFlag) ?
                        <>
                            {this.state.liveCampaigns && this.state.liveCampaigns.length === 0 ? '' :
                                <Container fixed className={classes.campaignContainer}>
                                    <h2 className={classes.h2 + " " + classes.headerTitle + " " + classes.FuturaBTBook} id="liveCampaignHeader"> <span className={classes.FuturaBTMedium}>Campaigns</span></h2>
                                    <Grid container spacing={2} className={classes.campignCardRow + " list-scrollView"}>
                                        {this.liveCampaignJsx(classes)}
                                    </Grid  >
                                </Container>}
                            {this.state.upcomingCampaigns && this.state.upcomingCampaigns.length === 0 ? '' :
                                <Container fixed className={classes.campaignContainer}>
                                    <h2 className={classes.h2 + " " + classes.headerTitle + " " + classes.FuturaBTBook}>Upcoming<span className={classes.FuturaBTMedium}> Campaigns</span></h2>
                                    <Grid container spacing={2} className={classes.campignCardRow + " list-scrollView"}>
                                        {this.upcomingCampaignJsx(classes)}
                                    </Grid  >
                                </Container>
                            }
                        </> : <div id="noCampaign" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", fontSize: 30, fontWeight: 600 }}>No Campaign Uploaded !</div>
                    }
                </Box>
                {/* @ts-ignore */}
                <AskEkycModalWeb id="askEkycModalWeb" openState={this.state.askEkycDialog} onCloseFunc={() => this.handleAskEkycDialog()} />
                {/* @ts-ignore */}
                <AskSignupModalWeb id="askSignupModalWeb" openState={this.state.askSignupDialog} onCloseFunc={() => this.handleAskSignupDialog()} />

                <FooterWeb />
            </Box>
            // Customizable Area End	
            );
    }
};
// Customizable Area Start 
const CampaignScreenStyle = {
    mainContainer: {
        height: "100%",
        overflowX: "hidden",
        backgroundColor: "#fff",
        "& .MuiContainer-fixed":
        {
            "@media(max-width: 1024px)": {
                maxWidth: "1000px",
            }
        }
    },
    FuturaBTBook: {
        fontFamily: "FuturaBT-Book !important",
    },
    FuturaBTMedium: {
        fontFamily: "FuturaBT-Medium !important",
    },
    FuturaMedium: {
        fontFamily: "FuturaBT-Medium !important",
    },
    FuturaBold: {
        fontFamily: "Futura-Bold !important",
    },
    bannerContainer: {
        // paddingBottom: 50,
    },
    hr: {
        borderTop: "2px solid #E0E0E0",
    },
    brandHr: {
        borderTop: "1px solid #E0E0E0",
        margin: "30px 0",
    },
    pt: {
        paddingTop: 50,
    },
    sectionRow: {
        // paddingTop: 130,
    },
    fonts:
    {
        fontFamily: 'FuturaBT-Book, sans-serif',
        fontWeight: "400",
    },
    headerTitle:
    {
        textAlign: "center",
        letterSpacing: "1px",
        fontWeight: "400",
        "& span": {
            fontWeight: 600,
        }
    },
    h1:
    {
        color: "#474747",
        fontSize: 40,
        lineHeight: "60px",
        "@media(max-width: 1024px)": {
            fontSize: 25,
            lineHeight: "30px",
        },
    },
    h2:
    {
        color: "#474747",
        fontSize: 30,
        margin: 0,
        padding: "50px 0",
        "@media(max-width: 1024px)": {
            fontSize: 22,
        },
    },
    h3:
    {
        color: "#414141",
        fontSize: 20,
    },
    boldTitle:
    {
        fontWeight: "600",
    },
    h5:
    {
        color: "#393939",
        fontSize: 24,
        lineHeight: "30px",
        margin: 0,
    },
    leadText:
    {
        color: "#393939",
        fontSize: 15,
        lineHeight: "28px",
        margin: 0,
        wordBreak: "break-word",
        minHeight: "115px",
        "@media (max-width:1279px)": {
            minHeight: "168px",
        },
        "@media (max-width:600px)": {
            minHeight: "auto",
        }
    },
    lead:
    {
        color: "#6D6D6D",
        fontSize: 18,
        lineHeight: "28px",
    },
    customButton:
    {
        color: "#00BC4A",
        border: "1px solid #00BC4A",
        fontFamily: 'FuturaBT-Medium !important',
        padding: "5px 10px",
        textTransform: "capitalize",
        backgroundColor: "#edfff4",
        "&:hover":
        {
            backgroundColor: "#00BC4A",
            color: "#fff",
        },
        "& .MuiButton-label":
        {
            fontSize: "13px",
            fontWeight: 600,
        }
    },
    disbaleButton: {
        color: "#fff !important",
        fontFamily: 'FuturaBT-Medium !important',
        padding: "5px 10px",
        textTransform: "capitalize",
        opacity:0.6,
       
    },
    primaryButton:
    {
        color: "#fff",
        padding: "10px",
        backgroundColor: "#00BC4A",
        "&:hover":
        {
            border: "1px solid #00BC4A",
        },
        "& .MuiButton-label":
        {
            fontSize: "18px",
            lineHeight: "25px",
            fontWeight: "normal !important",
            letterSpacing: ".5px",
        }
    },
    campaignContainer:
    {
        // backgroundColor: "#fff",
        "&:nth-child(2)":
        {
            paddingBottom: "50px",
        }
        // "@media(max-width: 1024px)": {
        //     padding: "100px 10px",
        // },
    },
    campignCardRow:
    {
        // paddingTop: "50px",
        position: "relative",
    },
    campaignRightImg:
    {
        position: "absolute",
        right: "11%",
        top: "-72px",
    },
    campaignCard: {
        padding: 30,
        borderRadius: 12,
        border: "1px solid #dedede",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        justifyContent: "space-between",
        height: "100%",
        "@media(max-width: 1279px)": {
            padding: "15px",
        },
        "& .card_dis": {
            textAlign: "justify",
        }
    },
    campaignFooterRow:
    {
        display: "flex",
    },
    campaignFooterBlocks:
    {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "&:nth-child(2)":
        {
            paddingLeft: 30,
        }
    },
    campaignHeaderRow:
    {
        display: "flex",
        alignItems: "center",
        paddingBottom: 15,
    },
    campFooterBtnRow:
    {
        marginTop: 15,
        "& button":
        {
            width: "100%",
        },
        "&.disabled": {
            pointerEvents: "none",
            opacity: .7,
        }
    },
    h6:
    {
        color: "#474747",
        fontSize: 18,
        margin: 0,
        paddingLeft: "10px",
        letterSpacing: ".5px"
    },
    span:
    {
        color: "#787878",
        fontSize: 15,
        margin: 0,
        marginBottom: "10px",
        display: "inline-block"
    },
    campIcon:
    {
        height: 54,
        width: 54,
        objectFit: "cover",
        borderRadius: "50%",
    },
    campBtnrow:
    {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "25px 0",
        paddingBottom: "18px",
        marginBottom: "auto",
        "& button":
        {
            margin: "0 8px 8px 0",
            pointerEvents: "none",
            padding: "8px",
            boxSizing: "border-box",
            wordBreak: "break-all",
            "& .MuiButton-label": {
                lineHeight: "15px",
                letterSpacing: ".5px",
            }
        }
    },
    campTitle:
    {
        color: "#474747",
        fontSize: 28,
        marginBottom: 0,
        "@media(max-width: 1024px)": {
            fontSize: 18,
        },
    },
}
//@ts-ignore
export default withStyles(CampaignScreenStyle)(withRouter(CampaignWebPage))
// Customizable Area End
