// Customizable Area Start
import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
//MUI COMPONENTS
import { Grid, Link } from "@material-ui/core";
// Customizable Area Start
import DashboardController from "./DashboardController.web";
import "../assets/css/DashboardHeader.web.css";
import { liveCampaignsIcon, portfolioActiveIcon, portfolioIcon, refer_earnIcon } from "./assets";
import { refer_earnActiveIcon } from "../../DashboardGuests/src/assets";
// Customizable Area End
class DashboardSidebar extends DashboardController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Grid className="sidebar-wrapper">
        <Link href="/DashboardGuests" className={`sidebar-link ${window.location.pathname === "/DashboardGuests" ? "active" : ""}`}>
          <img src={window.location.pathname.includes('DashboardGuests') ? portfolioActiveIcon : portfolioIcon} alt="" width={"20px"}/>
          <span>portfolio</span>
        </Link>
        <Link href="/CampaignWebPage" className={`sidebar-link`}>
          <img src={liveCampaignsIcon} alt="" width={"20px"}/>
          <span>Live Campaigns</span>
        </Link>
        <Link href="/ReferAndEarn" className={`sidebar-link ${window.location.pathname === "/ReferAndEarn" ? "active" : ""}`}>
          <img src={window.location.pathname.includes('ReferAndEarn') ? refer_earnActiveIcon : refer_earnIcon} alt="" width={"20px"}/>
          <span>Refer & Earn</span>
        </Link>
      </Grid>
      // Customizable Area End
    );
  }
}
//HOME SCREEN CSS
// Customizable Area Start
const DashboardSidebarScreenStyle = {
};
// Customizable Area End
//@ts-ignore
export default withStyles(DashboardSidebarScreenStyle)(withRouter(DashboardSidebar));
// Customizable Area End
