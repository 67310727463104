import React, { Component } from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Box, Container, Link } from "@material-ui/core";
import {
  logoImg,
  instagramIcon,
  twitterIcon,
  linkdinIcon,
  emailIcon,
  phoneIcon,
  locationIcon,
} from "./assets";
import { fbIcons } from "../../landingpage/src/assets";
// Customizable Area End

type Props = {
  // Customizable Area Start
  // Customizable Area End
};

class Footer extends Component<Props> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    //@ts-ignorets
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Container className={classes.mainFooterContainer} id="Main">
        <Box className={classes.footerSection}>
          <Container
            fixed
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Container className={classes.footerTopRow}>
              <div className={classes.topRowLeft}>
                <img src={logoImg} alt="Logo Img" />
              </div>
              <div className={classes.topRowRight}>
                <p
                  className={classes.fonts + " " + classes.lead}
                  style={{ margin: 0 }}
                >
                  Are you owner of a startup looking to raise funds or an
                  individual looking to subscribe in top notch startups then you
                  are at the right place.
                </p>
              </div>
            </Container>
            <Container className={classes.footerDetailsContainer}>
              <div className={classes.footerIconsContainer}>
                <Box className={classes.socialIconsRow}>
                  <Link 
                    href="https://www.facebook.com/profile.php?id=100089193859655" 
                    target="_blank"
                  >
                    <img 
                      src={fbIcons} 
                      className={classes.footerIcons} 
                      alt="instagram Icon" 
                    />
                  </Link>
                  <Link
                    href="https://instagram.com/yuvainvest?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <img
                      src={instagramIcon}
                      className={classes.footerIcons}
                      alt="instagram Icon"
                    />
                  </Link>
                  <Link href="https://twitter.com/YuvaInvest" target="_blank">
                    <img
                      src={twitterIcon}
                      className={classes.footerIcons}
                      alt="twitter Icon"
                      width={18}
                    />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/85632556/admin/"
                    target="_blank"
                  >
                    <img
                      src={linkdinIcon}
                      className={classes.footerIcons}
                      alt="linkdin Icon"
                    />
                  </Link>
                </Box>
              </div>
              <div className={classes.footerMiddleRowRight}>
                <div className={classes.contactDetailsContainer}>
                  <Box
                    className={classes.emailBlock}
                  // style={{ width: "32%" }}
                  >
                    <img
                      src={emailIcon}
                      className={classes.footerIcons}
                      alt="fb Icon"
                    />
                    <h6 className={classes.fonts + " " + classes.h6}>
                    hello@yuvainvest.com
                    </h6>
                  </Box>
                  <Box
                    className={classes.mobileBlock}
                  // style={{ width: "26%" }}
                  >
                    <img
                      src={phoneIcon}
                      className={classes.footerIcons}
                      alt="fb Icon"
                    />
                    <h6 className={classes.fonts + " " + classes.h6}>
                      9136578301
                    </h6>
                  </Box>
                </div>
              </div>
            </Container>
          </Container>
          {/* <Box className={classes.hr} /> */}
        </Box>
        <Box className={classes.footerCopyRight}>
          <p className={classes.fonts + " " + classes.lead}>
          Vaishalica Technologies Private Limited,© 2022 All rights reserved
          </p>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FooterStyle = {
  footerTopRow: {
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    alignItems:"center",
    "@media (max-width: 767px)": {
      flexDirection:"column",
      alignItems:"flex-start",
    }
  },
  topRowLeft: {
    maxWidth:"95px",
  },
  topRowRight: {
    width:"calc(100% - 95px)",
    paddingLeft:"95px",
    boxSizing:"border-box",
    "@media (max-width: 767px)": {
      width:"100%",
      marginTop:"10px",
      paddingLeft:"0",
    }
  },
  footerMiddleRowRight: {
    width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    height:"100px",
    paddingLeft:"50px",
    "@media (max-width: 767px)": {
      height:"auto",
      marginTop:"10px",
      flexDirection:"column",
      paddingLeft:"0",
    }
  },
  socialIconsRow: {
    display:"flex",
    flexWrap:"nowrap",
  },
  mainFooterContainer: {
    maxWidth: "100%",
    height: "295px",
    padding: 0,
    background: "#ffffff",
    position: "relative",
    bottom: 0,
  },
  addressBoxStyle: {
    maxWidth: "34%",
    flexBasis: "34%",
  },
  mobileNumberBoxStyle: {
    maxWidth: "32%",
    flexBasis: "32%",
  },
  hr: {
    borderTop: "1px solid #EFEFEF",
    width: "1441px",
    margin: "30px 0",
  },
  fonts: {
    fontFamily: "FuturaBT-Book",
    fontWeight: "400",
  },
  h2: {
    color: "#474747",
    fontSize: 30,
    margin: 0,
    "@media(max-width: 1024px)": {
      fontSize: 22,
    },
  },
  h3: {
    color: "#414141",
    fontSize: 20,
  },
  h5: {
    color: "#393939",
    fontSize: 24,
    lineHeight: "30px",
    margin: 0,
  },
  lead: {
    color: "#6D6D6D",
    fontSize: 18,
    lineHeight: "28px",
    "@media(max-width: 425px)": {
      lineHeight: "18px",
    },
    "@media(max-width: 320px)": {
      lineHeight: "14px",
    },
  },
  h6: {
    color: "#474747",
    fontSize: 18,
    margin: 0,
    paddingLeft: "10px",
  },
  footerSection: {
    borderTop: "1px solid rgba(151,151,151,0.44);",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    height: "245x",
    justifyContent: "center",
    padding: "55px 162px 37px 158px",
    "& p": {
      fontSize: 16,
    },
    "@media(max-width: 1024px)": {
      padding: "55px 86px 37px 98px",
    },
    "@media(max-width: 768px)": {
      padding: "30px 0",
      "& p": {
        fontSize: 14,
        lineHeight:"18px",
      },
    }
  },
  footerIcons: {
    marginRight: 20,
    "@media(max-width: 768px)": {
      marginRight: 8,
    },
  },
  footerContactBlock: {
    display: "flex",
    alignItems: "center",
    "&:nth-child(3)": {
      alignItems: "flex-start",
    },
    "& h6": {
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: "25px",
    },
    "@media(max-width: 1024px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 15,
        lineHeight: "22px",
      },
    },
    "@media(max-width: 768px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 12,
        lineHeight: "18px",
      },
    },
  },
  footerLinkBlock: {
    "& p": {
      paddingLeft: 0,
      fontSize: 16,
      color: "#373737",
    },
  },
  footerLinksRow: {
    display: "flex",
    flexDirection: "column",
    "& a": {
      fontSize: 14,
      color: "#373737",
      fontFamily: "sans-serif",
      fontWeight: "400",
      marginBottom: "10px",
      "&:hover": {
        color: "#00BC4A",
        textDecoration: "none",
        fontWeight: "600",
      },
    },
  },
  footerCopyRight: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #EFEFEF",
    width: "100%",
    padding: "15px 0px",
    position: "relative",
    boxSizing: "border-box",
    bottom: 0,
    "& p": {
      fontSize: 14,
      lineHeight:"18px",
      color: "#373737",
      margin: 0,
      whiteSpace: "break-space",
      wordBreak: "breack-word",
    },
    "@media (max-width: 767px)": {
      padding:"12px 16px",
      textAlign:"center",
    }
  },
  footerIconsContainer: {
    height: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      height:"60px",
    }
  },
  footerDetailsContainer: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
    padding: "0 16px",
    "@media (max-width: 767px)": {
      flexDirection:"column",
    }
  },
  contactDetailsContainer: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
    "@media (max-width:767px)": {
      flexDirection: "column",
    }
  },
  emailBlock: {
    display: "flex",
    alignItems: "center",
    width: "49%",
    "& h6": {
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: "25px",
    },
    "@media(max-width: 1024px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 14,
        lineHeight: "20px",
      },
    },
    "@media(max-width: 991px)":{
      width: "50%",
    },
    "@media(max-width: 425px)": {
      width: "100%",
    },
  },
  mobileBlock: {
    display: "flex",
    alignItems: "center",
    width: "49%",
    "& h6": {
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: "25px",
    },
    "@media(max-width: 1024px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 14,
        lineHeight: "20px",
      },
    },
    "@media(max-width: 991px)":{
      width: "50%",
    },
    "@media (max-width: 767px)": {
      margin:"8px 0",
    },
    "@media(max-width: 425px)": {
      width: "100%",
    },
  },
  addressBlock: {
    display: "flex",
    alignItems: "center",
    width: "48%",
    "& h6": {
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: "25px",
    },
    "@media(max-width: 1024px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 15,
        lineHeight: "22px",
      },
    },
    "@media(max-width: 768px)": {
      "& h6": {
        paddingLeft: 0,
        fontSize: 12,
        lineHeight: "18px",
      },
    },
    "@media(max-width: 425px)": {
      width: "100%",
      "& h6": {
        paddingLeft: 0,
        fontSize: 12,
        lineHeight: "18px",
      },
    },
    "@media(max-width: 375px)": {
      width: "100%",
      "& h6": {
        paddingLeft: 0,
        fontSize: 11,
        lineHeight: "16px",
      },
    },
  },
};
// Customizable Area End
//@ts-ignore
export default withStyles(FooterStyle)(withRouter(Footer));
