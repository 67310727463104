import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  location?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  value: any,
  checkboxFlag: boolean,
  termCheckboxFlag: boolean,
  open: boolean,
  openFail: boolean,
  count: number,
  investedAmount: any,
  amountValid: boolean,
  feedetails:any,
  feecal:any,
  maxamount:boolean,
  campingTitle:string,
  campaignId:string,
  isLoading:boolean
  isMultiplcable: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CheckoutController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  getLogosCallId: string = "";
  getCartDetailsCallId:string="";
  getFeeDetailsCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.handleContinueClick2 = this.handleContinueClick2.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      value: 0,
      checkboxFlag: false,
      termCheckboxFlag: false,
      open: false,
      openFail: false,
      count: 0,
      investedAmount: "",
      amountValid: false,
      feedetails:'',
      feecal:'',
      maxamount:false,
      campingTitle:"",
      campaignId:"",
      isLoading:false,
      
      isMultiplcable: false,      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.firstHalfConditions(responseJson, apiRequestCallId);
      if (this.getCartDetailsCallId === apiRequestCallId) {
        console.log(responseJson, "###")
        this.setState({feedetails:responseJson,isLoading:false}); 
    }
    if (this.getFeeDetailsCallId === apiRequestCallId) {
      console.log(responseJson, "###")
      this.setState({feecal:responseJson,isLoading:false}) 
  }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.setState({
      campingTitle:this.props?.location.state?.title,
      campaignId:this.props?.location.state?.campaignId
    });
  }
  firstHalfConditions(responseJson: any, apiRequestCallId: any) {
    if (this.getLogosCallId === apiRequestCallId) {
      console.log(responseJson);
    }
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      // token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
 
getFeeDetails =async (d1:any) => {
  this.setState({isLoading:true});
  const user = JSON.parse(localStorage.getItem("token") || "{}");

  let header = new Headers();
  header.append("token", user.token);
  const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getFeeDetailsCallId = getValidationsMsg.messageId;
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_invest_raises/calculate_fee?amount=${d1}`
  );
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
  );
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  handleContinueClick2 = () => {
    if (this.state.count === 1) {
     const userData={
        amounts: this.state.investedAmount,
        pfee:this.state.feecal.proccessing_fee_p,
        lfee:this.state.feecal.legal_fee_p,
        gst:this.state.feecal.gst_p,
        campingTitle:this.state.campingTitle,
        campaignId:this.state.campaignId,
      }
      localStorage.setItem("UserData",JSON.stringify(userData))
      this.props.history.push({
        pathname:"/paymentsWeb",
        state:{
          campaignId:this.state.campaignId
        }
      })
      
    } else {
      this.setState({ count: 1 });
    }
  }

  handleInvestedAmountChange = (e:any) => {
    this.setState({ 
      investedAmount: e.target.value,
      amountValid: false,
      maxamount: false,
      isMultiplcable: false
    })
  }

  handleContinueClick = () => { 
    if(this.state.investedAmount < 5000) {
      this.setState({ amountValid: true });
    }
    else if(this.state.investedAmount > 1000000 ) 
    {
      this.setState({ maxamount: true });
    }
    else if(this.state.investedAmount % 5000 !== 0) {
      this.setState({ isMultiplcable: true });
    }
    else {
      this.setState({ count: 1 });
      this.getFeeDetails(this.state.investedAmount);
    }
  }
  // Customizable Area End
}