import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: any,
  validName:boolean,
  number: any,
  validNumber: Boolean,
  companyWebsite: any,
  validWebsite: Boolean,
  file: any,
  fileName: string,
  fileFlag: Boolean,
  filesArr: any,
  companyEmail: string,
  emailErrorFlag: Boolean,
  FounderLinkedinUrl: any,
  CoFounderLinkedinUrl: any,
  validFounderLinkedinUrl: any,
  CompanyName: any,
  validCompanyName: boolean,
  CompanyLinkedinPage: any,
  validCompanyLinkedinPage: boolean,
  rounds: any,
  validRounds: boolean,
  describeProduct: any,
  validDescribeProduct: boolean,
  revenue: any,
  validRevenue: boolean,
  teamSize: any,
  validTeamSize: boolean,
  submitRaiseFundModal: boolean,
  open:boolean,
  openDelete:boolean,
  delIndex:number,
  anythingMore:any,
  myFile:any,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleFormSubmitId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.isLetters = this.isLetters.bind(this);
    this.isValidMobileNumber = this.isValidMobileNumber.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);
    this.onRaiseFundClick = this.onRaiseFundClick.bind(this);
    this.onWebsiteChange = this.onWebsiteChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onFounderLinkedinUrlChange = this.onFounderLinkedinUrlChange.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onCompanyLinkedinPageChange = this.onCompanyLinkedinPageChange.bind(this);
    this.onRoundChange = this.onRoundChange.bind(this);
    this.onDescribeProductChange = this.onDescribeProductChange.bind(this);
    this.onRevenueChange = this.onRevenueChange.bind(this);
    this.onTeamSizeChange = this.onTeamSizeChange.bind(this);
    this.handleClose=this.handleClose.bind(this);
    this.handleCloseDelete=this.handleCloseDelete.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.onAnythingMoreChange = this.onAnythingMoreChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFileReuploadChange = this.onFileReuploadChange.bind(this);
    this.onCoFounderLinkedinUrlChange = this.onCoFounderLinkedinUrlChange.bind(this);
    this.firstHalfConditions = this.firstHalfConditions.bind(this);
    this.secondHalfConditions = this.secondHalfConditions.bind(this);

    // this.handleChange = this.handleChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      name: "",
      validName:true,
      number: "",
      validNumber: true,
      companyWebsite: "",
      validWebsite: true,
      file: "",
      filesArr: [],
      fileName: "",
      fileFlag: true,
      companyEmail: "",
      emailErrorFlag: true,
      FounderLinkedinUrl: "",
      CoFounderLinkedinUrl: "",
      validFounderLinkedinUrl:true ,
      CompanyName: "",
      validCompanyName: true,
      CompanyLinkedinPage: "",
      validCompanyLinkedinPage: true,
      rounds: "",
      validRounds:true,
      describeProduct: "",
      validDescribeProduct: true,
      revenue: "",
      validRevenue: true,
      teamSize: "",
      validTeamSize:true,
      submitRaiseFundModal: false,
      open:false,
      openDelete:false,
      delIndex:0,
      anythingMore:"",
      myFile:{},

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // await this.getLogos()
  }
  handleClose(){
    this.setState({
      open:false,
    })
  }
  handleCloseDelete(){
    this.setState({
      openDelete:false,
    })
  }
  handleOpenDelete(index:any){
    this.setState({
      openDelete:true,
      delIndex:index,
    })
  }
  
  handleOpen(){
    this.setState({
      open:true,
    })
  }

  isLetters(str: string) {
    return /^[a-zA-Z ]*$/.test(str)
  }

  isValidMobileNumber(number: any) {
    return /^\d{10}$/.test(number);
  }

  onInputChange(e: any) {
    const { value } = e.target;
    if (/^[a-zA-Z ._-]*$/.test(value)) {
      this.setState({
        name: value
      })
    }
  }
  onNumberChange(e: any) {
    const { value } = e.target;
    this.setState({
      number: value
    })
    
  }

  onWebsiteChange(e: any) {
    const { value } = e.target;
    this.setState({
      companyWebsite: value
    })
  }

  onRevenueChange(e: any) {
    const { value } = e.target;
    this.setState({
      revenue: value,
    })
  }
  onAnythingMoreChange(e: any) {
    const { value } = e.target;
    this.setState({
      anythingMore: value,
    })
  }
  onTeamSizeChange(e: any) {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {

      this.setState({
        teamSize: value,
      })
    }
  }
  onRoundChange(e: any) {
    const { value } = e.target;
    this.setState({
      rounds: value,
    })
  }
  onDescribeProductChange(e: any) {
    const { value } = e.target;
    this.setState({
      describeProduct: value,
    })
  }
  onFounderLinkedinUrlChange(e: any) {
    const { value } = e.target;
    this.setState({
      FounderLinkedinUrl: value
    })
  }
  onCoFounderLinkedinUrlChange(e: any) {
    const { value } = e.target;
    this.setState({
      CoFounderLinkedinUrl: value
    })
  }
  onCompanyNameChange(e: any) {
    const { value } = e.target;
    this.setState({
      CompanyName: value
    })
  }
  onCompanyLinkedinPageChange(e: any) {
    const { value } = e.target;
    this.setState({
      CompanyLinkedinPage: value
    })
  }
  onChangeEmail(e: any) {
    const { value } = e.target;
    this.setState({
      companyEmail: value
    })
  }
  deleteItem(index: any) {
    this.state.filesArr.splice(index, 1)
    this.setState({
      filesArr: this.state.filesArr
    })
    this.handleCloseDelete();
  }
  onFileReuploadChange(e:any){
    const { value } = e.target;
    this.state.filesArr.splice(this.state.delIndex, 1 , e.target.files[0])
    this.setState({
      filesArr: this.state.filesArr
    })
    this.handleCloseDelete();
  }
  onFileChange(e: any) {
    const arr = []
    const { value } = e.target;
    this.setState({
      myFile:e.target.files[0]
    })
    for (let key in e.target.files) {
      if (e.target.files.hasOwnProperty(key)) {
        const value = e.target.files[key];
        arr.push(value)
      }
    }
    this.setState({
      fileName: e.target.files[0].name,
      file: e.target.files[0],
      filesArr: arr,

    })
    
  }
  validateEmail(email: any) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  };

  firstHalfConditions(){
    if (this.isValidMobileNumber(this.state.number)) {
      this.setState({
        validNumber: true
      })
    } else { 
      this.setState({
        validNumber: false
      })
    }

    if (this.state.companyWebsite.length !== 0) {
      this.setState({
        validWebsite: true
      })
    } else {
      this.setState({
        validWebsite: false
      })

    }
    if (this.state.fileName.length < 1) {
      this.setState({
        fileFlag: false
      })

    } else {
      this.setState({
        fileFlag: true
      })

    }
    if (this.validateEmail(this.state.companyEmail)) {
      this.setState({
        emailErrorFlag: true,
      })
    } else {
      this.setState({
        emailErrorFlag: false,
      })
    }
    if (this.state.name == "") {
      this.setState({
        validName: false,
      })
    } else {
      this.setState({
        validName: true,
      })
    }
    if (this.state.FounderLinkedinUrl == "") {
      this.setState({
        validFounderLinkedinUrl: false,
      })
    } else {
      this.setState({
        validFounderLinkedinUrl: true,
      })
    }
  }

  secondHalfConditions(){
    if (this.state.CompanyName == "") {
      this.setState({
        validCompanyName: false,
      })
    } else {
      this.setState({
        validCompanyName: true,
      })
    }
    if (this.state.CompanyLinkedinPage == "") {
      this.setState({
        validCompanyLinkedinPage: false,
      })
    } else {
      this.setState({
        validCompanyLinkedinPage: true,
      })
    }
    if (this.state.rounds == "") {
      this.setState({
        validRounds: false,
      })
    } else {
      this.setState({
        validRounds: true,
      })
    }
   
    if (this.state.describeProduct == "") {
      this.setState({
        validDescribeProduct: false,
      })
    } else {
      this.setState({
        validDescribeProduct: true,
      })
    }
    if (this.state.revenue == "") {
      this.setState({
        validRevenue: false,
      })
    } else {
      this.setState({
        validRevenue: true,
      })
    }
    if (this.state.teamSize == "") {
      this.setState({
        validTeamSize: false,
      })
    } else {
      this.setState({
        validTeamSize: true,
      })
    }
  }
  onRaiseFundClick() {
   
   this.firstHalfConditions();
   this.secondHalfConditions();
    if(this.state.validNumber && this.state.validWebsite && this.state.emailErrorFlag && this.state.validName && this.state.validFounderLinkedinUrl && this.state.CompanyLinkedinPage && this.state.CompanyName && this.state.validRounds && this.state.validDescribeProduct && this.state.validRevenue && this.state.validTeamSize && this.state.filesArr.length !=0){
      this.handleFormSubmit();
 }
  }

  handleOpenSubmitRaiseFundModal = () => {
    this.setState({ submitRaiseFundModal: true })
  }
  handleCloseSubmitRaiseFundModal = () => {
    this.setState({
      submitRaiseFundModal: false,
    })
  }

  handleFormSubmit  = async () => {
      let formData = new FormData();
      formData.append("raise[name]", this.state.name);
      formData.append("raise[company_email]",this.state.companyEmail );
      formData.append("raise[company_contact_number]",this.state.number );
      formData.append("raise[founder_linkedin_url]",this.state.FounderLinkedinUrl);
      formData.append("raise[co_founder_linkedin_url]",this.state.CoFounderLinkedinUrl );
      formData.append("raise[company_registered_name]", this.state.CompanyName);
      formData.append("raise[company_linkedin_page]",this.state.CompanyLinkedinPage );
      formData.append("raise[website]",this.state.companyWebsite);
      formData.append("raise[previous_funding_description]",this.state.rounds );
      formData.append("raise[product_description]",this.state.describeProduct);
      formData.append("raise[revenue_description]",this.state.revenue );
      formData.append("raise[team_size]", this.state.teamSize);
      formData.append("raise[more_description]", this.state.anythingMore);
      this.state?.filesArr?.map((item:any)=>{
        return formData.append("raise[pitch_deck][]",item);
      })
      
      this.handleFormSubmitId = await this.apiCall({
        method: "POST",
        endPoint: `/bx_block_invest_raises/raises`,
        body: formData,
      });
    }
  apiCall = async (data: any) => {
    const {  method, endPoint, body , token} = data;

    const header = {
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.handleFormSubmitId === apiRequestCallId) {
        if (responseJson.errors && responseJson.errors.length > 0) {
          if (responseJson.errors[0].token.toLowerCase().includes("token")) {
              localStorage.clear()
              window.location.href = "/";
              return;
          }
      } 
       else{

         this.handleOpen();
          setTimeout(() => {
            this.handleClose();
            this.props.history.push("/")
          },3000);
      
      }
    }
    }
  }
  // Customizable Area Start
}
// Customizable Area End

