import React from "react";
import { withRouter } from "react-router";
import UserProfileControllerWeb, {
  Props
} from "./UserProfileBlockController.web";
// Customizable Area Start
import { Grid, OutlinedInput } from '@material-ui/core';
import DashBoardHeaderWeb from "../../dashboard/src/DashBoardHeader.web";
import DashboardSidebarWeb from "../../dashboard/src/DashboardSidebar.web";
import '../assets/css/profileBio.web.css';
import { myprofileIcon, userProfile } from "../../dashboard/src/assets";
// Customizable Area End

export class UserProfileEdit extends UserProfileControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getProfileDetails();
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {profile_image,
      is_ekyc_verified,
      pan,
      passport,
      adhaar,
      is_email_account,
      bank_name,
      bank_account_holder_name,
      bank_account_number,
      bank_account_ifsc_code
    }=this.state.profileDetails;

    return (
      <Grid className="profile-page-main" id="Main">
        <DashBoardHeaderWeb />
        <div className="profile-container-main">
          <DashboardSidebarWeb />

          <div className="profile-bg-main edit-user-profile">
            <div className="profile-header">
              <div className="profile-title"><img src={myprofileIcon} alt="" width={"30px"} />&nbsp;My Profile</div>
            </div>

            <div className="user-info-box">
              <div className="info-wrapper">
              <div className="profile-wrapper">
                  <img 
                    data-test-id="profileImage"
                    src={profile_image ? profile_image : this.state.user_image} 
                    alt="" 
                    width={"45px"} 
                    height={"45px"} 
                  />
                  <label htmlFor="user_profile">Edit</label>
                  <input 
                    data-test-id="userProfileEdit"
                    type="file" 
                    accept="images/*" 
                    id="user_profile" 
                    className="user-profile-input" 
                    onChange={(event: any) => {
                      
                          this.custURL(event)
                      
                    }}
                  />
                </div>
                <div className="account-info">
                  <span className="data-label">Account Opening Date</span>
                  <span className="data-info">{this.state.profileDetails.created_at}</span>
                </div>
                <div className="kyc-info">
                  <span className="data-label">KYC</span>
                  {is_ekyc_verified ? (<span data-test-id="verified" className="data-info verified-tag">Verified</span>) :
                    (<span className="data-info unverified-tag">Not Verified</span>)}
                </div>
                <div className="wallet-info">
                  <span className="data-label">wallet</span>
                  <span className="data-info">0</span>
                </div>
              </div>
            </div>

            <div className="info-card personal-info">
              <div className="card-header edit-profile">
                <h3>Personal details</h3>
              </div>
              <div className="card-body"> 
                <div className="f-row">
                  <div className="info-cell-edit">
                    <span className="data-label">Name</span>

                    <OutlinedInput color="secondary" id="ip1" value={this.state.user_name}
                      data-test-id="userName"
                      onChange={(event: any) => this.setUserName(event)}
                      type="text" name="user-name" />
                  </div>
                </div>
                {is_ekyc_verified &&
                  <div data-test-id="ekycDiv" className="f-row">
                    <div className="info-cell-edit">
                      <span className="data-label">PAN</span>
                      <OutlinedInput id="ip2" color="secondary" value={pan} type="text" name="Pan-number" disabled />
                    </div>
                    <div className="info-cell-edit">
                      <span className="data-label">Adhar Number</span>
                      <OutlinedInput color="secondary" id="ip3" type="text" value={adhaar} name="Adhar-number" disabled />
                    </div>
                    <div className="info-cell-edit">
                      <span className="data-label">Passport Number</span>
                      <OutlinedInput color="secondary" value={passport} type="text" name="passport-number" disabled />
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="info-card contact-info">
              <div className="card-header edit-profile">
                <h3>contact details</h3>
              </div>
              <div className="card-body">
                <div className="f-row">
                <div className="info-cell-edit">
                    <span className="data-label">Email ID</span>
                    {is_email_account?(<OutlinedInput data-test-id="emailTrue" color="secondary" value={this.state.user_email} onChange={(e: any) => this.setUserEmail(e)} type="email" name="Email" disabled/>):(<OutlinedInput data-test-id="emailFalse" color="secondary" value={this.state.user_email} onChange={(e: any) => this.setUserEmail(e)} type="email" name="Email"   />)}
                    
                  </div>
                  <div className="info-cell-edit">
                    <span className="data-label">Contact No.</span>
                    {is_email_account? <OutlinedInput color="secondary" data-test-id="truePhone" value={this.state.user_phone} onChange={(e: any) => this.setUserPhone(e)} type="number" name="mobile"  />:<OutlinedInput color="secondary" data-test-id="falsePhone" value={this.state.user_phone} onChange={(e: any) => this.setUserPhone(e)} type="number" name="mobile" disabled />}                  
                  </div>
                </div>
              </div>
            </div>
            {this.state.profileDetails.is_ekyc_verified &&
              <div className="info-card bank-details">
                <div className="card-header edit-profile">
                  <h3>Bank details</h3>
                </div>
                <div className="card-body">
                  <div className="f-row">
                    <div className="info-cell-edit">
                      <span className="data-label">Bank Name</span>
                      <OutlinedInput color="secondary" value={bank_name} type="text" name="bank-name" disabled />
                    </div>
                    <div className="info-cell-edit">
                      <span className="data-label">Account Holder Name</span>
                      <OutlinedInput color="secondary" value={bank_account_holder_name} type="text" name="acc-holder-name" disabled />
                    </div>
                    <div className="info-cell-edit">
                      <span className="data-label">Account No.</span>
                      <OutlinedInput color="secondary" value={bank_account_number} type="text" name="acc-number" disabled />
                    </div>
                    <div className="info-cell-edit">
                      <span className="data-label">IFSC Code</span>
                      <OutlinedInput color="secondary" value={bank_account_ifsc_code} type="text" name="ifsc-code" disabled />
                    </div>
                  </div>
                </div>
              </div>
            }
            <button
              className="form-button"
              type="button" 
              onClick={() => this.getProfileUpdate(this.state.user_name,this.state.user_phone,this.state.user_email)}
              id="btntxtt"
            >
              Update
            </button>
          </div>
        </div>
      </Grid>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End  
}

// Customizable Area Start
//@ts-ignore
export default (withRouter(UserProfileEdit));
// Customizable Area End
