import React from "react";
// Customizable Area Start
import Loader from "../../../components/src/Loader";
import {
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Grid,
  Button,
} from "@material-ui/core";
import '../assets/css/ConfirmSignupDetails.web.css';
import LoginCommonHeaderWeb from "../../social-media-account-login/src/LoginCommonHeader.web";
import LoginCommonFooterWeb from "../../social-media-account-login/src/LoginCommonFooter.web";
import ConfirmSignupDetailsWebController, {
  Props,
} from "./ConfirmSignupDetailsWebController";
import { withRouter } from "react-router-dom";
// Customizable Area End
export class ConfirmSignupDetails extends ConfirmSignupDetailsWebController {
  static ConfirmSignupDetails: ConfirmSignupDetails;

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { loading, userValidationData } = this.state;
    const is_userRefered = localStorage.getItem('refer_code') ?? "";
    // Customizable Area End

    return (
      // Customizable Area Start
      <Grid className="Confirm-signup-details-container" id="Main">
        <Loader loading={loading} />
        <LoginCommonHeaderWeb
          {...this.btnRegisterNowProps}
          {...this.btnLoginOnHeader}
        />

        <Container className="container-inner">
          <span className="labelTitle"> Please confirm your details </span>

          <Box className="Confirm-details-box">

            <span className="input-label">Name</span>
            <FormControl
              className="input-form-control"
              variant="outlined"
            >
              <OutlinedInput
                id="input1"
                type="text"
                name="name"
                value={userValidationData.name}
                error={this.state.validName ? true : false}
                onChange={(e) => {
                  this.handleUserDataChange(e.target.name, e.target.value);
                  this.setState({ validName: false });
                }}
              />
              {this.state.validName && (
                <span className="validation-msg">
                  Please enter name.
                </span>
              )}
            </FormControl>

            <span className="input-label">Email Address</span>
            <FormControl
              className="input-form-control"
              variant="outlined"
            >
              <OutlinedInput
                id="input2"
                type="text"
                name="email"
                value={userValidationData.email}
                error={this.state.validEmail ? true : false}
                onChange={(e) => {
                  this.handleUserDataChange(e.target.name, e.target.value);
                  this.setState({ validEmail: false });
                }}
                disabled={userValidationData.isSignupUsingGoogleAccount}
              />
              <span className="validation-msg">{this.state.errortext}</span>
              {this.state.validEmail && (
                <span className="validation-msg">
                  Please enter valid email.

                </span>
              )}
            </FormControl>

            <span className="input-label">Mobile Number</span>
            <FormControl
              className="input-form-control"
              variant="outlined"
            >
              <OutlinedInput
                id="input3"
                type="number"
                inputMode="tel"
                name="mobileNumber"
                error={this.state.validMobileNumber ? true : false}
                value={userValidationData.mobileNumber}
                onChange={(e) => {
                  this.handleUserDataChange(e.target.name, e.target.value);
                  this.setState({ validMobileNumber: false });
                }}
                disabled={userValidationData.isSignupUsingMobileNumber}
              />
              {this.state.validMobileNumber && (
                <span className="validation-msg">
                  Please enter valid mobile number.
                </span>
              )}
            </FormControl>

            <span className="input-label">Referral Code (Optional)</span>
            <FormControl
              className="input-form-control"
              variant="outlined"
            >
              <OutlinedInput
                id="input4"
                type="text"
                name="referallCode"
                error={this.state.referalError}
                value={userValidationData.referallCode}
                onChange={(e) =>
                  this.handleUserDataChange(e.target.name, e.target.value)
                }
                placeholder="Enter Code"
                disabled={Boolean(is_userRefered)}
              />
              <span className="validation-msg">{this.state.referalError}</span>
            </FormControl>

            <>
              <Button
                className="confirm-details-btn"
                id="confirm-user-details-button"
                onClick={this.doConfirmDetails}
              >
                Confirm
              </Button>
            </>
          </Box>
        </Container>

        <LoginCommonFooterWeb />
      </Grid>
      // Customizable Area Start
    );
  }
}

//@ts-ignore
export default withRouter(ConfirmSignupDetails);
